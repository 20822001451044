 /*CSS Document */

 /* FONTS */
 @font-face {
    font-family: 'AVGSans';
    /* IE9 Compat Modes | IE6-IE8 | Modern Browsers | Safari, Android, iOS | Legacy iOS */
    src: url('../fonts/AVGSansLightProportional.eot');
    src: url('../fonts/AVGSansLightProportional.eot?#iefix') format('embedded-opentype'), url('../fonts/AVGSansLightProportional.woff') format('woff'), url('../fonts/AVGSansLightProportional.ttf') format('truetype'), url('../fonts/AVGSansLightProportional.svg#svgFontName') format('svg');
    font-weight: 300;
}


@font-face {
    font-family: 'AVGSans';
    /* IE9 Compat Modes | IE6-IE8 | Modern Browsers | Safari, Android, iOS | Legacy iOS */
    src: url('../fonts/AVGSansBookProportional.eot');
    src: url('../fonts/AVGSansBookProportional.eot?#iefix') format('embedded-opentype'), url('../fonts/AVGSansBookProportional.woff') format('woff'), url('../fonts/AVGSansBookProportional.ttf') format('truetype'), url('../fonts/AVGSansBookProportional.svg#svgFontName') format('svg');
    font-weight: 400;
}

@font-face {
    font-family: 'AVGIconFont';
    /* IE9 Compat Modes | IE6-IE8 | Modern Browsers | Safari, Android, iOS | Legacy iOS */
    src: url('../fonts/icomoon.eot');
    src: url('../fonts/icomoon.eot?#iefix') format('embedded-opentype'), url('../fonts/icomoon.woff') format('woff'), url('../fonts/icomoon.ttf') format('truetype'), url('../fonts/icomoon.svg#svgFontName') format('svg');
}

/* GENERAL SETTINGS + GENERAL ELEMENTS */

body {
    overflow-x: hidden;
}

body,
html {
    margin: 0px;
    padding: 0;
    background: #fff;
    font-family: AVGSans, Verdana, Arial, Helvetica, sans-serif;
    font-size: 14px;
    height: 100%;
}

body,
html,
div[id*=content] {
    width: 100%;
}

body.ja * {
    font-family: 'Meiryo','メイリオ','Hiragino Kaku Gothic Pro','Osaka','MS PGothic','Proxima Nova','Helvetica Neue','Roboto',Helvetica,Arial,sans-serif !important;
}

.avg-font {
    font-family: AVGSans !important;
}

.font-weight-300 {
    font-weight: 300 !important;
}

.wrapper {
    width: 940px;
    padding: 0 20px;
    margin: 0 auto;
}

.wrapper::after {
    content: "";
    clear: both;
    display: block;
}

.supportSalesContent .wrapper {
    position:static;
}

.ja .supportSalesContent .wrapper .left.wrap h2 {
    font-size: 22px;
}

.supportSalesContent .wrapper.contact {
    padding-top: 36px;
    padding-bottom: 36px;
}

.partners-page.ja .wrapper h1 {
    font-size: 32px;
}

.px18 {
    font-size: 18px !important;
}

.px19 {
    font-size: 19px !important;
}

.left {
    float: left;
}

.right {
    float: right;
}

.inner-center {
    margin-left: auto !important;
    margin-right: auto !important;
}

i {
    display: block;
    font-style: normal;
    /*notice this approach to icons - i tag no longer for italic*/
}

.bolder {
    font-weight: 500 !important;
}

.bold {
    font-weight: 400 !important;
}

.hidden {
    display: none !important;
}

.invisible, .no-visible {
   visibility:hidden !important;
}

.inline-block {
    display: inline-block !important;
}

.center-text {
    text-align: center;
}

.left-text {
    text-align: left !important;
}

.vertical-align-middle {
    vertical-align: middle;
}

.cleaner {
    clear: both;
    padding: 0 !important;
    margin: 0 !important;
}

.clear {
    clear: both !important;
}

.full-width {
    width: 100% !important;
}

.half-width {
    width: 50%;
}

.quarter-width {
    width: 25%;
}

.third-box {
    width: 280px;
    padding: 30px 15px;
    overflow: hidden;
}

.large-col {
    width: 68%;
}

.cform-col {
    width: 396px;
}

.cform-col.contact {
    width: 460px;
}

.cform-col.contact.narrow {
    width: 330px;
}

table.cform-col.contact td {
    display: block;
}

.cform-col .leftcell {
    width: 38%;
}

.cform-col .rightcell {
    width: 62%;
}

.cform-col.contact .leftcell {
    width: 100%;
}

.cform-col.contact .rightcell {
    width: 100%;
}

[id*=submitSelectedButton] {
    width: 300px;
    font-weight: 500;
}

[id*=submitSelectedButton]:disabled, [id*=emailButton]:disabled, [id*=submitSelectedButton]:disabled:hover, [id*=emailButton]:disabled:hover {
    background: #dcdcdc none repeat scroll 0 0;
    color: #5c707b;
    cursor: auto;
}

a:link,
a:visited {
    font-family: Verdana;
    color: #0072D1;
    text-decoration: none;
}

a.black {
    color: #000000 !important;
}

a.fill-link {
    width: 100%;
    height: 100%;
    display: block;
}

span.link-like {
    color: #0072D1;
}

span.underlined {
    text-decoration: underline;
}

span.inline-button {
    color: #0072d1;
    cursor: pointer;
}

h3 a.underline {
    text-decoration: underline;
}

h3:hover a.underline {
    text-decoration: none;
}

.strong {
    font-weight: bold;
    color: #5c707b;
}

.green-mark {
    font-weight: bold;
    color: #45a21f;
}

.green-highlight {
    font-weight: bold;
    color: #ffffff;
    background-color: #45a21f;
    padding: 5px;
}

.line-height-1c5 {
    line-height: 1.5em;
}

.line-height-2 {
    line-height: 2em;
}

.margin-left-10px {
    margin-left: 10px;
    text-align: justify;
}

p {
    margin: 10px 0;
}

p.px16, span.px16 {
    font-size: 16px !important;
}

p.px15, span.px15 {
    font-size: 15px !important;
}

p.grey, span.grey {
    color: #5c707b;
}

blockquote {
    margin: 0;
}

p.spacious {
    padding: 25px 0;
}

p.with-gap {
    padding: 10px 0;
    margin: 0;
}

a.underlined {
    text-decoration: underline !important;
}

a.underlined-hover:hover {
    text-decoration: underline !important;
}

.underlined-ul {
    margin-bottom: 0px;
    padding-left: 15px;
}

ul.underlined-ul li a:hover {
    text-decoration: underline !important;
}

span.name {
    text-transform: capitalize;
}

span.red {
    color: red;
}

.title {
    font-size: 16px;
}

span.upper {
    position: relative;
    top: -0.5em;
    font-size: 60%;
}

a:not(.prod-icon):hover span.upper {
    display: inline-block !important;
    line-height: 1.7em !important;
    text-decoration: none !important;
}

a span.trademark {
    position: relative;
}

a:hover span.trademark {
    display: inline-block !important;
    text-decoration: none !important;
    line-height:.8em;
}

a:hover sup {
    text-decoration: none !important;
    display: inline-block !important;
}

mark {
    font-weight: bold;
    background-color: inherit;
    color: inherit;
}

.verdana-text {
    font-family: Verdana;
}

.orange-text {
    color: #ff6c2f !important;
}

a.orange-text {
    color: #ff6c2f;
}

.green-text {
    color: #399e2d;
}

.white-text {
    color: #ffffff;
}

.gray-text {
    color: #707174;
}

a.green-text {
    font-family: AVGSans;
    color: #399e2d;
}

p.error {
    color: red;
    font-weight: bold;
}

p.larger-text {
    font-size: 1.2em;
}

.dif-text,
[id*=articleview],
[id*=articleview] p,
[id*=articleview] div.large-col div,
[id*=articleview] ol,
[id*=articleview] ul,
.article-wrap p,
.article-wrap ol,
.article-wrap ul {
    font-family: Verdana;
    color: #5c707b;
}

[id*=alert] h2 {
    margin: 0 0 15px 0;
    color: #ff6c2f;
    padding: 7px 0 0 0;
    font-family: AVGSans !important;
    padding-left: 60px;
    height: 28px;
    background: url('../img/icons/alert-icon.png') no-repeat left center;
}


/* EXTRA MARGIN, PADDING */

.no-margin {
    margin: 0 !important;
}

.margin-top--3px {
    margin-top: -3px !important;
}

.no-padding {
    padding: 0 !important;
}

.center-element {
    margin-left: auto;
    margin-right: auto;
}

.center-inner-element > * {
    margin-left: auto !important;
    margin-right: auto !important;
}

.no-padding-left {
    padding-left: 0 !important;
}


.no-padding-top {
    padding-top: 0 !important;
}

.no-padding-bottom {
    padding-bottom: 0 !important;
}

.every-padding-15px {
    padding: 15px;
}

.no-bottom-padding {
    padding-bottom: 0 !important;
}

.no-right-margin {
    margin-right: 0 !important;
}

.no-left-margin {
    margin-left: 0 !important;
}

.no-bottom-margin {
    margin-bottom: 0 !important;
}

.no-top-margin {
    margin-top: 0 !important;
}

.no-top-padding {
    padding-top: 0 !important;
}

.no-right-padding {
    padding-right: 0 !important;
}

.no-right-border {
    border-right: 0 !important;
}

.padding-top-5px {
    padding-top: 5px !important;
}

.padding-top-10px {
    padding-top: 10px !important;
}

.padding-top-15px {
    padding-top: 15px !important;
}

.padding-top-20px {
    padding-top: 20px !important;
}

.padding-bottom-10px {
    padding-bottom: 10px !important;
}

.padding-bottom-30px {
    padding-bottom: 30px !important;
}

.padding-bottom-60px {
    padding-bottom: 60px !important;
}

.center-align-component {
    text-align-last: center !important;
}

.padding-bottom-25px {
   padding-bottom: 25px !important;
}

.padding-bottom-20px {
    padding-bottom: 20px !important;
}

.padding-bottom-5px {
    padding-bottom: 5px !important;
}

.padding-top-30px {
    padding-top: 30px !important;
}

.padding-20px {
    padding: 20px !important;
}

.margin-top--10px {
    margin-top: -10px !important;
}

.margin-top-40px {
    margin-top: 40px !important;
}

.margin-top-20px {
    margin-top: 20px !important;
}

.gap {
    padding-bottom: 80px;
}

.gap45 {
    padding-bottom: 45px;
}

.gap30 {
    padding-bottom: 30px;
}

.gap15 {
    padding-bottom: 15px;
}

.width-300px {
    width: 300px;
}

.width-700px {
    width: 700px;
}

.width-500px {
    width: 500px;
}

/* COMMON BACKGROUNDS, COLORS, BORDERS*/

.silver-bg {
    background: #fafafa;
}

.white-smoke-bg {
    background: #f5f5f5;
}

.gray-bg {
    background: #f5f5f5;
}

.light-gray-bg {
    background: #f1f1f1;
}

.gray-bg {
    background: #48494B;
}

.grey-blue-bg {
    background: #eaeef4 !important;
}

.gray-border {
    border: 1px solid #dcdcdc;
}

.bottom-gray-border {
    border-bottom: solid 1px #dcdcdc;
}

.bottom-blue-border {
    border-bottom: solid 1px #0072D1;
}

.top-gray-border {
    border-top: solid 1px #dcdcdc;
}

.top-white-border {
    border-top: solid 1px white;
}

.top-white-border-2px {
    border-top: solid 2px white;
}

.black-text {
    color: #000000;
}


/* STRIPES */

.find-related-help-stripe {
    padding: 15px 0px 15px 0px;
    color: white;
    font-size: 14px;
    font-family: Verdana;
}

.find-related-help-stripe a {
    text-decoration: underline;
    color: white;
    font-size: 14px;
}

.find-related-help-stripe a:hover {
    text-decoration: none;
}


/* ARTICLES */

#articlePanel {
    min-height: 250px;
}

#articleview #articlePanel u, #articleview #articlePanel b {
    text-decoration: none;
    font-weight: bold;
}

#articleview #articlePanel h3 u {
    text-decoration: none;
    font-weight: 300;
}

#articleview #articlePanel a:not(.prominent-link) {
    text-decoration: underline;
    font-weight: bold;
 }

#articleview #articlePanel a:not(.prominent-link):hover {
    text-decoration: underline;
    font-weight: bold;
    color: #249efc;
}

#articleview #articlePanel pre.ckeditor_codeblock {
    width: 100%;
    overflow-x: auto;
}

.article-wrap {
    padding: 15px 0;
    margin: 10px 0;
    border: solid 1px #dcdcdc;
    border-left: 0;
    border-right: 0;
}

[id*=articleview] i {
    display: inline !important;
    font-style: italic !important;
}

[id*=articleview] ol,
[id*=articleview] ul {
    margin-top: 15px;
}

.article-wrap p,
.article-wrap ol,
.article-wrap ul {
    font-size: 13px;
}

[id*=articleview] table {
    width: 100%;
    line-height: 2em;
    border-collapse: collapse;
    font-family: Verdana;
    font-size: 12px;
}

[id*=articleview] table tr td {
    padding: 8px;
}

[id*=articleview] table tr th {
    padding: 12px;
}

[id*=articleview] ol {
    list-style-type: none !important;
    /*IE 7- hack*/
    margin-left: 4em;
    padding: 0;
    counter-reset: li-counter;
}

[id*=articleview] ol > li {
    position: relative;
    margin-bottom: 14px;
    min-height: 2.5em;
}

.lte-ie7 [id*=articleview] ol > li {
    min-height: auto;
}

[id*=articleview] ol > li:before {
    position: absolute;
    top: 2px;
    left: -3em;
    width: 1.8em;
    height: 1.8em;
    line-height: 1.8em;
    text-align: center;
    color: #f5f5f5;
    font-weight: bold;
    background-color: #45a21f;
    content: counter(li-counter);
    counter-increment: li-counter;
}

[id*=articleview] ol ol {
    counter-reset: li-counter-inner;
}

[id*=articleview] ol ol > li:before {
    content: counter(li-counter-inner);
    counter-increment: li-counter-inner;
}

[id*=articleview] h1 {
    font-family: AVGSans;
    color: #000000;
}

[id*=articleview] h2 {
    font-family: AVGSans;
    color: #000000;
    font-size: 26px;
    padding: 20px 0 6px 0;
}

#articlePanel h2:first-child {
    font-family: AVGSans;
    padding-top: 10px;
}

[id*=articleview] h3 {
    font-family: AVGSans;
    color: #000000;
    font-size: 19px;
    padding: 15px 0 10px 0;
}

[id*=articleview] h4 {
    font-family: AVGSans;
    color: #000000;
    font-size: 16px;
    padding: 10px 0 10px 0;
    font-weight: bold;
}

[id*=articleview] h5 {
    font-family: AVGSans;
    color: #000000;
}

[id*=articleview] h6 {
    font-family: AVGSans;
    color: #000000;
}

[id*=articleview] img {
    margin: 10px 0;
}

[id*=articleview] .expand-header {
    background: url('../img/icons/expand-arrow.png') no-repeat scroll left center transparent;
    padding-left: 30px;
    cursor: pointer;
}

[id*=articleview] .expand-header:hover {
    color: #0072D1;
}

[id*=articleview] .expand-content {
    display: none;
    padding-left: 30px;
}

[id*=articleBanner] {
    padding-bottom: 20px;
    border-bottom: 1px solid #0072D1;
}

[id*=articleBanner] h2 {
    color: #0072D1;
    font-size: 22px;
    padding-bottom: 0;
}

[id*=articleBanner] p {
    width: 325px;
    margin: 10px 0 20px 0;
}

[id*=relArtBox] h2 {
    margin-bottom: 10px;
}

.article-side-box {
    margin-top: 45px;
    width: 180px;
    padding: 20px;
    border: 1px solid #dcdcdc;
}

.link-wrap.home {
    padding: 70px 0 30px 0;
}

.link-wrap.home .homepage-link {
    margin-left: 20px;
    display: inline-block;
}

.link-wrap.home .separator {
    font-family: Verdana;
    font-size: 14px;
    color: #5c707b;
}

.link-wrap.home .homepage-link .link-text {
    padding-right: 20px;
}


/* HEADINGS */

h1,
.h1-like {
    font-size: 36px;
    padding: 15px 0 25px 0;
    margin: 0;
    font-weight: 300;
}

h1.large,
h1.homepage {
    font-size: 44px;
}

h1.homepage {
    text-align: center;
    padding: 20px 0 5px 0;
}

h1.product {
    padding-top: 0;
    padding-left: 60px;
}

h1 .frase {
    font-weight: 300;
    color: #5c707b;
}

h1.product-name-2017 {
    font-size: 32px;
    line-height: 42px;
}

h1.product-name-2017.pl {
    font-size: 29px;
}

h2 {
    font-size: 24px;
    line-height: 24px;
    padding: 5px 0 15px;
    margin: 0;
    font-weight: 300;
}

h2#subHome {
    font-size: 36px;
    padding: 35px 0 40px 0;
    margin: 0;
    font-weight: 300;
    line-height: 50px;
}

h2.crossroad#subHome {
    font-size: 36px;
    padding: 35px 0 17px 0;
    margin: 0;
    font-weight: 300;
    line-height: 50px;
}

h2.business#subHome {
    font-size: 36px;
    padding: 35px 0 25px 0;
    margin: 0;
    font-weight: 300;
    line-height: 50px;
}

h2.business-consumer#subHome {
    font-size: 36px;
    padding: 40px 0 35px 0;
    margin: 0;
    font-weight: 300;
    line-height: 50px;
}

h2.result {
    display: inline;
    font-size: 19px;
    line-height: 25px;
    margin: 0;
    font-weight: 300;
    font-family: Verdana !important;
}

h2.result a {
    line-height: 25px;
}

h2.subheadline {
    padding: 0 0 25px 0;
    color: #5c707b;
}

p.subheadline.smaller {
    font-size: 20px;
    padding: 0 0 25px 0;
    color: #5c707b;
    font-weight: 300;
}

h1.thin,
h2.thin,
h3.thin,
h4.thin {
    font-weight: 300;
}

h3 {
    font-size: 18px;
    padding: 5px 0 8px 0;
    margin: 0;
    font-weight: 300;
}

h3.forced {
    font-size: 18px !important;
    padding: 5px 0 8px 0 !important;
    margin: 0 !important;
    font-weight: 300 !important;
    font-family: AVGSans !important;
    color: #000000 !important;
}

h3.larger {
    font-size: 20px;
}

h2.smaller {
    font-size: 20px;
}

.third-box h3 {
    font-weight: 300;
    text-align: center;
    padding: 0 0 20px 0;
}

h4 {
    font-size: 14px;
    padding: 3px 0 5px 0;
    margin: 0;
    font-weight: 300;
}

h2.home-subheadline {
    text-align: center;
    font-weight: 300;
    color: #5c707b;
}


/* BADGES */

a.sq-badge h2 {
    font-family: AVGSans;
    color: #000000;
}

.badge h2 {
    text-align: center;
    line-height: 20px;
    padding-top: 200px;
    font-family: AVGSans;
    color: #000000;
    height: 36px;
}

.badge.cs h2 {
    line-height: 24px;
    height: 55px;
    padding-bottom: 24px;
}

.sq-badge h2 {
    padding: 0 0 10px 0;
}

.third-box h2 {
    text-align: center;
    padding: 0 0 10px 0;
}

.third-box.cs h2 {
    padding-bottom: 10px;
}

.third-box.pl h2 {
    padding-bottom: 2px;
}

.third-box.en_US h2,
.third-box.cs h2,
.third-box.fr h2 {
    padding-bottom: 5px;
}

.third-box.de h2 {
    padding-bottom: 0px;
}

.third-box.nl_NL h2 {
    font-size: 22px;
}


.third-box.community h2 {
    padding: 0;
}

ul.search-results h2 {
    display: inline;
    padding: 0;
}

div[id*=footer] div.box h4 {
    color: #FFFFFF;
    padding: 0;
    margin: 0 0 7px 0;
}


/* LISTS, ITEMS */

ul {
    padding: 10px 20px 10px 20px;
    margin: 0;
}

ul.plain {
    list-style-type: none;
    padding-left: 0;
    padding-right: 0;
}

li {
    padding-bottom: 10px;
}

ul.underlined li {
    border-bottom: solid 1px #dcdcdc;
    padding-bottom: 7px;
    padding-top: 7px;
}

ul.underlined li.last-item {
    border: 0;
}

ul.underlined li.last-item a {
    font-weight: bold;
}

ul.horizontal li {
    display: inline;
    padding-right: 9px;
}

ul.filter li a.selected {
    font-weight: bold;
}

ul.search-results {
    padding-bottom: 0;
}

.search-results li {
    padding-bottom: 3px;
}

.search-results li.even {
    font-family: Verdana;
    padding-bottom: 27px !important;
    color: #5c707b;
}

ul.product-list {
    margin-top: 15px;
}

ul.product-list li {
    padding: 0;
    display: inline-block;
    width: 152px;
    height: 122px;
    margin-bottom: 10px;
    float: left;
    padding: 30px 10px 0px 10px;
    margin-right: 6px;
}

ul.product-list li.wider {
    width: 172px;
    padding: 30px 0px 0px 0px;
}


ul.product-list li:hover {
    background-color: #f1f1f1;
    border-radius: 5px;
}

ul.horizontal .prod-icon {
    width: 100%;
    height: 100%;
    background-position: top center !important;
    color: #000000;
    font-family: AVGSans;
}

ul.horizontal .prod-icon h3 {
    text-align: center;
    line-height: 18px;
    position: relative;
    top: 60px;
    font-size: 16px;
    padding: 0;
    color: #0072D1;
}


/* COMMON ICONS AND IMAGE LINKS */

.logo {
    display: block;
    width: 90px;
    height: 36px;
    background: url('../img/logo.png') no-repeat left center;
}

.prominent-link {
    display: inline-block;
    font-family: AVGSans !important;
    border: 1px solid #0072D1;
    border-radius: 13px;
    padding: 3px 13px;
    font-size: 16px;
}

.prominent-link.inversed {
    line-height: 23px;
    color: #ffffff;
    border: 1px solid #0072D1;
    background: #0072D1;
}

[id*=article-print-email-box].es .prominent-link.inversed {
    font-size: 14.5px;
    line-height: 20px;
}

.prominent-link.business {
    margin-right: 25px;
}

.prominent-link.black {
    color: #ffffff;
    font-weight: bold !important;
    border: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: block;
    width: 120px;
    text-align: center;
    margin: 0 auto;
}

.third-box.community .prominent-link.black {
    margin-top: 145px;
}

.lte-ie8 .prominent-link.black {
    background: #48494B;
    /* IE 8 */
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
    /* IE 5-7 */
    filter: alpha(opacity=70);
}

.arrow-stripe {
    height: 30px;
    width: 100%;
    background: url('../img/guide-arrow.png') no-repeat center center;
    clear: both;
}

.email-note {
    width: 243px;
    height: 159px;
    background: url('../img/email-note/email-note-en.png') no-repeat center center;
}

.email-note.cs {
    background: url('../img/email-note/email-note-cs.png') no-repeat center center;
}

.email-note.de {
    background: url('../img/email-note/email-note-de.png') no-repeat center center;
}

.email-note.pt_BR {
    background: url('../img/email-note/email-note-pt-br.png') no-repeat center center;
}

.email-note.fr {
    background: url('../img/email-note/email-note-fr.png') no-repeat center center;
}

.email-note.cform {
    position: relative;
    top: 10px;
}

a.button, button.button {
    display: inline-block;
    font-family: AVGSans;
    font-size: 18px;
    background-color: #0072D1;
    color: #ffffff;
    border: 0;
    border-radius: 5px;
    padding: 14px 30px;
    text-align: center;
}

button.button.wide {
    min-width: 200px;
}

button {
    cursor: pointer;
    background-color: transparent;
    border: 0;
}

a.button:hover, button.button:hover {
    background-color: #3aa9fe;
}

a.button.inverted,
a.button.stype {
    display: block;
    width: 220px;
    padding: 12px 0;
    text-align: center;
}

a.button.inverted {
    background-color: #ffffff;
    color: #0072D1;
    border: solid 1px #0072D1;
}

div.support-type-buttons a.button.inverted,
div.support-type-buttons a.button.stype {
    float: left;
    margin-right: 20px;
}

div.support-type-buttons.business a.button.stype,
div.support-type-buttons.partner a.button.stype {
    width: 280px;
}

#techButton.wide {
    width: 300px !important;
}

.suitcase {
    display: inline-block;
    width: 18px;
    height: 15px;
    background: url('../img/icons/suitcase.png') no-repeat center center;
}

.label {
    display: inline-block;
    width: 18px;
    height: 15px;
    background: url('../img/icons/label.png') no-repeat center center;
}

.comm-small {
    display: inline-block;
    width: 18px;
    height: 15px;
    background: url('../img/icons/comm-small.png') no-repeat center center;
}

.kb {
    width: 61px;
    height: 20px;
    background: url('../img/icons/kb.png') no-repeat center center;
}

.solved {
    width: 82px;
    height: 20px;
    background: url('../img/icons/solved.png') no-repeat center center;
}

.unsolved {
    width: 77px;
    height: 20px;
    background: url('../img/icons/unsolved.png') no-repeat center center;
}

.kb,
.solved,
.unsolved {
    display: inline-block;
    position: relative;
    top: 3px;
    padding-left: 10px;
}

.badge-homepage .badge-container {
    width: 220px;
    margin-right: 20px;
}

.badge-homepage.en .badge-container {
    width: 172px;
    margin-right: 20px;
}

.badge-container.tripplecontainer {
    width: 293px;
    margin-right: 20px;
}

.badge {
    display: block;
    width: 178px;
}

.badge.tripplecontainer {
    display: block;
    width: 300px;
}

.flag {
    width: 22px;
    height: 16px;
}

[id*=mobile-language-selector] {
    display: none;
}

.flag.en_US {
    background: url('../img/icons/flags/us.png') no-repeat center center;
}

.flag.de {
    background: url('../img/icons/flags/de.gif') top left no-repeat;
}

.flag.cs {
    background: url('../img/icons/flags/cz.gif') top left no-repeat;
}

.flag.es {
    background: url('../img/icons/flags/es.gif') top left no-repeat;
}

.flag.fr {
    background: url('../img/icons/flags/fr.gif') top left no-repeat;
}

.flag.it {
    background: url('../img/icons/flags/it.gif') top left no-repeat;
}

.flag.nl_NL {
    background: url('../img/icons/flags/nl.gif') top left no-repeat;
}

.flag.pl {
    background: url('../img/icons/flags/pl.gif') top left no-repeat;
}

.flag.pt_BR {
    background: url('../img/icons/flags/br.gif') top left no-repeat;
}

.flag.ja {
    background: url('../img/icons/flags/ja.png') top left no-repeat;
}

.sq-badge {
    width: 320px;
    min-height: 56px;
    background-color: #f1f1f1;
    padding: 30px 30px 30px 110px;
}

.sq-badge:hover {
    background-color: #dcdcdc;
}

a.sq-badge {
    display: block;
}

.sq-badge.sales {
    background-image: url('../img/icons/label-large.png');
    background-repeat: no-repeat;
    background-position: 30px 30px;
}

.sq-badge.bussines {
    background-image: url('../img/icons/suitcase-large.png');
    background-repeat: no-repeat;
    background-position: 30px 30px;
}

.sq-badge.home {
    height: 80px;
    background-color: #f1f1f1;
}

.outer-consumer-fields {
    display: table-cell;
    vertical-align: middle;
    height: 80px;
}

.inner-consumer-fields {
    vertical-align: middle;
}

.sq-badge.home h2 {
    color: #0072D1;
    font-size: 18px;
    padding-top: 0px;
    font-family: AVGSans;
    padding-bottom: 0px;
}

.sq-badge.home:hover h2 {
    text-decoration: underline;
}

.sq-badge.nl_NL .outer-consumer-fields .inner-consumer-fields h2 {
    font-size: 17px;
}

.sq-badge.it .outer-consumer-fields .inner-consumer-fields h2 {
    font-size: 16px;
}

.sq-badge.fr .outer-consumer-fields .inner-consumer-fields h2,
.sq-badge.de .outer-consumer-fields .inner-consumer-fields h2 {
    font-size: 14px;
}

.sq-badge.pl .outer-consumer-fields .inner-consumer-fields h2 {
    font-size: 12px;
}

.sq-badge.home.sales {
    background-image: none;
}

.sq-badge.home.sales .image {
    background-image: url('../img/icons/home-top-sprite.png');
    background-repeat: no-repeat;
    margin: 0px 20px 0px 0px;
    width: 40px;
    height: 40px;
    display: inline-block;
    vertical-align: middle;
    background-position: 0px 0px;
}

.sq-badge.home.sales:hover .image {
    background-position: -60px 0px;
}

.sq-badge.home.partner {
    background-image: none;
    border-left: 2px solid white;
    border-right: 2px solid white;
}

.sq-badge.home.partner .image {
    background-image: url('../img/icons/home-top-sprite.png');
    background-repeat: no-repeat;
    margin: 0px 20px 0px 0px;
    width: 40px;
    height: 40px;
    display: inline-block;
    vertical-align: middle;
    background-position: 0px -60px;
}

.sq-badge.home.partner:hover .image {
    background-position: -60px -60px;
}

.sq-badge.home.business {
    background-image: none;
}

.sq-badge.home.business .image {
    background-image: url('../img/icons/home-top-sprite.png');
    background-repeat: no-repeat;
    margin: 0px 20px 0px 0px;
    width: 40px;
    height: 40px;
    display: inline-block;
    vertical-align: middle;
    background-position: 0px -120px;
}

.sq-badge.home.business:hover .image {
    background-position: -60px -120px;
}

.sq-badge.home {
    padding: 0px 2px 0px 32px;
    width: 33%;
    box-sizing: border-box;
}

.sq-badge.home .image {
    margin: 0 10px 0 0;
}

.sq-badge.home h2 {
    font-size: 17px;
}

.soc-icon {
    display: block;
    width: 25px;
    height: 25px;
    float: left;
    margin-right: 8px;
}

.soc-icon.fb {
    background: url('../img/icons/icon-fb.svg') no-repeat center center;
}

.soc-icon.twitter {
    background: url('../img/icons/icon-twitter.svg') no-repeat center center;
}

.soc-icon.rss {
    background: url('../img/icons/icon_rss.png') no-repeat center center;
}

.soc-icon.youtube {
    background: url('../img/icons/icon_youtube.svg') no-repeat center center;
}

.soc-icon.linkedin {
    background: url('../img/icons/icon_linkedin.svg') no-repeat center center;
}

.soc-icon.gplus {
    background: url('../img/icons/icon_google_plus.png') no-repeat center center;
}

.copyright-company {
    display:inline-block
}

.copyright-company img {
    margin-inline-end:4px;
    vertical-align:bottom;
}

@media (min-width: 992px) {
    .copyright-company img {
        vertical-align:text-bottom
    }
}

.lg-icon {
    width: 9.4%;
    height: 60px;
    background-position: top left;
    background-repeat: no-repeat;
    background-size: contain;
}

i.left.lg-icon {
    margin-right: 5%;
}

i.facebook-icon-large {
    background-image: url('../img/icons/facebook-logo-large.png');
    width: 60px;
    height: 60px;
    display: inline-block;
    margin: 0px 10px 0px 10px;
}

i.twitter-icon-large {
    background-image: url('../img/icons/twitter-logo-large.png');
    width: 60px;
    height: 60px;
    display: inline-block;
    margin: 0px 10px 0px 10px;
}

.lg-icon.buoy {
    background-image: url('../img/icons/sales-contact.png');
    width: 35px;
    height: 35px;
}

.lg-icon.refund {
    background-image: url('../img/icons/sales-refund.png');
    width: 29px;
    height: 60px;
    margin-left: 3px;
    margin-top: 7px;
}

.lg-icon.retrieve {
    background-image: url('../img/icons/sales-key.png');
    width: 35px;
    height: 35px;
}

.lg-icon.faq {
    background-image: url('../img/icons/sales-faq.png');
    width: 35px;
    height: 35px;
}

.lg-icon.label {
    background-image: url('../img/icons/label-large.png');
}

.wrapper.contact.faq ul {
    list-style: none;
    padding: 10px 0 0 0;
}

.wrapper.contact.faq ul li {
    padding-bottom: 5px;
}

h2.product-list-subheadline-faq {
    padding-top: 10px;
    font-size: 28px;
}

div.print-icon-small {
    width: 25px;
    height: 20px;
    margin-right: 10px;
    background: url('../img/icons/print.png') no-repeat center center;
}

div.email-icon-small {
    width: 23px;
    height: 20px;
    margin-right: 10px;
    background: url('../img/icons/email.png') no-repeat center center;
}

div.article-side-box .icon-link-wrap a {
    width: 145px;
}

.prod-icon {
    display: block;
    width: 50px;
    height: 50px;
    background-size: 50px 50px !important;
}

.prod-icon.small {
    width: 40px;
    height: 40px;
    background-size: 40px 40px !important;
}

.prod-icon.small.before-headline {
    float: left;
    margin-right: 20px !important;
    margin-bottom: 20px !important;
}

.prod-icon.protection {
    background: url('../img/icons/products/protection.png') no-repeat center center;
}

.prod-icon.performance {
    background: url('../img/icons/products/AVG-Performance.png') no-repeat center center;
}

.prod-icon.ultimate {
    background: url('../img/icons/products/ultimate.png') no-repeat center center;
}

.prod-icon.antivirus {
    background: url('../img/icons/products/antivirus.png') no-repeat center center;
}

.prod-icon.antivirus-free {
    background: url('../img/icons/products/antivirus-free.png') no-repeat center center;
}

.prod-icon.antivirus-free-android {
    background: url('../img/icons/products/android-antivirus-free.png') no-repeat center center;
}

.prod-icon.internet-security {
    background: url('../img/icons/products/internet-security.png') no-repeat center center;
}


.prod-icon.privacy-fix {
    background: url('../img/icons/products/privacy-fix.png') no-repeat center center;
}

.prod-icon.avg-secure-browser {
    background: url('../img/icons/products/avg-secure-browser.png') no-repeat center center;
}

.prod-icon.mobile-security-ios {
    background: url('../img/icons/products/mobile-security.png') no-repeat center center;
}

.prod-icon.tuneup-premium-mac {
    background: url('../img/icons/products/tuneup_premium_mac.png') no-repeat center center;
}

ul.rightArrow{
    list-style: none;
}

ul.rightArrow li::before {
    content: '';
    display: inline-block;
    background-image: url('../img/icons/rsz_right-arrow.png') ;
    background-size: contain;
    width: 10px;
    height: 12px;
    background-repeat: no-repeat;
    vertical-align: middle;
}

.prod-icon.photo-cleaner {
    background: url('../img/icons/products/photo-cleaner.png') no-repeat center center;
}

.prod-icon.tune-up {
    background: url('../img/icons/products/tune-up.png') no-repeat center center;
}

.prod-icon.tune-up-new {
    background: url('../img/icons/products/tune-up-new.png') no-repeat center center;
}

.prod-icon.tuu {
    background: url('../img/icons/products/tuu.png') no-repeat center center;
}

.prod-icon.driver-updater {
    background: url('../img/icons/products/driver-updater.png') no-repeat center center;
}

.prod-icon.safe-surf {
    background: url('../img/icons/products/safe-surf.png') no-repeat center center;
}

.prod-icon.secure-vpn {
    background: url('../img/icons/products/secure-vpn.png') no-repeat center center;
}

.prod-icon.secure-vpn-android {
    background: url('../img/icons/products/android-secure-vpn.png') no-repeat center center;
}

.prod-icon.secure-vpn-ios {
    background: url('../img/icons/products/secure_vpn_ios.png') no-repeat center center;
}

.prod-icon.dealfinder {
    background: url('../img/icons/products/dealfinder.png') no-repeat center center;
}

.prod-icon.antitrack {
    background: url('../img/icons/products/antitrack.png') no-repeat center center;
}

.prod-icon.battery-saver {
    background: url('../img/icons/products/battery-saver.png') no-repeat center center;
}

.prod-icon.breach-guard {
    background: url('../img/icons/products/breach-guard.png') no-repeat center center;
}

.prod-icon.cleaner-android {
    background: url('../img/icons/products/android-cleaner.png') no-repeat center center;
}

.prod-icon.antivirus-pro {
    background: url('../img/icons/products/antivirus-pro.png') no-repeat center center;
}

.prod-icon.security-suite {
    background: url('../img/icons/products/security-suite.png') no-repeat center center;
}

.prod-icon.alarm-clock {
    background: url('../img/icons/products/alarm-clock.png') no-repeat center center;
}

.prod-icon.stop-watch {
    background: url('../img/icons/products/stop-watch.png') no-repeat center center;
}

.prod-icon.image-shrink {
    background: url('../img/icons/products/image-shrink.png') no-repeat center center;
}

.prod-icon.uninstaller {
    background: url('../img/icons/products/uninstaller.png') no-repeat center center;
}

.prod-icon.family-mobile {
    background: url('../img/icons/products/family-mobile.png') no-repeat center center;
}

.prod-icon.family-pc {
    background: url('../img/icons/products/family-pc.png') no-repeat center center;
}

.prod-icon.androzip-fm {
    background: url('../img/icons/products/androzip-fm.png') no-repeat center center;
}

.prod-icon.andro-tm {
    background: url('../img/icons/products/andro-tm.png') no-repeat center center;
}

.prod-icon.lock-screen {
    background: url('../img/icons/products/lock-screen.png') no-repeat center center;
}

.prod-icon.vault {
    background: url('../img/icons/products/vault.png') no-repeat center center;
}

.prod-icon.android {
    background: url('../img/icons/products/android.png') no-repeat center center;
}

.prod-icon.safe-secure {
    background: url('../img/icons/products/safe-secure.png') no-repeat center center;
}

.prod-icon.secure-browser {
    background: url('../img/icons/products/secure-browser.png') no-repeat center center;
}

.prod-icon.secure-browser-android {
    background: url('../img/icons/products/android-secure-browser.png') no-repeat center center;
}

.prod-icon.myaccount {
    background: url('../img/icons/products/myaccount.png') no-repeat center center;
}

.prod-icon.secureidentity {
    background: url('../img/icons/products/secureidentity.png') no-repeat center center;
}

prod-icon.premium-tech-support {
    background: url('../img/icons/products/ptsproduct.png') no-repeat center center;
}

.prod-icon.premium-security {
    background: url('../img/icons/products/premium-security.png') no-repeat center center;
}

.prod-icon.av-business {
    background: url('../img/icons/products/business/av-business.png') no-repeat center center;
}

.prod-icon.is-business {
    background: url('../img/icons/products/business/is-business.png') no-repeat center center;
}

.prod-icon.pctu-business {
    background: url('../img/icons/products/business/pctu-business.png') no-repeat center center;
}

.prod-icon.file-server {
    background: url('../img/icons/products/business/file-server.png') no-repeat center center;
}

.prod-icon.cloudcare {
    background: url('../img/icons/products/business/cloudcare.png') no-repeat center center;
}

.prod-icon.managed-workplace {
    background: url('../img/icons/products/business/managed-workplace.png') no-repeat center center;
}

.prod-icon.business-sso {
    background: url('../img/icons/products/business/business-sso.png') no-repeat center center;
}

.prod-icon.avg-expert-care {
    background: url('../img/icons/products/avg-expert-care.png') no-repeat center center;
}


.article-lg-icon .left.wrap {
    width: 82%;
}

.third-box.community {
    display: block;
    width: 278px;
    border: solid 1px #dcdcdc;
    border-bottom: 0;
    border-top: 0;
    background: url('../img/lifestyle/consumer-support-avg-support-community.png') no-repeat center center;
    font-family: AVGSans;
    color: black;
}

.third-box.community.fr h3,
.third-box.community.es h3,
.third-box.community.pt_BR h3 {
    display: none;
}

.third-box.community.fr .prominent-link.black {
    width: 90%;
    margin-top: 165px;
}

.third-box .link-wrap {
    width: 150px;
    padding-top: 130px;
}

.banner {
    background-color: #fafafa;
    background-image: url('../img/pts-banner-bg.png');
    background-position: right center;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 150px;
    margin: 20px 0;
}

.banner a {
    font-family: AVGSans;
}

.banner div {
    text-align: center;
    padding: 0 50px;
    width: 62%;
}

.banner h2 {
    font-size: 36px;
    padding: 15px 0 0 0;
}

.banner h3 {
    padding: 0;
    font-size: 19px;
    font-weight: 300 !important;
    color: #5c707b;
}

.banner h2,
.banner h3 {
    margin-bottom: 15px;
}

.banner .prominent-link {
    font-family: Verdana;
    text-align: center;
    font-size: 14px;
    width: 150px;
    padding: 5px 15px;
}


/* DEVICES */

.devices-wrapper {
    width: 714px;
    padding: 30px 0;
    text-align: center;
}

.devices-logo {
    width: 153px;
    height: 42px;
    background: url('../img/devices-text/devices.png') no-repeat center center;
}

.devices-en_us {
    width: 563px;
    margin: 0px auto;
}

.devices-text-en_us {
    width: 395px;
    height: 42px;
    background: url('../img/devices-text/device-text-en_us.png') no-repeat center center;
    margin-left: 15px;
}

.devices-de {
    width: 711px;
    margin: 0px auto;
}

.devices-text-de {
    width: 543px;
    height: 42px;
    background: url('../img/devices-text/device-text-de.png') no-repeat center center;
    margin-left: 15px;
}

.devices-cs {
    width: 675px;
    margin: 0px auto;
}

.devices-text-cs {
    width: 507px;
    height: 42px;
    background: url('../img/devices-text/device-text-cs.png') no-repeat center center;
    margin-left: 15px;
}

.devices-fr {
    width: 686px;
    margin: 0px auto;
}

.devices-text-fr {
    width: 518px;
    height: 42px;
    background: url('../img/devices-text/device-text-fr.png') no-repeat center center;
    margin-left: 15px;
}

.devices-it {
    width: 632px;
    margin: 0px auto;
}

.devices-text-it {
    width: 464px;
    height: 42px;
    background: url('../img/devices-text/device-text-it.png') no-repeat center center;
    margin-left: 15px;
}

.devices-nl_nl {
    width: 618px;
    margin: 0px auto;
}

.devices-text-nl_nl {
    width: 450px;
    height: 42px;
    background: url('../img/devices-text/device-text-nl_nl.png') no-repeat center center;
    margin-left: 15px;
}

.devices-pl {
    width: 714px;
    margin: 0px auto;
}

.devices-text-pl {
    width: 546px;
    height: 42px;
    background: url('../img/devices-text/device-text-pl.png') no-repeat center center;
    margin-left: 15px;
}

.devices-es {
    width: 628px;
    margin: 0px auto;
}

.devices-text-es {
    width: 460px;
    height: 42px;
    background: url('../img/devices-text/device-text-es.png') no-repeat center center;
    margin-left: 15px;
}

.devices-pt_br {
    width: 583px;
    margin: 0px auto;
}

.devices-text-pt_br {
    width: 415px;
    height: 42px;
    background: url('../img/devices-text/device-text-pt_br.png') no-repeat center center;
    margin-left: 15px;
}

.devices-ja {
    width: 714px;
    margin: 0px auto;
}

.devices-text-ja {
    width: 546px;
    height: 42px;
    background: url('../img/devices-text/device-text-ja.png') no-repeat center center;
    margin-left: 15px;
}

.wrapper.pts {
    width: 100% !important;
    padding: 0px !important;
}

.wrapper.pts.white {
    background-color: #ffffff;
}

.wrapper.pts.grey {
    background-color: #fafafa;
}

.wrapper.pts.marginTop {
    margin-top: 40px;
}

.devices-wrapper.pts {
    position: relative;
    padding: 0px !important;
    margin: 0px auto;
}

.devices-wrapper.pts.content {
    padding: 0px;
}

.devices-wrapper.pts a {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: block;
}

.devices-wrapper.pts span.innerText {
    font-size: 16px;
    line-height: 20px;
}

.devices-wrapper.pts span.highlight, .devices-wrapper.pts span.number {
    color: #0072d1;
}

.devices-wrapper.pts.global {
    width: 714px;
}

.devices-wrapper.pts.global div.content{
    background-image: url('../img/pts/icon_multi-device.svg');
    background-repeat: no-repeat;
    padding: 44px 100px 44px 200px;
    background-size: 67px 54px;
    background-position: left 100px center;
    text-align: left;
}

.devices-wrapper.pts.global span.line {
    display: inline;
}

.devices-wrapper.pts.number {
    width: 640px;
}

.devices-wrapper.pts.number div.content{
    background: url('../img/pts/devices_max.png') no-repeat center center;
    background-repeat: no-repeat;
    padding: 40px 153px 40px 27px;
    background-size: 126px 91px;
    background-position: right 27px center;
    text-align: left;
}

.devices-wrapper.pts.number span.note {
    font-size: 10px;
    line-height: 13px;
    display: block;
    margin-top: 23px;
}

.devices-wrapper.pts.number span.line {
    display: block;
}


/* HEADER + header elements */

[id*=header] {
    height: 85px;
    margin-bottom: 30px;
    font-weight: 300;
}

body.header-no-bottom-margin [id*=header] {
    margin-bottom: 0;
}

body.header-no-bottom-margin-and-border [id*=header] {
    margin-bottom: 0;
    border-bottom: none;
}

[id*=header] a.logo {
    padding: 0 35px 0 0;
    margin: 25px 0 5px 0;
}

[id*=header] h2 {
    font-family: AVGSans;
    font-weight: 300;
    color: #5c707b;
    font-size: 26px;
    border-left: solid 1px #000000;
    line-height: 30px;
    padding: 3px 10px 3px 35px;
    margin-top: 25px;
}

[id*=header] h2.pl,
[id*=header] h2.nl_NL {
    font-size: 20px;
}

[id*=header] div.right.with-search {
    width: 410px;
    padding: 0;
}

[id*=header] div.right.wrapper.with-menu {
    width: 805px;
    padding-right: 0;
    padding-left: 0;
}

.lte-ie7 [id*=header] div.right.wrapper.with-menu ul.right.plain {
    padding: 0;
}

[id*=header] div.right.wrapper.login {
    padding: 12px 0 0 0;
    width: 300px;
}

[id*=header] .loginLink {
    font-size: 12px;
    color: #5c707b;
    padding-right: 12px;
    border-right: solid 1px #5c707b;
    margin-right: 12px;
}

[id*=header] .integrated-search input[type="text"] {
    width: 348px;
}

[id*=header] .integrated-search input[type="text"],
[id*=header] .integrated-search input[type="submit"],
[id*=header] .integrated-search input[type="button"],
[id*=header] .integrated-search button {
    padding-top: 6px;
    padding-bottom: 6px;
}

[id*=header] .integrated-search input[type="button"],
[id*=header] .integrated-search input[type="submit"],
[id*=header] .integrated-search button {
    width: 40px;
    height: 31px;
    background: #0072D1 url('/resource/1402642864000/support_images/icons/search-icon-small.png') no-repeat center center;
}

[id*=header] .integrated-search input[type="button"]:hover,
[id*=header] .integrated-search input[type="submit"]:hover,
[id*=header] .integrated-search button:hover {
    background-color: #3aa9fe;
}


/* HEADER PICTURE*/

.homepage-header-wrap {
    background-color: white;
    background-size: 1240px 100%;
    background-position: top center;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 400px;
    padding-bottom: 100px;
}

.homepage-header-wrap .display-table {
    height: 350px;
}

.homepage-header-wrap.business-background,
.homepage-header-wrap.partners-background,
.homepage-header-wrap.consumer-background {
   background-color: #ffffff;
   min-height: 320px;
}

.homepage-header-wrap.poster-background {
    min-height: 270px !important;
}

.homepage-header-wrap.consumer-background.pl {
    min-height: 420px;
}

.homepage-header-wrap.business-background .wrapper h1 span,
.homepage-header-wrap.partners-background .wrapper h1 span,
.homepage-header-wrap.consumer-background .wrapper h1 span
{
    color: #5c707b;
}

.homepage-header-wrap.crossroad-background {
    background-image: url('../img/lifestyle/header-support-homepage.png');
    background-color: #f3f3f3;
}

.homepage-header-wrap .wrapper {
    padding-top: 100px;
}

.homepage-header-wrap.business-background .wrapper,
.homepage-header-wrap.partners-background .wrapper,
.homepage-header-wrap.consumer-background .wrapper{
    padding-top: 40px;
}

.homepage-header-wrap.crossroad-background .wrapper {
    padding-top: 40px;
}

.homepage-header-wrap.consumer-background.pl .wrapper {
    padding-top: 85px;
}

html.lte-ie7 div.crossroad-background div div div div.vertical-align-middle {
    /* IE7 fixing */
    vertical-align: auto;
    padding-top: 140px;
}

html.lte-ie7 a.sq-badge div.outer-consumer-fields div.inner-consumer-fields {
    /* IE7 fixing: */
    padding-top: 20px;
}


/* CONTENT + content elements */

.content-box {
    min-height: 300px;
}

div#searchresults {
    min-height: 500px;
}

.std-stripe {
    padding: 25px 0px;
}

.small-stripe {
    padding: 15px 20px;
}

.display-none {
    display: none;
}

.display-table {
    display: table;
}

.display-row {
    display: table-row;
}

.display-cell {
    display: table-cell;
}

div.lg-stripe,
section.lg-stripe {
    padding: 40px 0px;
}

div.wrapper + div.homepage {
    width: 90%;
    padding: 0px 0px;
    margin: 0 auto;
}

.content-nav {
    padding-bottom: 40px !important;
}

input[type="text"],
input[type="password"],
input[type="button"],
input[type="submit"],
select,
textarea {
    font-family: Verdana;
    font-size: 14px;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #959595;
    border-radius: 5px;
    -webkit-appearance: none !important;

}

input[type="button"].plain {
    background: #FFFFFF;
    border: 0;
    font-size: 18px;
    font-family: Verdana;
    color: #5c707b;
    padding: 0;
}

[id*=feedbackpanel] input[type="submit"].plain,
#select-option .plain {
    margin-left: 50px;
    cursor: pointer;
}

[id*=feedbackpanel] input[type="submit"].y-n-radio-like,
#select-option .y-n-radio-like {
    display: block;
    width: 70px;
    text-align: right;
    background: url('../img/icons/radio.png') no-repeat left center;
    border: 0;
    font-size: 1.3em;
    color: #5c707b;
    padding: 0;
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 0;
}

.newForm #select-option .y-n-radio-like {
    background: url('../img/icons/input-radio.png') no-repeat left center;
}

#select-option {
    margin: 10px 0px 20px 0px;
}

#select-option #y-n-buttons-wrap {
    height: 24px;
}

#select-option .y-n-radio-like {
    display: inline-block !important;
    margin-left: 0px;
    width: 24px;
    margin-right: 10px;
}

#select-option button {
    font-family: Verdana;
    color: #5c707b;
    font-size: 18px;
    margin-right: 30px;
    cursor: pointer;
    line-height: 24px;
    display: inline-flex;
    border: 0;
    background-color: transparent;
}

#select-option button.secondOne {
    margin-left: 60px;
}

[id*=feedbackpanel] input[type="submit"].y-n-radio-like,
[id*=feedbackpanel] h3,
#select-option .y-n-radio-like {
    height: 24px;
}

[id*=feedbackpanel] h3 {
    padding: 0;
}

[id*=feedbackpanel] input[type="submit"].y-n-radio-like:hover,
#select-option .y-n-radio-like:hover,
#select-option .y-n-radio-like.sel,
#select-option button:hover i {
    background: url('../img/icons/radio-sel.png') no-repeat left center;
}

.newForm #select-option .y-n-radio-like:hover,
.newForm #select-option .y-n-radio-like.sel,
.newForm #select-option button:hover i {
    background: url('../img/icons/input-radio-checked.png') no-repeat left center;
}

.newForm #select-option #y-n-buttons-wrap button:hover,
.newForm #select-option #y-n-buttons-wrap button.sel {
    color: #0072D1;
}


#y-n-buttons-wrap.de input.no {
    width: 80px;
}

.integrated-search input[type="text"],
.integrated-search input[type="button"],
.integrated-search select,
.integrated-search textarea,
form.integrated-search input[type="text"],
form.integrated-search input[type="button"],
form.integrated-search select,
form.integrated-search textarea {
    border: 1px solid #0072D1;
}

.integrated-search input[type="text"] {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    float: left;
}

.integrated-search input[type="text"],
form.integrated-search input[type="text"] {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    float: left;
}

.integrated-search input[type="button"],
.integrated-search input[type="submit"],
.integrated-search button,
form.integrated-search input[type="button"],
form.integrated-search input[type="submit"],
form.integrated-search button {
    float: left;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    cursor: pointer;
}

.crossroad .integrated-search {
    margin-top: 5px;
}

.homepage select {
    width: 83%;
    height: 28px;
    line-height: 28px;
}

.homepage input[type="text"] {
    height: 28px;
    line-height: 28px;
}

div.homepage div.homepage-search-wrapper {
    display: block;
    width: 90%;
    position: relative;
    margin: 0px auto;
}

div.homepage div.homepage-search-wrapper input.textBox {
    display: block;
    padding-left: 1%;
    padding-right: 0;
    width: 96%;
}

div.homepage div.homepage-search-wrapper input[type="submit"] {
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 10px;
}

.homepage input[type="button"],
.homepage input[type="submit"],
.homepage button {
    width: 80px;
    height: 50px;
    background: #0072D1 url('../img/icons/search-icon-large.png') no-repeat center center;
}

.homepage input[type="button"]:hover,
.homepage input[type="submit"]:hover,
.homepage button:hover {
    background-color: #3aa9fe;
}

.search-switch {
    padding: 0 12px;
}

.search-switch.selected {
    color: #5c707b;
    font-weight: bold;
}

.search-switch.with-delimiter {
    border-right: solid 1px #5c707b;
}

textarea.full-width,
input[type="text"].half-width {
    width: 47% !important;
}

textarea.full-width,
input[type="text"].full-width {
    width: 97% !important;
}

.cform-col input[type="text"].full-width {
    width: 90% !important;
}

.font-size-13px {
    font-size: 13px;
}

[id*=alert].the-form-static-width {
    margin: 10px 0;
    padding: 20px;
    width: 600px;
}

[id*=alert] {
    margin: 10px 0;
    padding: 20px;
}

.error-panel {
    padding-left: 60px !important;
    background: url('../img/icons/alert-icon.png') no-repeat left center;
    min-height: 35px;
}

.error-panel p {
    margin: 0px 0px 10px 0px;
}

.alert-icon-small {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-right: 15px;
    background: url(../img/icons/alert-icon-small.png) no-repeat center center;
}

.with-alert-icon-small {
    height: 30px;
    line-height: 30px;
}

#avg-go-refund-note {
    width: 590px;
    margin-left: auto;
    margin-right: auto;
}

select {
    overflow: hidden;
}

input[type="button"],
input[type="submit"] {
    background-color: #0072D1;
    color: #ffffff;
    border: 0;
    padding: 14px;
    font-weight: 300;
    cursor: pointer;
}

input[type="button"]:disabled,
input[type="submit"]:disabled {
    background: #dcdcdc none repeat scroll 0 0 !important;
    color: #5c707b;
    cursor: auto;
    box-shadow: none !important;
}

i.cform-button-disabled {
    display: block;
    margin-top: 10px;
}

input[type="button"].cform,
input[type="submit"].cform,
a.button.cform {
    padding: 21px 0;
    width: 300px;
}

input[type="button"].cform:hover,
input[type="submit"].cform:hover,
a.button.cform:hover,
input[type="button"].cform:focus,
input[type="submit"].cform:focus,
a.button.cform:focus{
    background-color: #3aa9fe;
}

input[type="button"].cform.disabled,
input[type="submit"].cform.disabled {
    background: #dcdcdc;
    color: #5c707b;
    cursor: auto;
}

input.cform.disabled[type="submit"], input.cform.disabled[type="button"] {
    background: #dcdcdc none repeat scroll 0 0 !important;
    color: #5c707b;
    cursor: auto;
    box-shadow: none !important;
}

.contact-radio-box {
    margin: 0 0 5px 0;
    cursor: pointer;
}

.contact-radio-box input[type=radio] {
    display: none;
}

.lte-ie8 .contact-radio-box input[type=radio] {
    display: block;
    width: 20px;
    height: 20px;
}

.contact-radio-box button {
    margin: 0;
    padding: 0;
    text-align: left;
    width: 100%;
    padding: 5px;
}

dl.consumer dd div.contact-radio-box dl {
    margin: 0;
}

.contact-radio-box button span.heading {
    font-size: 18px;
    margin: 0;
    font-weight: 300;
    font-family: AVGSans;
}

.contact-radio-box button span.channel-text {
    color: #5c707b;
    font-family: Verdana;
    line-height: 22px;
}

.contact-radio-box button:hover span.heading,
.contact-radio-box button:focus span.heading,
.contact-radio-box button.selected span.heading {
    color: #0072d1;
}

.contact-radio-box button:hover .radio-like-business,
.contact-radio-box button:focus .radio-like-business,
.contact-radio-box button.selected .radio-like-business{
    background-position: -44px 0px;
}

.cform.radio-like {
    width: 24px;
    height: 24px;
    margin: 18px 15px 0 0;
    background-image: url('../img/icons/input-radio-sprite.png');
    background-repeat: no-repeat;
    background-position: 0 0;
}



.cform.radio-like ~ .inner.email {
    margin-top: 8px;
}

.lte-ie8 .cform.radio-like {
    display: none;
}

.contact-radio-box input[type=radio]:checked + .cform.radio-like {
    background-position: -44px 0;
}

.channel-icon {
    width: 60px;
    height: 60px;
    margin: 0 20px 0 0;
    background-repeat: no-repeat;
    background-position: 0 0;
}

.contact-radio-box .inner.chat .channel-icon {
    background-image: url('../img/chat-button.png');
}

.contact-radio-box .inner.phone .channel-icon {
    background-image: url('../img/phone-button.png');
}

.contact-radio-box .inner.email .channel-icon {
    background-position: 0 -10px;
    background-image: url('../img/email-button.png');
}

.contact-radio-box .inner:hover .channel-icon,
.contact-radio-box input[type=radio]:checked ~ .inner .channel-icon {
    background-position: 0 -65px;
}

.contact-radio-box .inner.email:hover .channel-icon,
.contact-radio-box input[type=radio]:checked ~ .inner.email .channel-icon {
    background-position: 0 -75px;
}

.contact-radio-box:hover .cform.radio-like {
    background-position: -44px 0;
}

.cform.radio-like:hover ~ .inner .channel-icon {
    background-position: 0 -65px;
}

.cform.radio-like:hover ~ .inner.email .channel-icon {
    background-position: 0 -75px;
}

.cform.radio-like:hover ~ .inner h3 {
    color: #0072D1;
}

.contact-radio-box .inner p {
    padding: 0;
    margin: 5px 0;
}

.contact-radio-box .inner:hover h3,
.contact-radio-box input[type=radio]:checked ~ .inner h3 {
    color: #0072D1;
}

input[type="radio"] {
    margin-right: 15px;
}

textarea {
    resize: none;
}

ul.search-results a:link,
a:visited {
    font-family: AVGSans;
}

.link-wrap.single {
    padding: 50px 0 30px 0 !important;
}

ul.search-results i.solved,
ul.search-results i.kb {
    display: inline-block;
    margin-left: 10px;
}

ul.search-results i.kb {
    position: relative;
    top: 2px;
}

.result-table thead {
    display: none;
}

.result-table tr {
    height: 35px;
}

.wrapper.loader {
    min-height: 500px;
}

tr.summary {
    background-color: #f5f5f5;
}

tr.summary td {
    padding: 15px 25px;
}

[id*=ccpanel] input[type=radio] {
    display: none;
}

[id*=ccpanel] label {
    padding-left: 33px;
    height: 20px;
    display: inline-block;
    line-height: 20px;
    background-repeat: no-repeat;
    background-position: 0 0;
    font-size: 20px;
    vertical-align: middle;
    cursor: pointer;
    background-image: url('../img/radio.png');
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

[id*=ccpanel] input[type=radio]:checked + span label {
    background-position: 0 -20px;
}

tr.ie-only {
    display: none;
}

.lte-ie9 tr.ie-only {
    /* CONTACT FORM - display labels for cells in IE8 and less */
    display: block;
}

.lte-ie9 tr.ie-only p {
    /* CONTACT FORM - display labels for cells in IE8 and less */
    margin: 15px 0px 0px;
    font-size: 1.3em;
}

.lte-ie8 [id*=ccpanel] input[type=radio] {
    display: inline;
}

.lte-ie8 [id*=ccpanel] label {
    padding-left: 0;
    background-image: none;
}

.survey-box {
    margin: 20px 0;
}

.survey-box p.comment {
    font-size: 80%;
    margin: 0;
    line-height: 1.50em;
}

fieldset {
    padding: 0;
    margin: 0;
}

.survey-box input[type=radio] {
    display: none;
}

div.survey-box.horizontal fieldset {
    float: left;
}

div.survey-box.horizontal p.fieldset-range {
    margin: 25px;
}

.survey-box.horizontal label {
    padding-bottom: 33px;
    margin: 0 10px;
    height: 20px;
    width: 20px;
    text-align: center;
    display: inline-block;
    line-height: 18px;
    background-repeat: no-repeat;
    background-position: -20px 20px;
    font-size: 14px;
    vertical-align: middle;
    cursor: pointer;
    background-image: url('../img/radio-hor.png');
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.survey-box.horizontal input[type=radio]:checked + label {
    background-position: 0 20px;
}

.survey-button {
    width: 250px;
}

i.avg-survey-icon {
    background-image: url('../img/icons/avg-survey-icon.svg');
    background-repeat: no-repeat;
    background-position: 0px 0px;
    background-size: 100% 100%;
    margin: 0px auto;
    width: 100%;
    height: 80px;
    display: block;
    margin-bottom: 20px;
}

input[type="submit"].survey-button {
    font-family: AVGSans;
    font-size: 18px;
    border: 0;
    border-radius: 5px;
    font-weight: normal;
    padding: 10px 0px;
}

.survey-box.vertical label {
    padding-left: 33px;
    height: 20px;
    display: inline-block;
    line-height: 20px;
    background-repeat: no-repeat;
    background-position: 0 0;
    vertical-align: middle;
    cursor: pointer;
    background-image: url('../img/radio.png');
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.survey-box.vertical input[type=radio]:checked + label {
    background-position: 0 -20px;
}

.survey-box.vertical tr {
    line-height: 2em;
}

.lt-ie9 .survey-box.horizontal input[type=radio] {
    display: block;
    margin: 0 15px 7px 15px;
}

.lt-ie9 .survey-box.horizontal label {
    padding-bottom: 0;
    background: 0;
    margin: 0 15px;
}

.lt-ie9 .survey-box.vertical input[type=radio] {
    display: inline;
}

.lt-ie9 .survey-box.vertical label {
    padding-left: 0;
    background: 0;
}

/* LANGUAGE SELECTOR */

#language-selector {
    cursor: pointer;
}

#lang-overlay {
    display: none;
    position: absolute;
    left: 50%;
    padding: 16px;
    border-radius: 5px;
    background-color: #ffffff;
    margin-left: -176px;
    margin-top: -176px;
    width: 320px;
    height: 370px;
    z-index: 99999;
}

body.ja #lang-overlay {
    height: 385px;
}

#fade {
    display: none;
    position: absolute;
    left: 0%;
    top: 0%;
    background-color: black;
    -moz-opacity: 0.7;
    opacity: .70;
    filter: alpha(opacity=70);
    width: 100%;
    height: 100%;
    z-index: 99998;
}

#lang-overlay-head {
    height: 36px;
    padding-left: 100px;
    background: url('../img/logo.png') top left no-repeat;
}

#lang-overlay-head h2 {
    font-weight: normal;
    font: 16px Verdana, sans-serif;
    color: #534f4f;
    line-height: 36px;
    margin: 0;
    padding: 0 0 0 10px;
    border-left: 1px solid black;
}

#lang-overlay h3 {
    padding: 20px 0 0 0;
}

#lang-overlay-head .close-button {
    font-style: normal;
    font-size: 15px;
    font-family: Arial, Baskerville, monospace;
    opacity: .65;
    cursor: pointer;
}

#lang-overlay-head .close-button:hover {
    opacity: 1;
}

#lang-overlay p {
    padding: 20px 0 0 0;
}

#lang-overlay ul {
    list-style-type: none;
    padding: 10px;
}

#lang-overlay ul li a, .avg-refund-phone span.flag {
    padding: 0 0 0 32px;
}

#lang-overlay ul li a.en_US, .avg-refund-phone span.flag.us {
    background: url('../img/icons/flags/us.png') top left no-repeat;
}

#lang-overlay ul li a.de {
    background: url('../img/icons/flags/de.gif') top left no-repeat;
}

#lang-overlay ul li a.cz {
    background: url('../img/icons/flags/cz.gif') top left no-repeat;
}

#lang-overlay ul li a.es {
    background: url('../img/icons/flags/es.gif') top left no-repeat;
}

#lang-overlay ul li a.fr {
    background: url('../img/icons/flags/fr.gif') top left no-repeat;
}

#lang-overlay ul li a.it {
    background: url('../img/icons/flags/it.gif') top left no-repeat;
}

#lang-overlay ul li a.nl {
    background: url('../img/icons/flags/nl.gif') top left no-repeat;
}

#lang-overlay ul li a.pl {
    background: url('../img/icons/flags/pl.gif') top left no-repeat;
}

#lang-overlay ul li a.br {
    background: url('../img/icons/flags/br.gif') top left no-repeat;
}

#lang-overlay ul li a.ja {
    background: url('../img/icons/flags/ja.png') top left no-repeat;
}

.avg-refund-phone span.flag.uk {
    background: url('../img/icons/flags/uk.gif') top left no-repeat;
}

.avg-refund-phone span.flag.aus {
    background: url('../img/icons/flags/aus.gif') top left no-repeat;
}

.asterisk {
    color: #E74A2B;
    vertical-align: top;
    font-size: 16px;
}

div.end-of-internet-cover {
    height: 300px;
    padding: 50px 0;
    background-color: #000000;
    background-image: url('../img/end-of-internet.jpg');
    background-repeat: no-repeat;
    background-position: center 0;
}

div.end-of-internet-cover h1 {
    margin-top: 30px;
}

div.end-of-internet-cover p {
    font-size: 18px;
    font-family: Verdana;
}

.three-boxes .box {
    width: 33.33%;
    float: left;
    box-sizing: border-box;
}

.three-boxes .box .inner {
    padding: 20px;
    border: 1px solid #dcdcdc;
    margin: 0 10px;
    height: 204px;
    text-align: center;
    box-sizing: border-box;
    background: #fff;
}

.three-boxes.nl_NL.not-found .box .inner h2 {
    font-size: 21px;
}

.three-boxes.pl.not-found .box .inner {
    height: 250px;
}

.three-boxes .box .inner p {
    margin-top: 0;
    height: 75px;
    padding-bottom: 10px;
}

.search-contact-box {
    padding-top: 0;
    margin: 10px 10px 25px 15px;

}

.search-contact-box a.prominent-link {
    margin-top: 15px;
}

.search-contact-box h2.result {
    color: #0072D1;
    font-family: AVGSans !important;
    font-size: 22px !important;

}


/* COPIED AND EDITED*/

#menu a {
    text-decoration: none;
    display: block
}

#menu ul {
    display: none;
    position: absolute;
    right: 0;
    list-style: none;
    z-index: 9999;
    min-width: 302px;
    max-width: 330px;
    text-align: left;
    border-left: 4px solid #fff;
    border-right: 4px solid #fff;
    border-bottom: 3px solid #fff;
    -webkit-box-shadow: 0 3px 5px 0 rgba(3, 3, 3, 0.2);
    box-shadow: 0 3px 5px 0 rgba(3, 3, 3, 0.2);
    background: #fff;
    background: -moz-linear-gradient(top, #fff 0, #f7f8f8 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff), color-stop(100%, #f7f8f8));
    background: -webkit-linear-gradient(top, #fff 0, #f7f8f8 100%);
    background: -o-linear-gradient(top, #fff 0, #f7f8f8 100%);
    background: -ms-linear-gradient(top, #fff 0, #f7f8f8 100%);
    background: linear-gradient(to bottom, #fff 0, #f7f8f8 100%)
}

#menu li ul {
    padding: 0;
}

.nav-main-link .menu-link {
    padding: 4px 13px 6px
}

.nav-main-link:hover .menu-link {
    background-color: #399e2d;
    color: #fff
}

#menu .nav-main-link:hover ul {
    display: block
}

#menu .nav-main-link.active ul {
    top: 26px
}

.nav-main-link {
    text-align: right;
    float: left;
    font: normal 18px/18px AVGSans_1, Verdana, sans-serif;
    margin-left: 10px;
    position: relative
}

.nav-main-link a {
    color: #000
}

.nav-main-link.selected {
    border-bottom: 5px solid #399e2d;
    overflow: visible
}

.nav-main-link.buy-now>a,
.design-2014 .nav-main-link.buy-now-img>a {
    background: #abb5ba;
    color: #fff;
    font: 16px/17px AVGSans_1, Verdana, sans-serif;
    padding: 4px 11px 6px 35px;
    margin: 0 4px 0 -6px;
    -webkit-border-radius: 13px;
    -moz-border-radius: 13px;
    border-radius: 13px
}

.nav-main-link.buy-now-img>a {
    text-align: left;
    text-indent: -9999px;
    padding: 0;
    width: 37px;
    height: 27px
}

#menu li li {
    display: block;
    text-align: left;
    line-height: 1em
}

#menu li li.nav-title,
.design-2014 #navigation #menu li li.nav-title:hover {
    font: bold 18px/18px AVGSans_4, Verdana, sans-serif;
    color: #5c707b;
    background: 0;
    cursor: default
}

#menu li li.nav-title {
    font: bold 18px/18px AVGSans_4, Verdana, sans-serif;
    color: #5c707b;
}

#menu li li.nav-title a {
    font: bold 14px/16px Verdana, sans-serif
}

#menu li li.separator {
    border-top: 5px solid #399E2D;
}

#menu li li a {
    border-top: 1px solid #ececec;
    padding: 11px 15px;
    color: #000;
    font: normal 13px/16px Verdana, sans-serif;
    text-decoration: none;
    display: block;
    cursor: pointer;
}

#menu li li span.no-link {
    border-top: 1px solid #ececec;
    padding: 11px 15px;
    color: #000;
    font: normal 13px/16px Verdana, sans-serif;
    text-decoration: none;
    display: block;
    cursor: auto;
    font-weight: bold;
}


#menu li li:first-child a {
    border-top: 0;
}

#menu li li:hover a {
    padding: 11px 15px 10px;
    background: rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid #d3d3d3;
    border-top: 1px solid #d3d3d3;
}

#menu li li:first-child:hover a {
    border-top: 0
}

#menu li:hover ul li:first-child {
    border-top: 5px solid #399e2d
}

#menu li {
    background: 0;
    padding: 0
}

#menu .nav-main-link li {
    margin: 0 -4px
}

#menu .nav-main-link li:last-child a {
    margin-bottom: -3px;
    padding-bottom: 14px
}

#menu .nav-main-link li:last-child:hover a {
    padding-bottom: 13px
}

a.menu-link > span {
    font-family: AVGSans;
    font-weight: 300;
}

.promo-label {
    padding: 0 4px;
    margin: 0 0 0 5px;
    text-transform: uppercase;
    font: normal 12px/16px AVGSans_4a, Verdana, sans-serif;
    color: #fff;
}

.promo-label.promo-new {
    background-color: #1869ad;
}

.promo-label.promo-free {
    background-color: #399e2d;
}

.fcb-login-button {
    background: url('../img/community/fcb.png') 00% 00% no-repeat;
    background-size: 100% 100%;
    height: 50px;
    width: 250px;
}


/* chat in contact form */

html.contact-form.chat div#chat-frame-wrapper {
    width: 62%;
    margin: 0 auto;
    padding: 40px 0 0 0;
}

html.contact-form.chat iframe#chatWindow,
html.contact-form.chat div#chatWindowPlaceholder {
    width: 100%;
    min-height: 525px;
    border: 0;
}

html.contact-form.chat div#chatWindowPlaceholder {
    background-color: white;
    background-image: url(../img/loader.gif);
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 5px;
}

html.contact-form.chat.sales .loading-bar {
    background-color: white;
    background-image: url("../img/loader.gif");
    background-position: center center;
    background-repeat: no-repeat;
    /*   border: 1px solid #d7d7d7;*/
    border-radius: 5px;
    display: block;
    min-height: 525px;
    width: 98%;
    margin: 0px auto;
    z-index: -1;
    top: -525px;
    position: relative;
}

html.contact-form.chat.sales #chatWindow {
    z-index: 1000;
}

html.contact-form.chat.sales div#chat-frame-wrapper div#chatWindowPlaceholder {
    border-radius: 5px;
}

html.contact-form.chat.sales div#chat-frame-wrapper div#chatWindowPlaceholder.hide-loading-bar {
    background-color: transparent !important;
    background-image: none !important;
}

html.contact-form.chat div#chatWindowNoAgent {
    background-color: white;
    width: 62%;
    margin: 0 auto 0px auto;
    height: 450px;
    position: relative;
    top: 40px;
    border: 1px solid #d7d7d7;
    border-radius: 5px;
    margin-bottom: 30px;
}

html.contact-form.chat div#chatWindowPlaceholder h2,
html.contact-form.chat div#chatWindowNoAgent h2 {
    text-align: center;
    position: relative;
    top: 180px;
}

html.contact-form.chat div#content {
    margin-top: -30px;
    background: url(../img/chat-bg.jpg) no-repeat top center;
}


/* headers */

.business-contact-country-lists {
    width: 50px;
}

.contact-form-captcha-container {
    padding: 20px 0px 10px 0px;
}

h1.homepage-headline {
    font-size: 44px;
    text-align: center;
    padding: 0px 0 0px 0;
    word-wrap: break-word;
    line-height: 0.9;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
}

.nl_NL h1.homepage-headline,
.ja h1.homepage-headline {
    font-size: 36px;
}

h2.homepage-subheadline,
h1.homepage-headline span.homepage-subheadline {
    text-align: center;
    font-weight: 300;
    color: #000000;
    padding: 0px;
    font-size: 22px;
    margin-bottom: -5px;
}

h1.homepage-headline span.homepage-subheadline.white-text {
    color: #ffffff !important;
}

h2.homepage-subheadline.gray-text {
    color: #5c707b;
}

h1.homepage-headline.white-text,
h2.homepage-subheadline.white-text {
    color: #ffffff;
}

.homepage input[type="button"].bussines-search-button:hover,
.homepage input[type="submit"].bussines-search-button:hover,
.homepage button.bussines-search-button:hover {
    background-color: #3aa9fe;
}

.bussines-search-button:hover {
    background-color: #3aa9fe;
}

.link-wrap.business-home {
    padding: 8px 0 0px;
}

.link-wrap span {
    color: #5c707b;
}

.link-wrap.white-text span,
.link-wrap.white-text a {
    color: #ffffff;
}

.link-wrap a:hover {
    text-decoration: underline;
}

.bottom-white-border {
    border-bottom: solid 1px #ffffff;
}

.contact-us-wrapper {
    width: 980px;
    padding: 0px 0px 0px 0px;
    text-align: left;
    height: 160px;
}

.contact-us-text {
    width: 510px;
    padding: 0px 20px 0 20px;
    color: #5c707b;
    display: inline-block;
    /* IE7 fix*/
    zoom: 1;
    *display: inline;
}

.contact-us-text-outer {
    display: table-cell;
    vertical-align: middle;
    height: 160px;
    margin-top: -10px;
}

.contact-us-text-inner {
    vertical-align: middle;
}

.contact-us-text h3 {
    color: black;
    font-weight: 300;
    font-family: AVGSans;
    font-size: 24px;
    padding-top: 0px;
}

.contact-us-wrapper .contact-us-text:hover h3,
.contact-us-wrapper .contact-us-logo:hover + .contact-us-text .contact-us-text-outer .contact-us-text-inner h3 {
    color: #0072D1;
}

.contact-us-text span, .contact-us-refund span {
    color: #0072D1;
}

.contact-us-wrapper .contact-us-text:hover span,
.contact-us-wrapper .contact-us-logo:hover + .contact-us-text .contact-us-text-outer .contact-us-text-inner span {
    text-decoration: underline;
}

.contact-us-not {
    width: 250px;
    padding: 0px 20px 0 20px;
    border-left: 2px solid #ffffff;
    color: #5c707b;
    display: inline-block;
    /* IE7 fix*/
    zoom: 1;
    *display: inline;
}

.contact-us-not-outer {
    display: table-cell;
    vertical-align: middle;
    height: 160px;
    margin-top: -10px;
}

.contact-us-not-inner {
    vertical-align: middle;
}

.contact-us-not h3 {
    color: black;
    font-weight: 300;
    font-family: AVGSans;
    font-size: 24px;
    padding-top: 0px;
}

.contact-us-wrapper .contact-us-not:hover h3 {
    color: #0072D1;
}

.contact-us-not span {
    color: #0072D1;
}

.contact-us-wrapper .contact-us-not:hover span {
    text-decoration: underline;
}

.contact-us-logo {
    width: 120px;
    height: 160px;
    background: url('../img/lifestyle/img-contact.png') no-repeat center center;
    display: inline-block;
    /* IE7 fix*/
    zoom: 1;
    *display: inline;
}

 /*Override contact-us classes  -> 3 columns on bussiness page*/
@media screen and (min-width: 981px) {
    .contact-us-3-col.contact-us-wrapper {
        height: 230px;
    }

    .contact-us-3-col .contact-us-logo {
        height: 230px;
        width: 100px;
        background-size: 100px;
    }

    .contact-us-3-col .contact-us-text {
        box-sizing: border-box;
        width: 316px;
    }

    .contact-us-3-col .contact-us-text-outer {
        height: 230px;
    }

    .contact-us-3-col .contact-us-not {
        width: 226px;
        box-sizing: border-box;
    }

    .contact-us-3-col .contact-us-not-outer {
        height: 230px;
    }
}

    .contact-us-refund {
        width: 316px;
        padding: 0px 20px 0 20px;
        color: #5c707b;
        display: inline-block;
        /* IE7 fix*/
        zoom: 1;
        *display: inline;
        border-left: 2px solid white;
        box-sizing: border-box;
    }

    .contact-us-refund-outer {
        display: table-cell;
        vertical-align: middle;
        height: 230px;
        margin-top: -10px;
    }

    .contact-us-refund-inner {
        vertical-align: middle;
    }

    .contact-us-refund h3 {
        color: black;
        font-weight: 300;
        font-family: AVGSans;
        font-size: 24px;
        padding-top: 0px;
    }

    .contact-us-refund:hover h3 {
        color: #0072D1;
    }

    .contact-us-refund:hover span {
        text-decoration: underline;
    }

@media (max-width: 980px){
    .contact-us-refund{
        padding-top: 0;
        border-left: 0;
        border-top: 2px solid #fff;
        padding-left: 0;
        padding-right: 0;
        margin-top: 10px;
        width: 100%;
    }
    .contact-us-refund-outer {
        margin-top: 10px;
        margin-bottom: 10px;
        display: block;
        height: auto;
        width: 100%;
    }
    .contact-us-refund-inner {
        margin-top: 0;
        width: 80%;
        margin: 0 auto;
        text-align: center;
    }
    .contact-us-refund h3 {
        color: #0072D1;
    }
}
 /************************************************************/

html.lte-ie7 .contact-us-logo {
    /* IE7 fix*/
    float: none;
}

html.lte-ie7 .contact-us-wrapper a div div.contact-us-text-outer div.contact-us-text-inner,
html.lte-ie7 .contact-us-wrapper a div div.contact-us-not-outer div.contact-us-not-inner {
    /* IE7 fix*/
    padding-top: 40px;
}

.premium-support-wrapper {
    width: 940px;
    padding: 15px 0px 15px 0px;
    text-align: left;
    margin: 0px 0px 0px 0px;
}

.premium-support-text {
    width: 480px;
    padding: 0px 0px 0 0px;
    color: #5c707b;
    display: inline-block;
    /* IE7 fix*/
    zoom: 1;
    *display: inline;
}

.premium-support-text-outer {
    vertical-align: middle;
    height: 175px;
    display: table-cell;
}

.premium-support-text-inner {
    vertical-align: middle;
}

.premium-support-text span {
    color: #0072D1;
}

.premium-support-text h3 {
    color: black;
    padding-bottom: 10px;
    padding-top: 0px;
    font-weight: 300;
    font-size: 24px;
    font-family: AVGSans;
    line-height: 26px
}

.premium-support-note{
    font-size: 13px;
    color:#5c707b;
}

.premium-support-wrapper:hover .premium-support-text h3 {
    color: #0072D1;
}

.premium-support-wrapper:hover .premium-support-text a,
.premium-support-wrapper:hover .premium-support-text span {
    text-decoration: underline;
}

.premium-support-logo {
    width: 460px;
    height: 185px;
    background: url('../img/lifestyle/consumer-support-avg-go.png') no-repeat center center;
    display: inline-block;
    /* IE7 fix*/
    zoom: 1;
    *display: inline;
}

html.lte-ie7 .premium-support-logo {
    /* IE7 fix*/
    float: none;
}

html.lte-ie7 .premium-support-wrapper a div div.premium-support-text-outer div.premium-support-text-inner {
    /* IE7 fix*/
    padding-top: 30px;
}


/* PADDINGS */

.padding-top-20 {
    padding-top: 20px;
}

.padding-bottom-50 {
    padding-bottom: 50px;
}

.padding-bottom-60 {
    padding-bottom: 60px;
}

.padding-bottom-65 {
    padding-bottom: 65px;
}

.padding-bottom-20 {
    padding-bottom: 20px;
}

.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-bottom-40 {
    margin-bottom: 40px !important;
}




/* BADGES */

.badge.consumer-supp h2 .image {
    background-image: url("../img/icons/choose_support_sprite.png");
    background-position: 0 0;
    background-repeat: no-repeat;
    display: block;
    height: 60px;
    width: 60px;
    margin: 0px auto 18px;
}

.badge.consumer-supp:hover h2 .image {
    background-position: -80px 0;
}

.badge.partner-supp h2 .image {
    background-image: url("../img/icons/choose_support_sprite.png");
    background-position: 0 -80px;
    background-repeat: no-repeat;
    display: block;
    height: 60px;
    width: 60px;
    margin: 0px auto 18px;
}

.badge.partner-supp:hover h2 .image {
    background-position: -80px -80px;
}

.badge.business-supp h2 .image {
    background-image: url("../img/icons/choose_support_sprite.png");
    background-position: 0 -160px;
    background-repeat: no-repeat;
    display: block;
    height: 60px;
    width: 60px;
    margin: 0px auto 18px;
}

.badge.business-supp:hover h2 .image {
    background-position: -80px -160px;
}

.badge.cloud-care h2 .image {
    background-image: url("../img/icons/partners-homepage-sprite.png");
    background-position: 0 0;
    background-repeat: no-repeat;
    display: block;
    height: 60px;
    width: 60px;
    margin: 0px auto 0px;
}

.badge.cloud-care:hover h2 .image {
    background-position: -80px 0;
}

.badge.managed-workplace h2 .image {
    background-image: url("../img/icons/partners-homepage-sprite.png");
    background-position: 0 -80px;
    background-repeat: no-repeat;
    display: block;
    height: 60px;
    width: 60px;
    margin: 0px auto 0px;
}

.badge.managed-workplace:hover h2 .image {
    background-position: -80px -80px;
}

.badge.single-sing-on h2 .image {
    background-image: url("../img/icons/partners-homepage-sprite.png");
    background-position: 0 -160px;
    background-repeat: no-repeat;
    display: block;
    height: 60px;
    width: 60px;
    margin: 0px auto 5px;
}

.badge.single-sing-on:hover h2 .image {
    background-position: -80px -160px;
}

.badge.home-multi-device h2 .image {
    background-image: url("../img/icons/home-homepage-sprite.png");
    background-position: 0 0;
    background-repeat: no-repeat;
    display: block;
    height: 60px;
    width: 60px;
    margin: 0px auto 13px;
}

.badge.home-multi-device:hover h2 .image {
    background-position: -80px 0;
}

.badge.home-win h2 .image {
    background-image: url("../img/icons/home-homepage-sprite.png");
    background-position: 0 -80px;
    background-repeat: no-repeat;
    display: block;
    height: 60px;
    width: 60px;
    margin: 0px auto 13px;
}

.badge.home-win:hover h2 .image {
    background-position: -80px -80px;
}

.badge.home-android h2 .image {
    background-image: url("../img/icons/home-homepage-sprite.png");
    background-position: 0 -160px;
    background-repeat: no-repeat;
    display: block;
    height: 60px;
    width: 60px;
    margin: 0px auto 13px;
}

.badge.home-android:hover h2 .image {
    background-position: -80px -160px;
}

.badge.home-mac h2 .image {
    background-image: url("../img/icons/home-homepage-sprite.png");
    background-position: 0 -240px;
    background-repeat: no-repeat;
    display: block;
    height: 60px;
    width: 60px;
    margin: 0px auto 13px;
}

.badge.home-mac:hover h2 .image {
    background-position: -80px -240px;
}

.badge.home-pts h2 .image {
    background-image: url("../img/icons/ptsweb60.png");
    display: block;
    height: 60px;
    width: 60px;
    margin: 0px auto 13px;
}

.badge.home-pts:hover h2 .image {
    background-image: url("../img/icons/ptsweb60_hover.png");
}

.badge.antivirus-be-products {
    background: url('../img/icons/products/business/av-business-medium.png') no-repeat top center;
}

.badge.is-products {
    background: url('../img/icons/products/business/is-business-medium.png') no-repeat top center;
}

.badge.fs-products {
    background: url('../img/icons/products/business/file-server-medium.png') no-repeat top center;
}

.badge.tu-be-products {
    background: url('../img/icons/products/business/pctu-business-medium.png') no-repeat top center;
}

.badge.home-multi-device h2,
.badge.home-win h2,
.badge.home-android h2,
.badge.home-mac h2,
.badge.home-pts h2 {
    font-size: 18px;
    height: 94px;
    margin-top: 9px;
    padding-top: 0px;
}

.badge.home-multi-device.de h2,
.badge.home-win.de h2,
.badge.home-android.de h2,
.badge.home-mac.de h2,
.badge.home-pts.de h2,
.badge.home-multi-device.cs h2,
.badge.home-win.cs h2,
.badge.home-android.cs h2,
.badge.home-mac.cs h2,
.badge.home-pts.cs h2,
.badge.home-multi-device.fr h2,
.badge.home-win.fr h2,
.badge.home-android.fr h2,
.badge.home-mac.fr h2,
.badge.home-pts.fr h2,
.badge.home-multi-device.nl_NL h2,
.badge.home-win.nl_NL h2,
.badge.home-android.nl_NL h2,
.badge.home-mac.nl_NL h2,
.badge.home-pts.nl_NL h2,
.badge.home-multi-device.es h2,
.badge.home-win.es h2,
.badge.home-android.es h2,
.badge.home-mac.es h2,
.badge.home-pts.es h2
{
    font-size: 18px;
    height: 104px;
    margin-top: 9px;
    padding-top: 0px;
}

.badge.home-multi-device.pt_BR h2,
.badge.home-win.pt_BR h2,
.badge.home-android.pt_BR h2,
.badge.home-mac.pt_BR h2,
.badge.home-pts.pt_BR h2 {
    font-size: 16px;
    height: 104px;
    margin-top: 9px;
    padding-top: 0px;
}

.badge.home-multi-device.it h2,
.badge.home-win.it h2,
.badge.home-android.it h2,
.badge.home-mac.it h2,
.badge.home-pts.it h2 {
    font-size: 17px;
    height: 104px;
    margin-top: 9px;
    padding-top: 0px;
}

.badge.home-multi-device.en_US h2,
.badge.home-win.en_US h2,
.badge.home-android.en_US h2,
.badge.home-mac.en_US h2,
.badge.home-pts.en_US h2 {
    font-size: 18px;
    height: 84px;
    margin-top: 9px;
    padding-top: 0px;
}

.badge.home-multi-device.pl h2,
.badge.home-win.pl h2,
.badge.home-android.pl h2,
.badge.home-mac.pl h2,
.badge.home-pts.pl h2 {
    font-size: 18px;
    padding-top: 0px;
    height: 119px;
    margin-top: 9px;
}

.badge.antivirus-be-products h2,
.badge.is-products h2,
.badge.fs-products h2,
.badge.tu-be-products h2 {
    font-size: 18px;
    padding-top: 62px;
    height: 32px;
}

.badge.antivirus-be-products.ja h2,
.badge.is-products.ja h2,
.badge.fs-products.ja h2,
.badge.tu-be-products.ja h2 {
    font-size: 13px;
}

.badge.consumer-supp h2,
.badge.partner-supp h2,
.badge.business-supp h2 {
    font-size: 24px;
    padding-top: 0px;
    height: 94px;
}

.badge.consumer-supp.cs h2,
.badge.partner-supp.cs h2,
.badge.business-supp.cs h2,
.badge.consumer-supp.de h2,
.badge.partner-supp.de h2,
.badge.business-supp.de h2,
.badge.consumer-supp.es h2,
.badge.partner-supp.es h2,
.badge.business-supp.es h2,
.badge.consumer-supp.fr h2,
.badge.partner-supp.fr h2,
.badge.business-supp.fr h2,
.badge.consumer-supp.fr h2,
.badge.partner-supp.fr h2,
.badge.business-supp.fr h2,
.badge.consumer-supp.it h2,
.badge.partner-supp.it h2,
.badge.business-supp.it h2,
.badge.consumer-supp.nl_NL h2,
.badge.partner-supp.nl_NL h2,
.badge.business-supp.nl_NL h2,
.badge.consumer-supp.pl h2,
.badge.partner-supp.pl h2,
.badge.business-supp.pl h2,
.badge.consumer-supp.pt_BR h2,
.badge.partner-supp.pt_BR h2,
.badge.business-supp.pt_BR h2 {
    font-size: 24px;
    padding-top: 0px;
    height: 118px;
}

.badge.consumer-supp.ja h2,
.badge.partner-supp.ja h2,
.badge.business-supp.ja h2 {
    font-size: 22px;
    padding-top: 0px;
    height: 94px;
}

.badge.cloud-care h2,
.badge.managed-workplace h2,
.badge.single-sing-on h2 {
    font-size: 18px;
    padding-top: 0px;
    height: 76px;
}

.badge.cloud-care:hover h2,
.badge.managed-workplace:hover h2,
.badge.single-sing-on:hover h2,
.badge.antivirus-be-products:hover h2,
.badge.is-products:hover h2,
.badge.fs-products:hover h2,
.badge.tu-be-products:hover h2,
.badge.consumer-supp:hover h2,
.badge.partner-supp:hover h2,
.badge.business-supp:hover h2 {
    color: #0072D1;
    text-decoration: underline;
}

.badge:hover .badge-underlined{
    display:inline;
    border-bottom:1px solid #0072D1;
    height:18px;
    color:#0072D1;
}

ul.business-underlined {
    border-top: solid 1px #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
    padding: 10px 0px 10px 0px;
    margin-top: 10px;
    padding-left: 13px;
}

ul.business-underlined li {
    padding-bottom: 2px;
    padding-top: 2px;
    list-style: none;
}

ul.business-underlined li:before {
    content: '\2022';
    display: block;
    position: relative;
    max-width: 0;
    max-height: 0;
    left: -15px;
    top: -4px;
    color: #546a75;
    font-size: 20px;
}

ul.business-underlined li.only-three-li-items {
    padding-bottom: 25px;
}

ul.business-underlined li.last-item {
    margin-top: 10px;
    padding-top: 9px;
    border-top: solid 1px #dcdcdc;
    list-style-type: none;
    margin-right: 0px;
    margin-left: -13px;
    padding-left: 13px;
}

ul.business-underlined li.last-item:before {
    content: none;
    display: block;
    position: relative;
    max-width: 0;
    max-height: 0;
    left: 0px;
    top: 0px;
    color: #546a75;
    font-size: 20px;
}

ul.business-underlined li a:hover {
    text-decoration: none;
    border-bottom: 1px #0072D1 solid;
    display:inline;
}

ul.business-underlined li a {
    font-weight: 300;
}

.business-underlined-last-item {
    padding-top: 9px;
    margin-right: 0px;
    padding-left: 13px;
    display: block;
}

.business-underlined-last-item {
    font-weight: 300;
}

.business-underlined-last-item:hover {
    text-decoration: underline;
}

.third-box.business ul li a:hover {
    text-decoration: underline;
}


/* CONTACT FORM */

dl.business {
    margin: 0px 0px 0px 0px;
    padding: 5px 0px 5px 0px;
    display: block;
    width: 640px;
}

dl.business dt {
    display: inline-block;
    vertical-align: middle;
    margin: 0px 0px 5px 0px;
    text-align: left;
    width: 193px;
}

dl.business dt.wide {
    width: 640px;
}

dl.business dt .dif-text.outer {
    display: table-cell;
    width: 100%;
    height: 60px;
    vertical-align: middle;
}

dl.business dt .dif-text.inner {
    vertical-align: middle;
}

dl.business dd {
    display: inline-block;
    vertical-align: top;
    word-wrap: break-word;
    margin: 0px 0px 5px 0px;
    text-align: left;
    width: 443px;
}

dl.business dd:last-child {
    margin-bottom: 0px;
}

dl.business dd a:hover {
    text-decoration: underline;
}

dl.business textarea,
dl.business input[type="text"] {
    width: 390px;
}

dl.business select {
    width: 414px;
}

dl.business .channel-text {
    width: 270px;
}

dl.business .contact-radio-box {
    margin-bottom: 0px;
}

.business-initial-button {
    margin-top: -30px;
}

dl.email {
    margin: 0px 0px 0px 0px;
    padding: 5px 0px 5px 0px;
    display: block;
    width: 443px;
}

dl.email dt {
    display: block;
    vertical-align: top;
    word-wrap: break-word;
    margin: 0px 0px 0px 0px;
    text-align: left;
    width: 100%;
    line-height: 15px;
    height: auto;
}

dl.email dd {
    display: block;
    vertical-align: top;
    word-wrap: break-word;
    margin: 0px 0px 5px 0px;
    text-align: left;
    width: 100%;
}

dl.email dd:last-child {
    margin-bottom: 0px;
}

dl.email input[type="text"] {
    width: 390px;
}

dl.email textarea {
    width: 421px;
}

dl.business-phone {
    margin: 0px 0px 0px 0px;
    padding: 5px 0px 5px 0px;
    display: block;
}

dl.business-phone dt {
    display: inline-block;
    word-wrap: break-word;
    text-align: left;
    width: 25%;
}

dl.business-phone dd {
    display: inline-block;
    vertical-align: top;
    word-wrap: break-word;
    text-align: left;
    width: 70%;
}

dl.business-phone dt,
dl.business-phone dd {
    margin: 0px 0px 10px 0px;
}

.sales-chat-wrapper {
    padding: 0px;
    width: 100%;
    margin: 0px auto !important;
    border: 1px solid #d7d7d7 !important;
    border-radius: 5px;
    min-height: 525px;
    background: white;
}

.support-chat-inner-wrapper {
    width: 86%;
    margin: 0px auto;
}

.support-sales-chat-header {
    background: #f1f1f1;
    width: auto;
    padding: 10px 15px;
    height: 40px !important;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.support-sales-chat-header h2 {
    font-family: AVGSans;
    font-size: 18px;
    font-weight: 500;
    margin: 2px 0;
    top: 5px !important;
    text-align: left !important;
    padding: 0 !important;
}

dl.chat-login {
    margin: 0px auto;
    padding: 5px 0px 5px 0px;
    display: block;
    width: 100%;
}

dl.chat-login {
    padding-bottom: 80px;
}

dl.chat-login {
    padding-bottom: 0px;
}

dl.chat-login dt {
    display: inline-block;
    vertical-align: middle;
    margin: 0px 0px 5px 0px;
    text-align: left;
    width: 20%;
    /* IE7 fix*/
    zoom: 1;
    *display: inline;
}

dl.chat-login dt .dif-text.outer {
    display: table-cell;
    width: 100%;
    height: 60px;
    vertical-align: middle;
}

dl.chat-login dt .dif-text.inner {
    vertical-align: middle;
}

dl.chat-login dd {
    display: inline-block;
    vertical-align: top;
    word-wrap: break-word;
    margin: 0px 0px -5px 0px;
    text-align: left;
    width: 75%;
    float: right;
}

dl.chat-login dd input {
    width: 50%;
}

dl.chat-login dd a {
    margin: 0px 0px 15px 0px;
    display: block;
}

dl.chat-login dd:last-child {
    margin-bottom: 0px;
}

.radio-like-business {
    width: 24px;
    height: 24px;
    margin: 6px 15px 0 0;
    background-image: url('../img/icons/input-radio-sprite.png');
    background-repeat: no-repeat;
    background-position: 0 0;
}

.business-chat .inner.chat .channel-icon-business {
    background-image: url('../img/icons/contact-channel-sprite.png');
    height: 60px;
    width: 60px;
    margin-right: 15px;
    background-position: 0px 0px;
}

    .contact-radio-box button.chat:hover span.channel-icon-business,
.contact-radio-box button.chat:focus span.channel-icon-business,
.contact-radio-box button.chat.selected span.channel-icon-business,
.business-chat:hover .inner.chat .channel-icon-business,
.business-chat:focus .inner.chat .channel-icon-business,
.business-chat input[type=radio]:checked ~ .inner.chat .channel-icon-business {
    background-position: -80px 0px;
}

.business-chat:hover .radio-like-business,
.business-chat:focus .radio-like-business,
.business-chat input[type=radio]:checked ~ .radio-like-business {
    background-position: -44px 0px;
}

.business-chat:hover .inner.chat .channel-text h3,
.business-chat:focus .inner.chat .channel-text h3,
.business-chat input[type=radio]:checked ~ .inner.chat .channel-text h3 {
    color: #0072D1;
}

.business-phone .inner.phone .channel-icon-business {
    background-image: url('../img/icons/contact-channel-sprite.png');
    height: 60px;
    width: 60px;
    margin-right: 15px;
    background-position: 0px -80px;
}

.contact-radio-box button.phone:hover span.channel-icon-business,
.contact-radio-box button.phone:focus span.channel-icon-business,
.contact-radio-box button.phone.selected span.channel-icon-business,
.business-phone:hover .inner.phone .channel-icon-business,
.business-phone:focus .inner.phone .channel-icon-business,
.business-phone input[type=radio]:checked ~ .inner.phone .channel-icon-business {
    background-position: -80px -80px;
}

.business-phone:hover .radio-like-business,
.business-phone:focus .radio-like-business,
.business-phone input[type=radio]:checked ~ .radio-like-business {
    background-position: -44px 0px;
}

.business-phone:hover .inner.phone .channel-text h3,
.business-phone:focus .inner.phone .channel-text h3,
.business-phone input[type=radio]:checked ~ .inner.phone .channel-text h3 {
    color: #0072D1;
}

.business-email .inner.email .channel-icon-business {
    background-image: url('../img/icons/contact-channel-sprite.png');
    height: 40px;
    width: 60px;
    margin-right: 15px;
    background-position: 0px -160px;
}

.contact-radio-box button.email:hover span.channel-icon-business,
.contact-radio-box button.email:focus span.channel-icon-business,
.contact-radio-box button.email.selected span.channel-icon-business,
.business-email:hover .inner.email .channel-icon-business,
.business-email:focus .inner.email .channel-icon-business,
.business-email input[type=radio]:checked ~ .inner.email .channel-icon-business {
    background-position: -80px -160px;
}

.business-email:hover .radio-like-business,
.business-email:focus .radio-like-business,
.business-email input[type=radio]:checked ~ .radio-like-business {
    background-position: -44px 0px;
}

.business-email:hover .inner.email .channel-text h3,
.business-email:focus .inner.email .channel-text h3,
.business-email input[type=radio]:checked ~ .inner.email .channel-text h3 {
    color: #0072D1;
}

input.cform.business[type="button"]:hover,
input.cform.business[type="submit"]:hover,
a.button.cform.business:hover {
    background-color: #3aa9fe;
}

dl.consumer {
    margin: 0px 0px 0px 0px;
    padding: 20px 0px 0px 0px;
    display: block;
    width: 640px;
}

dl.consumer dt {
    display: inline-block;
    vertical-align: middle;
    margin: 0px 0px 5px 0px;
    text-align: left;
    width: 0px;
}

dl.consumer dt .dif-text.outer {
    display: table-cell;
    width: 100%;
    height: 60px;
    vertical-align: middle;
}

dl.consumer dt .dif-text.inner {
    vertical-align: middle;
}

dl.consumer dd {
    display: inline-block;
    vertical-align: top;
    word-wrap: break-word;
    margin: 0px 0px 5px 0px;
    text-align: left;
    width: 640px;
}

dl.consumer dd:last-child {
    margin-bottom: 0px;
}

dl.consumer dd a:hover {
    text-decoration: underline;
}

dl.consumer textarea,
dl.consumer input[type="text"] {
    width: 390px;
}

dl.consumer select {
    width: 414px;
}

dl.consumer .channel-text {
    width: 520px;
}

.business-article-selector-clean-place {
    height: 90px;
}

#business-article-selector {
    position: absolute;
    z-index: 1000;
    margin: 10px 10px 10px 15px;
    padding: 10px 0px 10px 0px;
    border: 1px solid #0072D1;
    background: transparent;
    width: 290px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    background-image: url("../img/icons/collapse-arrow.png");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-position-x: 260px;
    background-position-y: center;
    cursor: pointer;
}

#business-article-selector .shown-item {
    padding: 0px 13px 0px 13px;
    display: block;
    color: #0072D1;
    font-family: Verdana;
    font-size: 14px;
}

#business-article-selector.highlight {
    padding-top: 0px;
    padding-bottom: 0px;
    background: white;
    background-image: none;
}

#business-article-selector.highlight .shown-item {
    display: none;
}

#business-article-selector ul {
    display: none;
    list-style-type: none;
    padding: 8px;
}

#business-article-selector.highlight ul {
    display: block;
}

#business-article-selector.highlight ul li.firstchild span {
    color: #0072D1;
}

#business-article-selector.highlight ul li {
    padding: 0px;
    width: 100%;
}

#business-article-selector.highlight ul li span {
    color: #5c707b;
    padding: 8px 5px 8px 5px;
    width: 270px;
    display: block;
    font-family: Verdana;
}

#business-article-selector.highlight ul li:hover {
    background: #f5f5f5;
}

.button-business {
    display: block;
    background: #0072D1;
    color: #ffffff !important;
    text-align: center;
    border-radius: 5px;
    padding: 21px 0;
    font-weight: normal !important;
    font-size: 16px;
    width: 300px;
    position: absolute;
    bottom: 20px;
    left: 0px;
}

.button-business:hover {
    background-color: #3aa9fe;
}

.button-business-invert {
    display: block;
    background: #fafafa;
    color: #0072D1 !important;
    text-align: center;
    border-radius: 5px;
    padding: 20px 0;
    border: 1px solid #0072D1;
    font-weight: normal !important;
    font-size: 16px;
    width: 300px;
    position: absolute;
    bottom: 20px;
    right: 0px;
}

.button-business-invert:hover {
    border-color: #3aa9fe;
    background-color: #3aa9fe;
    color: white !important;
}

.button-business-invert.small-padding {
       padding: 10px 0;
 }

.content-nav-business {
    padding-bottom: 40px !important;
}

.content-nav-business .prominent-link:hover,
.content-nav .prominent-link:hover {
    border: 1px solid transparent;
}

.line-result {
    padding: 10px 15px 10px 15px;
    margin-bottom: 10px;
    cursor: pointer;
}

.line-result:hover {
    background-color: #f5f5f5;
}

.line-result .dif-text {
    padding-top: 5px;
    display: block;
}

.line-result h2.result {
    line-height: none;
    color: #0072D1;
    padding-bottom: 0px;
}

#product-filter-selector {
    font-family: AVGSans;
    font-size: 16px;
    text-align: center;
    color: #000000;
    margin-bottom: 5px;
}

#product-filter-selector span{
    cursor: auto;
    color: #000000;
    border-bottom: none;
    display: inline-block;
    margin-top: 3px;
}

#product-filter-selector span span{
    cursor: pointer;
    color: #0072D1;
    border-bottom: 1px dotted #0072D1;
    display: inline-block;
    margin-top: 3px;
}

#product-filter-selector span.selected span{
    border-bottom: 1px dotted transparent;
    color: #000000;
}

.result-box {
    min-height: 300px;
    position: relative;
    padding-bottom: 100px;
}

#result-box-overlay-loading,
#result-box-community-overlay-loading,
#contact-form-overlay {
    background-color: rgba(255, 255, 255, 0.7);
    background-image: url("../img/loader-transparent.gif");
    background-position: center top 100px;
    background-repeat: no-repeat;
    position: absolute;
    width: 640px;
    z-index: 2000;
    background-position-x: 50%;
    background-position-y: 100px;
    min-height: 300px;
    margin: -10px 0;
}

#contact-form-overlay {
    width: 100%;
    padding-bottom: 10px;
    height: 100%;
    background-position: center center;
}

.to-be-overlayed {
    position: relative;
}

#result-box-community-overlay-loading {
    margin: 0px;
}

#result-box-overlay-loading.hidden,
#result-box-community-overlay-loading.hidden {
    display: none;
}

h2.results-not-found {
    line-height: 1.2em;
    margin-left: 15px;
    margin-bottom: 50px;
}

.line-result:hover h2.result {
    text-decoration: underline;
}

.result-headline {
    margin-left: 15px !important;
}

.business-phone-list {
    list-style-type: none;
}

.business-phone-list {
    list-style-type: none;
    padding: 10px 10px 0px 10px;
}

#mailFormBox.hidden {
    display: none;
}

#alertChannelNotBeingChecked {
    margin: 0px auto 20px auto;
}

#alertChannelNotBeingChecked.hidden {
    display: none;
}

.third-box.business-community ul {
    padding-bottom: 0px;
}

.third-box.business-community ul li:last-child {
    padding-bottom: 0px;
}

.third-box.business-community.picture {
    display: block;
    width: 312px;
    border: none;
    padding: 45px 0px;
    border-bottom: 0;
    border-top: 0;
    background: url('../img/lifestyle/consumer-support-avg-support-community.png') no-repeat top 110px center;
    font-family: AVGSans;
    color: black;
    min-height: 150px;
}

.third-box.business-community.picture.pt_BR,
.third-box.business-community.picture.es,
.third-box.business-community.picture.fr {
    background: url('../img/lifestyle/consumer-support-avg-support-community.png') no-repeat top 140px center;
    min-height: 190px;
}

.third-box.business-community.picture h3 {
    padding-top: 0px;
    color: #5c707b;
}

.third-box.business-community.picture:hover h3 {
    text-decoration: underline;
}

.third-box.business-community.first {
    padding: 40px 0px 35px 80px;
    width: 390px;
}

.third-box.business-community.quickLinkWrapper {
    padding-left: 0px !important;
    width: inherit !important;
	list-style-position: inside;
    text-align-last: center !important;
    padding-left: 0px !important;
}

.third-box.business-community.last {
    width: 310px;
    padding: 40px 0px 35px 90px;
}

.third-box.business-community.with-center-part.first {
    width: 312px;
    padding: 45px 0px 35px 0px;
}

.third-box.business-community.with-center-part.last {
    width: 312px;
    padding: 45px 0px 35px 0px;
}

.third-box.business-community.last h2,
.third-box.business-community.first h2 {
    text-align: left;
    padding-left: 7px;
}

.third-box.business-community ul li a:hover {
    text-decoration: underline;
}

.wrap-avg-go-side-banner {
    clear: both;
    overflow: hidden;

}

.wrap-avg-go-side-banner .article-side-box-banner {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
}

.article-side-box-banner {
    border: 1px solid #dcdcdc;
    margin-top: 6px;
    margin-bottom: 6px;
    padding: 0px 0px 0px 0px;
    width: 220px;
    cursor: auto;
    text-align: center;
    background-color: white;
}

.article-side-box-banner h3 {
    font-family: AVGSans;
    font-weight: 300;
    text-align: center;
    font-size: 30px;
    color: black;
    padding-top: 0;
    margin-top: 0;

}

.article-side-box-banner h4 {
    font-family: AVGSans;
    text-transform: uppercase;
    text-align: center;
    font-size: 20px;
    font-weight: 300px;
    color: #3f3f3f;
    margin-top: 15px;
    padding-bottom: 0;
    padding-top: 0;
}

.article-side-box-banner .logo {
    background-image: url('./../img/avg-go/avg-go-logo.png');
    background-repeat: no-repeat;
    width: 80px;
    height: 40px;
    display: block !important;
    color: black;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
}

.article-side-box-banner .clock {
    background-image: url('./../img/avg-go/avg-go-clock.png');
    background-repeat: no-repeat;
    width: 65px;
    height: 59px;
    margin: 0px auto;
    display: block !important;
    color: black;
    margin-top: 0px;
    margin-bottom: 0px;
}

.article-side-box-banner a.number {
    color: #ff4e00;
    text-align: center;
    margin: 0px auto;
    margin: 0 0 0px 0;
    padding: 0;
    font-size: 22px;
    display: inline-block;
    font-family: AVGSans;
    font-weight: 500;
    height: 23px;
    text-decoration: none;
}

.article-side-box-banner a.number:hover {
    text-decoration: none;
}

.article-side-box-banner a.number span {
    color: #5a707b;
    font-size: 14px;
    vertical-align: middle;
}

.article-side-box-banner span.text {
    display: block;
    color: #5a707b;
    text-align: center;
    margin: 0px auto;
    margin: 4px 0 0px 0;
    padding: 0;
    font-size: 11px;
}

.article-side-box-banner span.text a {
    color: #ff4e00;
    text-decoration: underline;
    display: inline;
}

.article-side-box-banner .devices {
    background-image: url('./../img/avg-go/avg-go-devices.png');
    background-repeat: no-repeat;
    background-position: center;
    width: 220px;
    height: 200px;
    margin: 0px auto;
    margin-top: 20px;
    display: block !important;
}

.article-side-box-banner .get-help {
    background-image: url('./../img/avg-go/avg-go-get-help.png');
    background-repeat: no-repeat;
    background-position: center;
    width: 200px;
    height: 30px;
    margin: 0px auto;
    display: block !important;
    margin-top: 10px;
    margin-bottom: 20px;
}

.wrapper.contact {
    margin: 0px auto;
    padding: 0 100px;
    width: 780px;
}

.switcher-wrapper {
    width: 100%;
    border-bottom: 1px solid #dcdcdc;
    height: 47px;
    margin: 25px auto 10px auto;
    display: block;
    text-align: center;
}

.support-switcher {
    height: 48px;
    line-height: 50px;
    text-align: center;
    background: white;
    cursor: pointer;
    color: #0072D1;
    border: 1px solid #dcdcdc;
    border-top: 3px solid #e5e5e5;
    margin: 0px 5px 0px 5px;
    font-family: AVGSans;
    font-size: 17px;
    min-width: 220px;
    padding: 0 3px;
    display: inline-block;
}

.switcher-wrapper .support-switcher {
    min-width: 150px;
}

.switcher-wrapper button {
    margin-left: 20px;
}

.switcher-wrapper button:first-child {
    margin-left: 0px;
}

.wrapper.contact #chat-box {
    text-align: center;
}

.support-switcher.selected {
    color: black;
    border-bottom: 1px solid transparent;
    background: #fafafa;
}

.support-switcher.selected:hover {
    border-top: 3px solid #e5e5e5;
}

.support-switcher:hover {
    border-top: 3px solid #0072D1;
}

.form-label {
    color: #5d717c;
    font-family: Verdana !important;
}

ul.contact {
    list-style: none;
    padding: 0px;
}

ul.contact li {
    padding: 0px;
}

/* SideBannerSlider */
div[id*="avgSupportBannerSlider"] .slide-out-div {
    padding: 10px;
    text-align: center;
    width: 325px;
    height: 195px;
    background: #fff;
    box-shadow: 1px 1px 5px #888;
    z-index: 9999;
    height: 235px !important;
}

div[id*="avgSupportBannerSlider"] .slide-out-div .icons img {
    padding: 0 20px 0 0;
    width: 35px;
}

div[id*="avgSupportBannerSlider"] .slide-out-div .button img {
    padding: 20px 0 0 0;
}

div[id*="avgSupportBannerSlider"] .h2sub {
    font-size: 16px;
    color: #5c707b;
}

div[id*="avgSupportBannerSlider"] .avg-free {
    background: url('../img/support-popout/getFreeSupport.png') no-repeat;
    width: 56px;
    height: 256px;
}

div[id*="avgSupportBannerSlider"] .avg-go {
    background: url('../img/support-popout/needHelp.png') no-repeat;
    width: 56px;
    height: 256px;
}

div[id*="avgSupportBannerSlider"] .avg-close {
    background: url('../img/support-popout/close.png') no-repeat;
    width: 56px;
    height: 256px;
}

div[id*="avgSupportBannerSlider"] div#preloaded-images {
    position: absolute;
    overflow: hidden;
    left: -9999px;
    top: -9999px;
    height: 1px;
    width: 1px;
}

.slide-out-div h1 {
    padding-bottom: 5px;
    font-size: 34px;
    padding-top: 10px;
}

.slide-out-div h2 {
    padding-bottom: 10px;
}

.slide-out-div h1 a {
    color: black;
    font-weight: 300;
    font-family: AVGSans;
}

.slide-out-note{
    text-align: right;
    font-size: 11px;
    margin: 0!important;
    color: #5c707b;
    padding-top: 15px;
}
/*************************/

.attachmentBlock {
    margin-top: -20px;
}

.businessAttachmentBlock .attachmentBlock {
    margin-top: 5px;
}

.attachments .add-button {
    border: 2px dotted #0072D1;
    text-align: center;
    border-radius: 5px;
    font-weight: 300px;
    margin: 10px 0px 10px 0px;
    padding: 10px 3%;
    width: 94%;
    font-family: AVGSans;
    cursor: pointer;
    display: block;
    font-size: 22px;
    cursor: pointer;
}

.attachments .attached-item {
    border: 1px solid #339922;
    text-align: left;
    color: black;
    border-radius: 5px;
    font-weight: 300px;
    margin: 10px 0px 10px 0px;
    padding: 11px 2% 11px 18%;
    width: 80%;
    line-height: 18px !important;
    font-size: 16px;
    font-family: AVGSans;
    cursor: auto;
    background: url('../img/icons/icon-correct-30.png') no-repeat;
    background-position: left 15px center;
    display: block;
}

.attachments .attached-item .remove-item {
    height: 30px;
    width: 30px;
    background: url('../img/icons/icon-remove-30.png') no-repeat;
    display: inline-block;
    cursor: pointer;
    margin-top: 0px;
    float: right;
}

.attachments .attached-item .innerText {
    display: inline-block;
    float: left;
    width: 84%;
    margin-top: 5px;
    word-wrap: break-word;
}

.attachments .loading-bar {
    border: 2px dotted #ff6600;
    text-align: left;
    color: #5c707b;
    border-radius: 5px;
    font-weight: 300px;
    margin: 10px 0px 10px 0px;
    padding-left: 80px;
    padding: 15px 2% 15px 18%;
    width: 80%;
    font-size: 16px;
    font-family: AVGSans;
    cursor: auto;
    display: block;
    background: url('../img/loader.gif') no-repeat;
    background-position: left 15px center;
    background-size: 30px 30px;
}

.attachments .error-message {
    border: 2px dotted #ff6600;
    text-align: left;
    color: #5c707b;
    border-radius: 5px;
    font-weight: 300px;
    margin: 10px 0px 10px 0px;
    padding: 15px 2% 15px 18%;
    width: 80%;
    font-size: 16px;
    font-family: AVGSans;
    cursor: auto;
    display: block;
    background: url('../img/icons/icon-error-30.png') no-repeat;
    background-position: left 15px center;
}


.attachments .error-message span.message {
    color: #ff6600;
    line-height: 22px !important;
}

.attachments .error-message span.link-view {
    color: #0072D1;
    text-decoration: underline;
    line-height: 22px !important;
    cursor: pointer;
}

.refund .error-highlight {
    box-shadow: 0px 0px 1px 1px red;
    outline-color: red;
}

.avg-refund-phone {
    text-align: center;
    padding: 0px 0 10px 0;
}

.avg-refund-phone ul {
    list-style-type: none;
    padding: 20px;
}

.avg-refund-phone.contact ul {
    padding: 0px;
    padding-bottom: 20px;
}

.avg-refund-phone ul li {
    display: block;
    padding-bottom: 5px;
    position: relative;
    color: #5c707b;
}

.avg-refund-phone ul li:before {
    position: absolute;
    top: 8px;
    margin: 0px 0 0 -12px;
    vertical-align: middle;
    display: inline-block;
    width: 4px;
    height: 4px;
    background: #5c707b;
    content: "";
}

.avg-refund-phone ul li a {
    text-decoration: underline;
    margin-left: 10px;
}

.avg-refund-phone .note {
    font-size: 11px;
}

html.refund .channelSelector, html.refund .channelSelector-header {
    display: block;
    padding: 0px 0 20px 0;
}

html.refund .channelSelector {
    margin-bottom: 20px;
}

html.refund .channelSelector span {
    padding: 15px;
    border: 1px solid transparent;
    border-radius: 13px;
    color: #5D717C;
    font-size: 15px;
    margin-right: 20px;
    display: inline-block;
    text-align: center;
}

html.refund .channelSelector a {
    color: #5D717C;
}

html.refund .channelSelector span.active, html.refund .channelSelector span:hover {
    border: 1px solid #0072D1;
}

html.refund .channelSelector span.active {
    color: #0072D1;
}

html.refund .channelSelector .channel-icon {
    width: 60px;
    height: 60px;
    margin: 0 0px 0 0;
    background-repeat: no-repeat;
    background-position: 0 0;
}


html.refund .channelSelector .channel-icon.phone {
    background-image: url('../img/phone-button.png');
}

html.refund .channelSelector .channel-icon.chat {
    background-image: url('../img/chat-button.png');
}

html.refund .channelSelector .channel-icon.mail {
    background-position: 0 -10px;
    background-image: url('../img/email-button.png');
}

html.refund .channelSelector .active .channel-icon.phone {
    background-position: 0 -65px;
}

html.refund .channelSelector .active .channel-icon.chat {
    background-position: 0 -65px;
}

html.refund .channelSelector .active .channel-icon.mail {
    background-position: 0 -75px;
}


/* new FORM */
.newForm h1 {
    font-size: 44px;
}

.newForm h2 {
    font-size: 29px;
}

.newForm {
    margin-top: 20px;
}

.newForm .alert-box {
    border-color: #0072D1;
    border-top: 1px solid;
    border-right: 1px solid;
    border-left: 1px solid;
    border-bottom: 3px solid;
    padding: 14px 20px 14px 20px;
    width: 60%;
    margin: 18px auto 5px;
    text-align: left;
    color: #0072D1;
    margin-bottom: 10px;
    line-height: 22px;
}

.newForm .alert-box a {
    color: #0072D1;
    text-decoration: underline;
    font-family: AVGSans;
}

.newForm.consumer .alert-box h2 {
    color: #0072D1;
    padding: 0;
    font-size: 28px;
    background: none;
    margin-top: 10px;
}

.newForm .error-panel {
    width: 50%;
    margin: 18px auto 5px;
}

.newForm .error-panel-captcha {
    width: 100%;
    margin: 10px 0;
}

.newForm .error-panel-captcha-text {
    width: 100%;
    margin: 0 0 5px;
    padding-top: 20px;
    color: #ff6c2f;
}

.newForm dl.business {
    margin: 0px auto;
}

.newForm dl.business dt {
    text-align: right;
}

.newForm dl.business dd .error-one {
    display: block;
    font-family: AVGSans;
    font-size: 15px;
    color: #ff6c2f;
}

.newForm dl.business input[type="text"] {
    width: 280px;
}

.newForm dl.business select {
    width: 302px;
}

.newForm dl.business dt .dif-text.outer {
    margin-top: 14px;
    display: block;
    height: 40px;
}

.newForm dl.business dt .dif-text.inner {
    margin-right: 20px;
    font-size: 15px;
    font-weight: 300;
}

.newForm dl.business dt .dif-text.inner .error-highlight{
    color: #ff6c2f;
}

.newForm input{
    border-color: #e5e5e5;
    border-radius: 0px;
    border-bottom: 3px solid #e5e5e5;
    padding: 9px;
    margin: 6px 0;
}

.newForm input[type="text"], .newForm textarea, .newForm select {
    border-color: #e5e5e5;
    border-radius: 0px;
    border-bottom: 3px solid #e5e5e5;
    padding: 9px;
    margin: 6px 0;
    outline: none !important;
}

.newForm select {
    cursor: pointer;
    color: #5c707b !important;
    height: 40px !important;
    overflow: hidden !important;
}

.newForm select option {
    padding: 9px;
    color: #5c707b !important;
}

.newForm select option:first-child {
    padding: 0px;
}

.newForm select:focus option:first-child {
    padding: 9px;
}


.newForm select:focus {
    border-bottom: 3px solid #e5e5e5 !important;

}

.newForm textarea {
    width: 440px !important;
}

.newForm textarea.wide {
    width: 640px !important;
}

.newForm input:hover, .newForm textarea:hover, .newForm select:hover
{
    border-bottom: 3px solid #818387;
}

.newForm input.valid, .newForm textarea.valid, .newForm select.valid {
    border-bottom: 3px solid #339922;
}

.newForm input.error-highlight, .newForm textarea.error-highlight, .newForm select.error-highlight {
    border-bottom: 3px solid #ff6c2f !important;
}

.newForm input:focus, .newForm textarea:focus {
    border-bottom: 3px solid #818387 !important;
}

.newForm input[type="submit"], .newForm input[type="button"] {
    border: none !important;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
}

.newForm input[type="button"] {
    color: #ffffff;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    background: #55bb33;
    -webkit-box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.1);
    box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.1);
    border: 0px !important;
}

.newForm input[type="button"]:hover {
    background: #66cc00;
    -webkit-box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.2);
    text-decoration: none;
}

.newForm.consumer input[type="button"] {
    color: #ffffff;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    background: #0072D1;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: 0px !important;
}

.newForm.consumer input[type="button"]:hover,
.newForm.consumer input[type="button"]:focus{
    background: #3aa9fe;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    text-decoration: none;
}

.newForm .attachmentBlock {
    margin-left: 0px;
}

.newForm .attachments .add-button {
    border-radius: 0px;
    border: 1px solid #e5e5e5;
    border-bottom: 3px solid #e5e5e5;
    color: #818387;
    text-align: left;
    font-size: 16px;
    padding: 11px 2% 11px 2%;
    width: 280px;
    background-color: #ffffff;
}

.newForm .attachments .add-button .add-item {
    height: 12px;
    width: 12px;
    background: url('../img/icons/icon-plus.png') no-repeat;
    display: inline-block;
    cursor: pointer;
    margin-top: 5px;
    float: right;
}

.newForm .attachments .attached-item {
    border-radius: 0px;
    border: 1px solid #e5e5e5;
    border-bottom: 3px solid #e5e5e5;
    text-align: left;
    font-size: 16px;
    color: #818387;
    background: none;
    padding: 11px 2% 11px 2%;
    width: 280px;
    font-weight: 300;
    background-color: #ffffff;
    border-bottom: 3px solid #392;
}

.newForm .attachments .attached-item .innerText {
    margin-top: 0px;
}

.newForm .attachments .attached-item .remove-item {
    height: 12px;
    width: 12px;
    background: url('../img/icons/icon-remove.png') no-repeat;
    display: inline-block;
    cursor: pointer;
    margin-top: 5px;
    float: right;
}

.newForm .attachments .loading-bar {
    border-radius: 0px;
    border: 1px solid #e5e5e5;
    border-bottom: 3px solid #ff6c2f;
    text-align: left;
    font-size: 16px;
    color: #818387;
    font-weight: 300;
    width: 206px;
    background-color: #ffffff;
}

.newForm .attachments .error-message {
    border-radius: 0px;
    border: 1px solid #e5e5e5;
    border-bottom: 3px solid #ff6c2f;
    text-align: left;
    font-size: 16px;
    color: #818387;
    font-weight: 300;
    background: url('../img/icons/icon-remove.png') no-repeat;
    background-position: left 15px center;
    padding: 11px 2% 11px 10%;
    width: 243px;
    background-color: #ffffff;
}

.newForm .attachments .add-button:hover, .newForm .attachments .attached-item:hover,
.newForm .attachments .add-button:focus, .newForm .attachments .attached-item:focus {
    border-bottom: 3px solid #818387;
}


.newForm .selector-clean-place {
    min-height: 65px;
}

.newForm #selector,
.newForm #selectorOslist,
.newForm #selectorProdlist,
.newForm #selectorUserlist,
.newForm #selectorRequestlist,
.newForm #selectorLocationlist,
.newForm .selectorList {
    position: relative;
    z-index: 1000;
    margin: 10px 10px 10px 0px;
    padding: 9px 0px 9px 0px;
    border: 1px solid #e5e5e5;
    border-bottom: 3px solid #e5e5e5;
    background: white;
    width: 298px;
    background-image: url("../img/icons/arrow-down-black.png");
    background-repeat: no-repeat;
    background-position: right 10px center;
    /*background-position-x: 260px;
    background-position-y: center;*/
    cursor: pointer;
    text-align: left;
    font-family: AVGSans,Verdana,Arial,Helvetica,sans-serif !important;
}

.newForm .selectorList.wide {
    width: 640px !important
}

.newForm #selectorOslist,
.newForm #selectorUserlist,
.newForm #selectorLocationlist {
    z-index: 1000;
}

.newForm #selectorProdlist,
.newForm #selectorRequestlist {
    z-index: 999;
}

.newForm .selectorList {
    z-index: 0;
}

.newForm #selector:hover,
.newForm #selectorOslist:hover,
.newForm #selectorProdlist:hover,
.newForm #selectorUserlist:hover,
.newForm #selectorRequestlist:hover,
.newForm #selectorLocationlist:hover,
.newForm .selectorList:hover {
    border-bottom: 3px solid #818387;
}

.newForm #selector.error-highlight,
.newForm #selectorOslist.error-highlight,
.newForm #selectorProdlist.error-highlight,
.newForm #selectorUserlist.error-highlight,
.newForm #selectorRequestlist.error-highlight,
.newForm #selectorLocationlist.error-highlight,
.newForm .selectorList.error-highlight {
    border-bottom: 3px solid #ff6c2f;
}
.newForm #selector.valid,
.newForm #selectorOslist.valid,
.newForm #selectorProdlist.valid,
.newForm #selectorUserlist.valid,
.newForm #selectorRequestlist.valid,
.newForm #selectorLocationlist.valid,
.newForm .selectorList.valid{
    border-bottom: 3px solid #339922;
}

.newForm #selector.error-highlight.highlight + span  {
    padding-top: 63px;
}

.newForm #selectorHoverable.highlight + span.error-one,
.newForm #selectorOslistHoverable.highlight + span.error-one,
.newForm #selectorProdlistHoverable.highlight + span.error-one,
.newForm #selectorUserlistHoverable.highlight + span.error-one,
.newForm #selectorRequestlistHoverable.highlight + span.error-one,
.newForm #selectorLocationlistHoverable.highlight + span.error-one,
.newForm .selectorListHoverable.highlight + span.error-one {
    padding-top: 16px;
}


.newForm #selector .shown-item,
.newForm #selectorOslist .shown-item,
.newForm #selectorProdlist .shown-item,
.newForm #selectorUserlist .shown-item,
.newForm #selectorRequestlist .shown-item,
.newForm #selectorLocationlist .shown-item,
.newForm .selectorList .shown-item {
    padding: 0px 36px 0px 10px;
    color: #000000;
    display: inline-block;
}

.newForm #selector .shown-item:hover,
.newForm #selectorOslist .shown-item:hover,
.newForm #selectorProdlist .shown-item:hover,
.newForm #selectorUserlist .shown-item:hover,
.newForm #selectorRequqestlist .shown-item:hover,
.newForm #selectorLocationlist .shown-item:hover,
.newForm .selectorList .shown-item:hover {
    text-decoration: none;
}

.newForm #selector.highlight .shown-item,
.newForm #selectorOslist.highlight .shown-item,
.newForm #selectorProdlist.highlight .shown-item,
.newForm #selectorUserlist.highlight .shown-item,
.newForm #selectorRequestlist.highlight .shown-item,
.newForm #selectorLocationlist.highlight .shown-item,
.newForm .selectorList.highlight .shown-item {
    display: none;
}

.newForm #selector.highlight  {
    padding-top: 0px !important;
    padding-bottom: 0px;
    background: white;
    background-image: none;
    position: absolute;
}

.newForm #selectorOslist.highlight,
.newForm #selectorProdlist.highlight,
.newForm #selectorUserlist.highlight,
.newForm #selectorRequestlist.highlight,
.newForm #selectorLocationlist.highlight,
.newForm .selectorList.highlight {
    visibility: hidden;
}

.newForm #selector ul,
.newForm #selectorHoverable,
.newForm #selectorOslistHoverable,
.newForm #selectorProdlistHoverable,
.newForm #selectorUserlistHoverable,
.newForm #selectorRequestlistHoverable,
.newForm #selectorLocationlistHoverable,
.newForm .selectorListHoverable {
    display: none;
    list-style-type: none;
    padding: 0px;
    overflow-y: auto;
    max-height: 400px;
}

.newForm #selectorHoverable,
.newForm #selectorOslistHoverable,
.newForm #selectorProdlistHoverable,
.newForm #selectorUserlistHoverable,
.newForm #selectorRequestlistHoverable,
.newForm #selectorLocationlistHoverable,
.newForm .selectorListHoverable {
    position: relative;
    z-index: 1000;
    border-bottom: 3px solid #e5e5e5 !important;
    background: white;
    width: 298px;
    border: 1px solid #e5e5e5;
    margin: -32px 0 0 0;
    position: absolute;
}

.newForm #selectorLocationlistHoverable {
    margin: -10px 0 0 0 !important;
}

.newForm #selectorHoverable  {
    margin: 10px 0 0 0;
}

.newForm #selectorHoverable.valid,
.newForm #selectorOslistHoverable.valid,
.newForm #selectorProdlistHoverable.valid,
.newForm #selectorUserlistHoverable.valid,
.newForm #selectorRequestlistHoverable.valid,
.newForm #selectorLocationlistHoverable.valid,
.newForm .selectorListHoverable.valid {
    border-bottom: 3px solid #392 !important;
}

.newForm #selectorHoverable button,
.newForm #selectorOslistHoverable button,
.newForm #selectorProdlistHoverable button,
.newForm #selectorUserlistHoverable button,
.newForm #selectorRequestlistHoverable button,
.newForm #selectorLocationlistHoverable button,
.newForm .selectorListHoverable button {
    background-color: transparent;
    border: 1px solid #e5e5e5;
    cursor: pointer;
    border: 0;
    text-align: left;

}

.newForm #selector.highlight ul li:first-child:hover, .newForm #selector.highlight ul li:first-child  {
    background-image: url("../img/icons/arrow-up-black.png");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-position-x: 260px;
    background-position-y: center;
}

.newForm #selectorHoverable.highlight button:first-child,
.newForm.consumer #selectorOslistHoverable.highlight button:first-child,
.newForm.consumer #selectorProdlistHoverable.highlight button:first-child,
.newForm.consumer #selectorUserlistHoverable.highlight button:first-child,
.newForm.consumer #selectorRequestlistHoverable.highlight button:first-child,
.newForm.consumer #selectorLocationlistHoverable.highlight button:first-child,
.newForm #selectorUnsubReasonListHoverable.highlight button:first-child {
    display: none !important;
}

.newForm #selectorHoverable.highlight button:nth-child(2):hover, .newForm #selectorHoverable.highlight button:nth-child(2),
.newForm #selectorOslistHoverable.highlight button:nth-child(2):hover, .newForm #selectorOslistHoverable.highlight button:nth-child(2),
.newForm #selectorProdlistHoverable.highlight button:nth-child(2):hover, .newForm #selectorProdlistHoverable.highlight button:nth-child(2),
.newForm #selectorUserlistHoverable.highlight button:nth-child(2):hover, .newForm #selectorUserlistHoverable.highlight button:nth-child(2),
.newForm #selectorRequestlistHoverable.highlight button:nth-child(2):hover, .newForm #selectorRequestlistHoverable.highlight button:nth-child(2),
.newForm #selectorLocationlistHoverable.highlight button:nth-child(2):hover, .newForm #selectorLocationlistHoverable.highlight button:nth-child(2),
.newForm #selectorTermTypeListHoverable.highlight button:first-child:hover, .newForm #selectorTermTypeListHoverable.highlight button:first-child,
.newForm #selectorTermDateListHoverable.highlight button:first-child:hover, .newForm #selectorTermDateListHoverable.highlight button:first-child,
.newForm #selectorUnsubReasonListHoverable.highlight button:nth-child(2):hover, .newForm #selectorUnsubReasonListHoverable.highlight button:nth-child(2) {
    background-image: url("../img/icons/arrow-up-black.png");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-position-x: 260px;
    background-position-y: center;
}

.newForm #selector.highlight ul,
.newForm #selectorHoverable.highlight,
.newForm #selectorOslistHoverable.highlight,
.newForm #selectorProdlistHoverable.highlight,
.newForm #selectorUserlistHoverable.highlight,
.newForm #selectorRequestlistHoverable.highlight,
.newForm #selectorLocationlistHoverable.highlight,
.newForm .selectorListHoverable.highlight {
    display: block;
}

.newForm #selector.highlight ul li.selectedOne,
.newForm #selectorHoverable.highlight button.selectedOne,
.newForm #selectorOslistHoverable.highlight button.selectedOne,
.newForm #selectorProdlistHoverable.highlight button.selectedOne,
.newForm #selectorUserlistHoverable.highlight button.selectedOne,
.newForm #selectorRequestlistHoverable.highlight button.selectedOne,
.newForm #selectorLocationlistHoverable.highlight button.selectedOne,
.newForm .selectorListHoverable.highlight button.selectredOne {
    background-color: #fafafa;
}

.newForm #selector.highlight ul li,
.newForm #selectorHoverable.highlight button,
.newForm #selectorOslistHoverable.highlight button,
.newForm #selectorProdlistHoverable.highlight button,
.newForm #selectorUserlistHoverable.highlight button,
.newForm #selectorRequestlistHoverable.highlight button,
.newForm #selectorLocationlistHoverable.highlight button,
.newForm .selectorListHoverable.highlight button {
    padding: 0px;
    width: 100%;
}

.newForm #selector.highlight ul li span,
.newForm #selectorHoverable.highlight button span,
.newForm #selectorOslistHoverable.highlight button span,
.newForm #selectorProdlistHoverable.highlight button span,
.newForm #selectorUserlistHoverable.highlight button span,
.newForm #selectorRequestlistHoverable.highlight button span,
.newForm #selectorLocationlistHoverable.highlight button span,
.newForm .selectorListHoverable button span {
    color: #000000;
    padding: 9px 10px 9px 10px;
    display: block;
    font-weight: 300 !important;
    color: #000000;
    font-size: 16px;
    text-align: left;
    font-family: AVGSans,Verdana,Arial,Helvetica,sans-serif !important;
}

.newForm #selector.highlight ul li:first-child span,
.newForm #selectorHoverable.highlight button:first-child span,
.newForm #selectorOslistHoverable.highlight button:first-child span,
.newForm #selectorProdlistHoverable.highlight button:first-child span,
.newForm #selectorUserlistHoverable.highlight button:first-child span,
.newForm #selectorRequestlistHoverable.highlight button:first-child span,
.newForm #selectorLocationlistHoverable.highlight button:first-child span,
.newForm .selectorListHoverable.highlight button:first-child span {
    padding-right: 36px;
}

.newForm #selector.highlight ul li:hover,
.newForm #selectorHoverable.highlight button:hover, .newForm #selectorHoverable.highlight button:focus,
.newForm #selectorOslistHoverable.highlight button:hover, .newForm #selectorOslistHoverable.highlight button:focus,
.newForm #selectorProdlistHoverable.highlight button:hover, .newForm #selectorProdlistHoverable.highlight button:focus,
.newForm #selectorUserlistHoverable.highlight button:hover, .newForm #selectorUserlistHoverable.highlight button:focus,
.newForm #selectorRequestlistHoverable.highlight button:hover, .newForm #selectorRequestlistHoverable.highlight button:focus,
.newForm #selectorLocationlistHoverable.highlight button:hover, .newForm #selectorLocationlistHoverable.highlight button:focus,
.newForm .selectorListHoverable.highlight button:hover, .newForm #selectorUnsubReasonListHoverable.highlight button:focus {
    background-color: #fafafa;
}

.newForm #selector.highlight ul li:hover span,
.newForm #selectorHoverable.highlight button:hover span,
.newForm #selectorOslistHoverable.highlight button:hover span,
.newForm #selectorProdlistHoverable.highlight button:hover span,
.newForm #selectorUserlistHoverable.highlight button:hover span,
.newForm #selectorRequestlistHoverable.highlight button:hover span,
.newForm #selectorLocationlistHoverable.highlight button:hover span,
.newForm .selectorListHoverable.highlight button:hover span  {
    text-decoration: none;
    color: #392;
}

.newForm .tooltip {
    visibility: hidden;
    width: 100%;
    background-color: #fff;
    text-align: center;
    padding: 12px;
    border: 1px solid;
    font-size: 14px;

    position: absolute;
    z-index: 1;
    bottom: 64px;
    left: 0%;

    opacity: 0;
    -webkit-transition: opacity 0.5s;
    -moz-transition: opacity 0.5s;
    -o-transition: opacity 0.5s;
    transition: opacity 0.5s;

}

#OrderId-popup, #Termination-popup {
    border-radius: 3px;
}

#OrderId-popup .button-div {
    margin-top: 24px;
}

#OrderId-popup .popupInner {
    padding: 24px;
}

#OrderId-popup-yes {
    padding: 16px 24px;
    text-align: center;
    display: inline-block;
    background: #0072D1;
    color: #fff;
    border-radius: 5px;

    text-transform: uppercase;
    text-decoration: none;

    cursor: pointer;
}

#OrderId-popup-no {
    padding: 16px 24px;
    text-align: center;
    display: inline-block;
    background: #fff;
    color: #4d5566;
    border-radius: 5px;
    border: 1px solid #c7cdd7 !important;
    float: right;

    text-transform: uppercase;
    text-decoration: none;

    cursor: pointer;
}

@media (max-width: 992px) {
    .newForm .tooltip {
        top: -80px;
        min-height: 48px;
    }
}

.newForm .tooltip:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 48%;
    margin-left: -8px;
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent transparent;
}

.newForm .tooltip.active {
    visibility: visible;
    opacity: 1;
}

/* newForm - consumer version */
.newForm.consumer #selectorOslist.disabled, .newForm.consumer #selectorProdlist.disabled,
.newForm.consumer #selectorUserlist.disabled, .newForm.consumer #selectorRequestlist.disabled,
.newForm.consumer #selectorLocationlist.disabled, .newForm #selectorTermDateList.disabled {
    background-color: #e5e5e5 !important;
    background-image: none !important;
    cursor: not-allowed !important;
}

.newForm.consumer textarea:disabled{
    background-color: #e5e5e5 !important;
    background-image: none !important;
    cursor: not-allowed !important;
}

.newForm.consumer .wrapper {
    text-align: center;
}

.newForm.consumer .alert-box {
    width: 640px;
    margin: 20px auto 20px auto;
}

.newForm.consumer dl.business  {
    width: 940px;
}

.newForm.consumer dl.business dt {
    float: left;
    width: 280px;
    margin-right: 20px;
    text-align: right;
}

.newForm.consumer dl.business dd {
    float: left;
    width: 640px;
}

.newForm.consumer dl.business dt .dif-text.inner {
    margin-right: 0px;
}

.newForm.consumer dl.business dt .dif-text.inner strong {
    font-weight: bold;
}

.newForm.consumer h2 {
    font-size: 29px;
    padding-top: 20px;
    color: #5c707b;
}

.newForm.consumer h2.toll-free {
    font-size: 24px;
    line-height: 24px;
    padding: 5px 0 15px;
    margin: 0;
    font-weight: 300;
}

.newForm.consumer dl.consumer {
    margin: 0px auto;
    margin-bottom: 20px;
}

.newForm.consumer span + [id*="alert"] {
    margin: 0 !important;
    padding: 0 !important;
}


.newForm.consumer.sales .wrapper {
    width: 640px;
}

.newForm.consumer.sales dl.business {
    width: 100%;
}

.newForm.consumer.sales dl.business dt {
    width: 200px;
}

.newForm.consumer.sales dl.business dd {
    width: 420px;
}

.newForm.consumer.sales .large-col {
    width: 640px;
}

.newForm.consumer.sales .error-panel {
    width: 100%;
}

/* survey version */
.newForm.survey {
    min-height: 400px;
}

.newForm.survey.onlyheader {
    padding-top: 100px;
    min-height: 300px;
}


.newForm.survey dl dd {
    padding-left: 30px;
    width: 413px;
}

.newForm.survey dl.business dt .dif-text.inner {
    margin-right: 0px;
}

.newForm.survey .survey-box {
    text-align: center;
    width: 100%;
}

.newForm.survey .survey-box.yes-no {
    margin: 0;
    text-align: left;
    padding-top: 15px;
    margin-left: -10px;
}

.newForm.survey .survey-box label{
    margin: 0 17px;
}

.newForm.survey .survey-box label.selected,
.newForm.survey .survey-box label:hover {
    background-position: 0 20px;
}

.newForm.survey .survey-box.vertical label.selected,
.newForm.survey .survey-box.vertical label:hover {
    background-position: 0px -20px;
}

.newForm.survey .question-wrapper {
    max-width: 640px;
    margin: 20px auto 0;
}

.newForm.survey .question-wrapper > div {
    display: block;
    margin: 0px auto;
    clear: both;
    padding: 10px;
}

.newForm.survey .question-wrapper .likely, .newForm.survey .question-wrapper .unlikely {
    font-size: 14px;
    color: black;
    font-family: Verdana;
    cursor: pointer;
}

.newForm.survey .question-wrapper .likely {
    float: right;
    margin-right: 5px;
}

.newForm.survey .question-wrapper .unlikely {
    float: left;
    margin-left: 25px;
}


/* product crossroads */
div.crossroad-two-divs, div.crossroad-three-divs {
    display: flex;
    margin-bottom: 30px;
    width: 100%;
}

div.crossroad-two-divs div {
    display: inline-block;
    width: 50%;
    float: left;
    text-align: center;
    flex: 1;
}

div.crossroad-three-divs div {
    display: inline-block;
    width: 33%;
    float: left;
    text-align: center;
    flex: 1;
}

div.crossroad-two-divs div:first-child, div.crossroad-three-divs div:first-child {
    margin-right: 0%;
}

div.crossroad-two-divs div img, div.crossroad-three-divs div img {
    display: block;
    width: 100%;
    height: auto;
}

div.crossroad-two-divs div a h2, div.crossroad-two-divs div a span, div.crossroad-three-divs div a h2, div.crossroad-three-divs div a span  {
    margin-left: 30px;
    margin-right: 30px;
    display: block;
}

div.crossroad-two-divs div a span sup,
div.crossroad-three-divs div a span sup {
    display: inline-block;
    margin: 0 -1px 0 -1px;
}

div.crossroad-two-divs div a h2, div.crossroad-three-divs div a h2 {
    min-height: 48px;
}

div.crossroad-three-divs .button-business, div.crossroad-three-divs .button-business-invert,
div.crossroad-two-divs .button-business, div.crossroad-two-divs .button-business-invert {
    position: relative;
    padding: 13px 0;
    width: 220px;
}

div.crossroad-three-divs .button-business-invert, div.crossroad-two-divs .button-business-invert {
    background-color: transparent;
}

div.crossroad-three-divs a:hover ~ .button-business,
div.crossroad-two-divs a:hover ~ .button-business {
    background-color: #3aa9fe;

}

div.crossroad-three-divs a:hover ~ .button-business-invert,
div.crossroad-two-divs a:hover ~ .button-business-invert,
div.crossroad-three-divs .button-business-invert:hover,
div.crossroad-two-divs .button-business-invert:hover
{
    background-color: #3aa9fe;
    border-color: #3aa9fe;
    color: #fff !important;

}

.feedback-entry .wrapper {
    position: relative;
    min-height: 300px;
    padding-bottom: 50px;
}

.feedback-entry .button-business, .feedback-entry .button-business-invert {
    margin-right: auto;
    margin-left: auto;
    position: relative;
    margin-top: 61px;
    min-width: 220px;
    font-size: 23px;
    padding: 13px 0;
    border-width: 2px;

}

.feedback-entry .button-business {
    border: 2px solid #0072D1 !important;
}

.feedback-entry .button-business:hover {
    border: 2px solid #3aa9fe !important;

}

.feedback-entry .button-business-invert {
    margin-top: 62px;
    background: transparent !important;
}

.feedback-entry .button-business-invert:hover {
    background-color: #3aa9fe !important;
    color: white !important;
}

.feedback-entry h2 {
    font-size: 46px;
    font-weight: 300;
    line-height: 50px;
    margin: 7px 0 0 0;
    padding: 0px;
}


.feedback-entry h2.or {
    font-size: 36px;
    line-height: 42px;
    margin-top: 17px;
}


.feedback-entry h2 ~ p {
    margin: 0;
    margin-top: 2px;
    font-size: 30px;
}

.feedback-entry p.only-english {
    margin-top: -6px;
    font-size: 19px;
    font-weight: 500;
    font-family: AVGSans !important;
}

body.community footer#footer .footer-links ul.list-unstyled.footer-links-content li {
    margin-left: 0px
}

span.blue-border {
    border: 1px solid #0072D1;
    border-radius: 5px;
    display: inline-block;
    margin: 4px;
    padding: 0 4px;
    color: #0072D1;
}

h1 span.blue-border {
    margin:0px;
    line-height: 32px;

}

span.footer-newline {
    display: block;
    margin-top: 0px;
}

#remote-support #content-inner {
    text-align: center;
}

#remote-support #content-inner .input.text {
    text-align: left;
}

@media screen and (max-width: 980px) {
    #remote-support #content-inner {
        padding: 0 10%;
        width: 80%;
    }
}

body#remote-support {
    background: white;
}

#remote-support #content {
    background: white;
    padding-top: 50px;
}

#remote-support [id*=header] {
    margin-bottom: 0;
    background: #fafafa;
    margin-top: 0 !important;
}

#remote-support [id*=header] h2 {
    display: none;
}

@media screen and (max-width: 980px) {
    #remote-support [id*=header] a.logo {
        margin: 25px 0 15px 0;
    }
}

#remote-support.form-height {
    min-height: 1100px;
}

#remote-support h3 {
    color: #5C707B;
}

#remote-support fieldset div.input.text > * {
    display: block;
    margin: 10px auto;
}

#remote-support fieldset {
    width: 25%;
    margin: 30px auto 0px auto;
    padding: 20px;
    position: relative;
    border: 1px solid #dedede;
    box-shadow: 0 1px 5px #dedede;
    background: #fafafa;
}

@media screen and (max-width: 980px) {
    #remote-support fieldset {
        width: 90%;
    }
}

#remote-support fieldset input {
    width: 100%;
    outline: none;
}

#remote-support fieldset .input.text input {
    width:95%;
}

@media screen and (max-width: 980px) {
    #remote-support fieldset input.text {
        width:90%;
    }
}

#remote-support fieldset .note {
    color: #5C707B;
    font-size: 12px;
}

#remote-support fieldset span.req {
    position: absolute;
    right: 15px;
    top: 15px;
    color: #5C707B;
    font-size: 10px;
}

#remote-support #downloadbox {
    margin: 20px auto 0 auto;
}

#remote-support #downloadbox .col-md-4{
    width: 30%;
    padding: 10px 1.5% 10px 1.5%;
}

#remote-support #downloadbox h4 {
    font-size: 18px;
    text-align: left;
}

#remote-support #downloadbox h4 span {
    color: #FFF;
    background: #52bc30;
    padding: 1px 4px;
    line-height: 18px;
    display: inline;
}

#remote-support #downloadbox .col-md-4 div{
    height: 164px;
    width: 100%;
}

#remote-support #downloadbox .a div{
    background: url('../img/remote-support/728-icon-1.png') no-repeat center center;
}

#remote-support #downloadbox .b div{
    background: url('../img/remote-support/728-icon-2.png') no-repeat center center;
}

#remote-support #downloadbox .c div{
    background: url('../img/remote-support/728-icon-3.png') no-repeat center center;
}

#remote-support #content h2 {
    font-size: 34px;
}

#remote-support #content h3 {
    font-size: 24px;
}

#content.email-verify {
    text-align: center;
    padding: 50px 0 0 0;
}

#content.email-verify .wrapper {
    max-width: 664px;
    margin: 0px auto;
}

#content.email-verify .icon {
    width: 64px;
    height: 64px;
    display: inline-block;
    margin: 0px auto;
}

#content.email-verify .success .icon {
    background: url('../img/icons/email-verify/success.svg') no-repeat top left;
}

#content.email-verify .fail .icon,
#content.email-verify .expired .icon {
    background: url('../img/icons/email-verify/fail.svg') no-repeat top left;
}

#content.email-verify .contact-support.button {
    background-color: #00b451;
    padding-right: 54px;
    background-image: url('../img/icons/email-verify/arrow-m-right.svg');
    background-repeat: no-repeat;
    background-position: center right 20px;
    display: inline-block;
    margin: 30px auto;
}

#content.email-verify .log-into-account.button {
    background-color: #00b451;
    padding-left: 44px;
    background-image: url('../img/icons/email-verify/plus-m-copy-6.svg');
    background-repeat: no-repeat;
    background-position: center left 10px;
    display: inline-block;
    margin: 30px auto;
}

#content.email-verify .button:hover {
    background-color: #00b451 !important;
}

#content.email-verify .log-into-account,
#content.email-verify .visit-sc {
    display: block;
}

#content.email-verify .log-into-account {
    width: 200px;
    margin-left: auto;
    margin-right: auto;
}

#content.email-verify .log-into-account.link:hover {
    text-decoration: underline;
}

#content.email-verify .success .log-into-account {
    margin: 30px auto;
}

#content.email-verify .subtitle,
#content.email-verify .visit-sc,
#content.email-verify .visit-sc a {
    color: #4d5566;
}

#content.email-verify h1 {
    color: #1e222a;
    font-size: 40px;
    font-weight: bold;
}

#content.email-verify .subtitle {
    font-size: 16px;
}

#content.email-verify .visit-sc a {
    font-family: AVGSans;
    text-decoration-line: underline;
    text-decoration-style: dotted;
}

#content.email-verify .visit-sc a:hover {
    text-decoration-line: none;
    text-decoration: none;
}

/* article feedback */
.article-feedback {
    padding: 25px 0px;
    position: relative;
    border-top: solid 1px #dcdcdc;
    border-bottom: solid 1px #dcdcdc;
}

@media (max-width: 480px) {
    .article-feedback {
        text-align: center
    }
}

.article-feedback h3,
.article-feedback p,
.article-feedback h4 {
    margin: 0px;
    color: #000;
    font-weight: 300;
    padding: 0;
}

.article-feedback p {
    color: #000;
    font-size: 14px
}

.article-feedback h3 {
    color: #41424E;
    font-size: 20px
}

@media (max-width: 480px) {
    .article-feedback h3 {
        text-align: center
    }
}

.article-feedback h4 {
    color: #41424E;
    font-size: 18px
}

.article-feedback h3.white {
    color: #000
}

.article-feedback .button-wrap {
    position: absolute;
    right: 0px;
    top: 26px
}

@media (max-width: 480px) {
    .article-feedback .button-wrap {
        position: relative;
        display: block;
        margin: 10px auto auto auto;
        text-align: center;
        right: 0px;
        top: 0px
    }
}

.article-feedback .button-wrap>button:first-child {
    margin-right: 40px
}

.article-feedback .button-wrap .button {
    display: inline-block;
    width: 70px;
    text-align: right;
    background: url('../img/icons/radio.png') no-repeat left center;
    border: 0;
    font-size: 1.3em;
    color: #5c707b;
    padding: 0;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0;
    cursor: pointer;
    height: 24px;
}

.article-feedback .button-wrap button.button:hover,
.article-feedback .button-wrap button.button:focus {
    background-color: transparent !important;
    background: url('../img/icons/radio-sel.png') no-repeat left center;
}

.article-feedback .button.send-feedback {
    margin-top: 20px;
    float: none !important;
    width: 100%;
}

.article-feedback a.button:hover,
.article-feedback button.button:hover,
.article-feedback a.button:focus,
.article-feedback button.button:focus {
    background-color: #3aa9fe !important;
}

.article-feedback a.button.disabled,
.article-feedback a.button.sending,
.article-feedback a.button.disabled:hover,
.article-feedback a.button.sending:hover,
.article-feedback button.button.disabled,
.article-feedback button.button.sending,
.article-feedback button.button.disabled:hover,
.article-feedback button.button.sending:hover{
    background-color: rgba(31, 40, 64, 0.5) !important
}

.article-feedback .innerPanel .button {
    float: right;
    right: 0;
    border: 0
}

.article-feedback .innerPanel a.button,
.article-feedback .innerPanel button.button{
    display: inline-block;
    font-family: AVGSans;
    font-size: 18px;
    background-color: #0072D1;
    color: #fff;
    border: 0;
    border-radius: 5px;
    padding: 14px 30px;
    text-align: center;
    cursor: pointer;
    max-width: 50%;
}

.article-feedback .button.sending {
    cursor: progress
}

.article-feedback #reasonListLabel  {
    color: #f5203e;
    float: right;
    display: inline-block;
    font-size: 12px
}

.article-feedback .innerPanel {
    padding: 15px;
    /*background: #f2f2f6;*/
    margin-bottom: 10px;
    margin-top: 20px;
    position: relative;
    border: solid 1px #dcdcdc;
}

@media (max-width: 480px) {
    .article-feedback .innerPanel.contact h4,
    .article-feedback .innerPanel.contact p {
        text-align: center
    }
}

@media (max-width: 480px) {
    .article-feedback .innerPanel .button {
        float: none;
        width: 100%
    }
}

.article-feedback .innerPanel .button.contact {
    margin-top: -45px;
    padding: 12px 8px
}

@media (max-width: 480px) {
    .article-feedback .innerPanel .button.contact {
        margin-top: 5px
    }
}

.article-feedback .innerPanel .button:hover {
    text-decoration: none
}

.article-feedback .innerPanel textarea {
    width: 100%;
    border-radius: 3px;
    border: 1px solid #d3d4de;
    outline: none;
    font-size: 14px;
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #41424E;
    resize: vertical
}

@media (max-width: 480px) {
    .article-feedback .innerPanel textarea {
        margin-top: 10px
    }
}

.article-feedback .innerPanel textarea:focus {
    border: 1px solid #767683
}

.article-feedback textarea:-moz-placeholder {
    color: rgba(118, 118, 131, 0.5)
}

.article-feedback textarea:-ms-input-placeholder {
    color: rgba(118, 118, 131, 0.5)
}

.article-feedback textarea::-webkit-input-placeholder {
    color: rgba(118, 118, 131, 0.5)
}

.article-feedback .innerPanel div.spacer {
    display: block;
    height: 40px
}

@media (max-width: 480px) {
    .article-feedback .innerPanel div.spacer {
        display: none
    }
}

.article-feedback h3.tick {
    background-image: url("../img/article/check-white.png");
    background-position: left 10px center;
    background-size: 35px 35px;
    background-repeat: no-repeat;
    padding-left: 60px;
    line-height: 28px;
    color: #000
}

.article-feedback h3.tick:before {
    content: none;
}


@media (max-width: 480px) {
    .article-feedback h3.tick {
        text-align: left
    }
}

.article-feedback .reasonSelectedOption {
    display: block;
    padding: 10px 30px 10px 20px;
    border: 1px solid #d3d4de;
    cursor: pointer;
    background-image: url("../img/article/feedback-dropdown-arrow.png");
    background-position: right 10px center;
    background-size: 13px 9px;
    background-repeat: no-repeat;
    background-color: #fff;
    border-radius: 3px;
    max-width: 100%;
    margin: 0;
    font-size: 14px;
    font-weight: 300;
    margin-top: 0px;
    text-align: left !important;
    width: 100%;
}

@media (max-width: 1200px) {

    .article-feedback .reasonSelectedOption {
        padding: 15px 30px 15px 20px;
        font-size: 18px
    }
}

@media (max-width: 480px) {

    .article-feedback .reasonSelectedOption {
        font-size: 14px
    }
}

.article-feedback .reasonSelectedOption.error-highlight {
    border: 1px solid #f5203e
}

@media (max-width: 1200px) {
    .article-feedback .reasonSelectedOption {
        padding: 15px 30px 15px 20px;
        font-size: 18px
    }
}

@media (max-width: 480px) {
    .article-feedback .reasonSelectedOption {
        font-size: 14px
    }
}

.article-feedback .reasonSelectedOption.error-highlight {
    border: 1px solid #f5203e
}

.article-feedback .reasonListFrontendWrapper {
    position: relative;
    width: 50%;
    display: inline-block;
    margin-top: 20px;
    margin-bottom: 5px;
}

@media (max-width: 980px) {
   .article-feedback .reasonListFrontendWrapper {
       width: 100%;
   }
}

@media (max-width: 480px) {
    .article-feedback .reasonListFrontendWrapper {
        width: 100%
    }
}

.article-feedback .reasonListFrontend {
    display: block;
    position: absolute;
    z-index: 1000;
    border: 1px solid #767683;
    border-radius: 3px;
    padding: 0;
    background: #fff;
    width: 100%
}

.article-feedback .reasonListFrontend {
    max-height: 350px;
    overflow-y: auto;
}

.article-feedback .reasonListFrontend>button {
    display: block;
    padding: 10px 10px 10px 10px;
    cursor: pointer;
    max-width: 100%;
    margin: 0;
    font-size: 14px;
    font-weight: 300;
    text-align: left !important;
    background: transparent;
    border: 0;
    width: 100%;
}

@media (max-width: 1200px) {
    .article-feedback .reasonListFrontend>button {
        font-size: 18px;
        padding: 15px 15px 15px 20px
    }
}

@media (max-width: 480px) {
    .article-feedback .reasonListFrontend>button {
        font-size: 14px
    }
}

.article-feedback .reasonListFrontend>button:first-child {
    -webkit-border-top-left-radius: 3px;
    -webkit-border-top-right-radius: 3px;
    -moz-border-radius-topleft: 3px;
    -moz-border-radius-topright: 3px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px
}

.article-feedback .reasonListFrontend>button:last-child {
    -webkit-border-bottom-right-radius: 3px;
    -webkit-border-bottom-left-radius: 3px;
    -moz-border-radius-bottomright: 3px;
    -moz-border-radius-bottomleft: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px
}

.article-feedback .reasonListFrontend>button.selected,
.article-feedback .reasonListFrontend>button:hover,
.article-feedback .reasonListFrontend>button:focus {
    background-color: #f2f2f6
}

.article-feedback .button-wrapper {
    position: relative;
    display: block;
    width: 50%;
    text-align: right;
    display: inline-block;
}

@media (max-width: 980px) {
    .article-feedback .button-wrapper {
        width: 100% !important;
        text-align: center;
        margin-top: 0px;
    }
}

.article-feedback .button-wrapper.mobile-device .button, .article-feedback .button.contact {
    max-width: 50% !important;
}

.article-feedback .button-wrapper .button+span.tooltiptext {
    visibility: hidden;
    width: 300px;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    text-align: center;
    padding: 10px 5px;
    border-radius: 5px;
    position: absolute;
    z-index: 1;
    top: -45px;
    left: unset !important;
    right: 0%;
    opacity: 0;
    -webkit-transition: opacity 0.5s;
    -moz-transition: opacity 0.5s;
    -o-transition: opacity 0.5s;
    transition: opacity 0.5s
}
.article-feedback .button-wrapper .button+.tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 64%;
    margin-left: -8px;
    border-width: 8px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.7) transparent transparent transparent
}

@media screen and (max-width: 980px) {
    .article-feedback .button-wrapper .button+span.tooltiptext {
        right: 0%;
        left: 0%;
        width: 100%;
        top: -30px;
     }

    .article-feedback .button-wrapper .button+.tooltiptext::after {
        left: 50%;
    }
}

.article-feedback .button-wrapper.desktop-device .button.disabled:hover+.tooltiptext {
    visibility: visible;
    opacity: 1
}

/* ===== RESPONSIVE DESIGN MEDIA QUERIES ========== */

@media screen and (max-width: 980px) {
    .wrapper {
        /* 980px breakpoint */
        width: 97.8%;
        margin: 0 auto;
        padding: 0;
        position: relative;
    }
    .wrapper.contact {
        /* 980px breakpoint */
        width: 97.8%;
        margin: 0 auto;
        padding: 0;
    }
    [id*=header] {
        /* 980px breakpoint */
        height: auto;
    }
    [id*=header] div.right.wrapper.with-menu {
        /* 980px breakpoint */
        width: 100%;
    }
    [id*=header] div.right.with-search {
        /* 980px breakpoint */
        float: none;
        margin: 0 auto;
    }
    [id*=header] div.right.wrapper.login {
        /* 980px breakpoint */
        position: absolute;
        top: 0;
        right: 15px;
    }
    [id*=header] a.with-headline {
        /* 980px breakpoint */
        display: block;
        padding: 10px;
    }
    .homepage input[type="text"],
    .homepage select {
        /* 980px breakpoint */
        width: 78%;
    }
    .badge-homepage .badge-container {
        /* 980px breakpoint */
        width: 48% !important;
        margin-right: 2% !important;
        margin-bottom: 0px;
        float: left;
    }
    .badge-homepage .badge-container {
        /* 980px breakpoint */
        min-height: 310px !important;
    }
    .badge-homepage .badge-container.business {
        /* 980px breakpoint */
        min-height: auto;
    }
    .badge-homepage .badge-container.tripplecontainer {
        /* 980px breakpoint */
        width: 32%;
        margin-right: 2%;
    }
    .badge-homepage .badge-container ul.underlined {
        /* 980px breakpoint */
        min-height: 160px;
        padding-left: 4%;
        padding-right: 4%;
    }
    .badge,
    .badge.tripplecontainer {
        /* 980px breakpoint */
        width: 100%;
    }
    .sq-badge {
        /* 980px breakpoint */
        width: 30%;
        padding: 15px solid #f1f1f1;
        min-height: 110px;
    }
    .sq-badge.home {
        /* 980px breakpoint */
        height: auto;
        min-height: auto;
    }
    .sq-badge.home.sales {
        /* 980px breakpoint */
        padding: 0px 0 0px 0;
    }
    .sq-badge.home.partner {
        /* 980px breakpoint */
        border-left: 2px solid white;
        border-right: 2px solid white;
        padding: 0px 0 0px 1%;
    }
    .sq-badge.home.business {
        /* 980px breakpoint */
        padding: 0px 0 0px 1%;
    }
    .sq-badge.home.dsr {
        /* 980px breakpoint */
        padding: 0px 0 0px 1%;
    }

    .sq-badge.home {
        /* 980px breakpoint */
        width: 24%;
    }
    .third-box,
    .third-box.community,
    .third-box.business-community.first,
    .third-box.business-community.last,
    .third-box.business-community.picture {
        /* 980px breakpoint */
        width: 30%;
        padding: 15px 1.2%;
        height: auto;
    }
    .third-box.community {
        /* 980px breakpoint */
        border-bottom: 1px solid #DCDCDC;
    }
    .third-box h2 {
        /* 980px breakpoint */
        padding: 0;
    }
    .large-col,
    [id*=survey] {
        /* 980px breakpoint */
        width: 640px;
    }
    .large-col.full-line {
        width: 100%;
    }
    div[id*=footer] div.box {
        /* 980px breakpoint */
        padding-right: 10px;
        min-height: 220px;
    }
    div.support-type-buttons a.button.inverted {
        /* 980px breakpoint */
        width: 30%;
        margin-left: 1%;
        margin-right: 1%;
        font-size: 16px;
    }
    div.support-type-buttons a.button.stype {
        /* 980px breakpoint */
        width: 205px;
    }
    .embed-container {
        /* 980px breakpoint */
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 30px;
        height: 0;
        overflow: hidden;
    }
    .embed-container iframe,
    .embed-container object,
    .embed-container embed {
        /* 980px breakpoint */
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .survey-box .half-width {
        /* 980px breakpoint */
        width: 100%;
    }
    .survey-box textarea.half-width {
        /* 980px breakpoint */
        width: 470px;
    }
    div.survey-box.horizontal p.fieldset-range {
        /* 980px breakpoint */
        margin: 25px 8px 25px 8px;
    }
    .survey-box.vertical tr {
        /* 980px breakpoint */
        line-height: 3em;
    }
    .survey-box.horizontal label {
        /* 980px breakpoint */
        margin: 0 4px;
    }
    [id*=header] .flag {
        /* 980px breakpoint */
        display: none;
    }
    [id*=header] .loginLink {
        /* 980px breakpoint */
        border-right: 0;
        margin-right: 0;
        padding-right: 0;
    }
    [id*=mobile-language-selector] {
        /* 980px breakpoint*/
        display: inline;
        padding: 2%;
        width: 70%;
        float: right;
    }
    [id*=header] h2 {
        /* 980px breakpoint*/
        font-size: 20px;
        padding-right: 0;
        margin-top: 16px;
        margin-right: 10px;
    }
    [id*=header] h2.pl,
    [id*=header] h2.nl_NL {
        /* 980px breakpoint*/
        font-size: 16px;
    }
    .banner {
        /* 980px breakpoint*/
        display: none;
    }
    .third-box.community.fr .prominent-link.black {
        /* 980px breakpoint*/
        margin-top: 145px;
    }
    [id*=article-print-email-box] .prominent-link.inversed {
        /* 980px breakpoint*/
        margin-bottom: 26px;
        float: none;
    }
    [id*=article-print-email-box] .right {
        /* 980px breakpoint*/
        float: none;
    }
    [id*=article-print-email-box] {
        /* 980px breakpoint*/
        text-align: center;
    }
    .three-boxes .box .inner {
        /* 980px breakpoint */
        height: 260px;
    }
    .three-boxes .box .inner p {
        /* 980px breakpoint */
        height: 100px;
    }
    .three-boxes.pl.not-found .box .inner h2 {
        /* 980px breakpoint */
        font-size: 18px;
    }
    .three-boxes .box {
        /* 980px breakpoint */
        width: 50%;
        margin: 0 auto;
        float: none;
        margin-bottom: 15px;
    }
    .article-side-box {
        /* 980px breakpoint */
        display: none;
    }
    .article-side-box-banner {
        /* 980px breakpoint */
        display: none;
    }
    .pts-home .inner {
        /* 980px breakpoint */
        width: 92%;
        padding: 4%;
    }
    .pts-home {
        /* 980px breakpoint */
        background: #f3f3f3;
    }
    html.contact-form.chat #content > div.wrapper {
        /* 980px breakpoint */
        width: 100% !important;
        padding: 0;
        margin: 0px auto !important;
    }
    html.contact-form.chat #content #chat-frame-wrapper {
        /* 980px breakpoint */
        margin: 0px auto !important;
        width: 100% !important;
        padding: 0;
    }
    #avg-go-refund-note {
        /* 980px breakpoint */
        width: 100%;
    }
    .business-underlined-last-item {
        /* 980px breakpoint */
        margin-bottom: 30px;
    }
    .padding-bottom-50 {
        /* 980px breakpoint */
        padding-bottom: 20px;
    }
    .padding-bottom-60 {
        /* 980px breakpoint */
        padding-bottom: 20px;
    }
    .padding-bottom-65 {
        padding-bottom: 0px;
        /* 980px breakpoint */
    }
    .badge-homepage .badge-container ul.business-underlined {
        /* 980px breakpoint */
        padding-left: none;
        padding-right: none;
    }
    .badge-homepage .badge-container ul.business-underlined.tripplecontainer {
        /* 980px breakpoint */
        padding-left: none;
        padding-right: none;
    }
    ul.business-underlined {
        /* 980px breakpoint */
        height: auto !important;

    }
    .contact-us-wrapper {
        /* 980px breakpoint */
        width: 100%;
        padding: 0px;
        height: auto;
    }
    .contact-us-wrapper .contact-us-logo,
    .contact-us-wrapper .contact-us-text,
    .contact-us-wrapper .contact-us-not {
        /* 980px breakpoint */
        text-align: center;
        margin: 0 auto;
        width: 100%;
    }
    .contact-us-wrapper .contact-us-logo {
        /* 980px breakpoint */
        margin-top: 0px;
        height: 140px;
    }
    .contact-us-text {
        /* 980px breakpoint */
        padding-top: 0px;
        padding-left: 0px;
    }
    .contact-us-text-outer {
        /* 980px breakpoint */
        margin-top: -10px;
        margin-bottom: 10px;
        display: block;
        height: auto;
        width: 100%;
    }
    .contact-us-text-inner {
        /* 980px breakpoint */
        margin-top: 0px;
        width: 80%;
        margin: 0px auto;
    }
    .contact-us-text h3 {
        /* 980px breakpoint */
        color: #0072D1;
    }
    .contact-us-not {
        /* 980px breakpoint */
        padding-top: 0px;
        border-left: none;
        border-top: 2px solid #ffffff;
        padding-left: 0px;
        padding-right: 0px;
        margin-top: 10px !important;
    }
    .contact-us-not-outer {
        /* 980px breakpoint */
        margin-top: 10px;
        margin-bottom: 10px;
        display: block;
        height: auto;
        width: 100%;
    }
    .contact-us-not-inner {
        /* 980px breakpoint */
        margin-top: 0px;
        width: 80%;
        margin: 0px auto;
    }
    .contact-us-not h3 {
        /* 980px breakpoint */
        color: #0072D1;
    }
    h2#subHome {
        /* 980px breakpoint */
        padding: 0px 0px 15px 0px;
    }
    .premium-support-wrapper {
        /* 980px breakpoint */
        display: none;
    }
    .third-box.business-community.first {
        /* 980px breakpoint */
        width: 47%;
        padding: 15px 1.2%;
        float: left;
    }
    .third-box.business-community.last {
        /* 980px breakpoint */
        width: 47%;
        padding: 15px 1.2%;
        float: right;
    }
    .third-box.business-community.picture {
        /* 980px breakpoint */
        width: 36%;
        padding: 45px 0%;
    }
    .third-box.business-community.with-center-part.first {
        /* 980px breakpoint */
        width: 30%;
        padding: 45px 1% 40px 1%;
    }
    .third-box.business-community.with-center-part.last {
        /* 980px breakpoint */
        width: 30%;
        padding: 45px 1% 40px 1%;
    }
    .badge.home-multi-device.pl h2,
    .badge.home-win.pl h2,
    .badge.home-android.pl h2,
    .badge.home-mac.pl h2,
    .badge.home-pts.pl h2 {
        /* 980px breakpoint */
        font-size: 18px;
        padding-top: 0px;
        height: 94px;
        margin-top: 9px;
    }

    div[id*="avgSupportBannerSlider"] .slide-out-div {
        /* 980px breakpoint */
        /* sidesliderbanner is hidden for small devices */
        display: none !important;
    }

    div[id*="formContentComponents"] {
        padding: 0 25%;
    }

    .newForm.consumer dl.business {
        /* 980px breakpoint */
        width: 100%;
    }
    .newForm.consumer dl.business dt,
    .newForm.consumer.sales dl.business dt {
        /* 980px breakpoint */
        display: block;
        width: 100%;
        line-height: 15px;
    }

    .newForm.consumer dl.business dt {
        /* 980px breakpoint */
        text-align: left;
    }
    .newForm.consumer dl.business dt .dif-text.outer{
        /* 980px breakpoint */
        height: 20px;
    }
    .newForm.consumer dl.business dd,
    .newForm.consumer.sales dl.business dd {
        /* 980px breakpoint */
        display: block;
        width: 100%;
    }
    .newForm.consumer .alert-box {
        width: 420px;
    }

    .newForm.consumer.sales dl.business {
        /* 980px breakpoint */
        width: 75%;

    }

    .nl_NL h1.homepage-headline {
        /* 980px breakpoint */
        font-size: 28px;
    }

    .nl_NL  h1.homepage-headline span.homepage-subheadline {
        /* 980px breakpoint */
        font-size: 20px;
    }

    .devices-wrapper.pts.number {
        /* 980px breakpoint */
        width: 100%;
    }

    .devices-wrapper.pts.number div.content {
        /* 980px breakpoint */
        background-image: url('../img/pts/devices_min.png');
        background-position: center top 30px;
        text-align: center;
        background-size: 75px 56.25px;
        padding: 90px 90px 30px 90px;
    }

    .devices-wrapper.pts.number span.note {
        /* 980px breakpoint */
        margin: 15px auto 0 auto;
        max-width: 275px;
    }

    .devices-wrapper.pts.number span.innerText {
        /* 980px breakpoint */
        font-size: 14px;
        line-height: 18px;
    }

    .switcher-wrapper {
        /* 980px breakpoint */
        border: none !important;
        text-align: center;
    }

    .switcher-wrapper .support-switcher {
        /* 980px breakpoint */
        min-width: auto;
        padding-left: 6px;
        padding-right: 6px;
    }

    .switcher-wrapper .support-switcher {
        /* 980px breakpoint */
        display: inline-block;
        float: none;
        padding-left: 6px;
        padding-right: 6px;
        text-align: center;
        width: auto;
        min-width: auto;
    }

    .switcher-wrapper .support-switcher {
        /* 980px breakpoint */
        border-bottom: 1px solid #dcdcdc !important;
        margin: 4px !important;
    }
}

@media screen and (max-width: 768px) {
    .center-text-mobile {
        text-align: center;
    }

    .homepage-header-wrap.business-background,
    .homepage-header-wrap.partners-background,
    .homepage-header-wrap.consumer-background {
        /* 768px breakpoint */
        min-height: 320px;
        height: auto;
    }

    .homepage-header-wrap.poster-background {
        /* 768px breakpoint */
        min-height: 270px !important;
    }

    .homepage-header-wrap.consumer-background.pl {
        /* 768px breakpoint */
        height: auto;
    }

    .wrapper {
        /* 768px breakpoint */
        width: 97.8%;
        margin: 0 auto;
        padding: 0;
    }
    .width-700px {
        /* 768px breakpoint */
        width: 100%;
    }
    .width-500px {
        /* 768px breakpoint */
        width: 100%;
    }
    [id*=header] {
        /* 768px breakpoint */
        height: auto;
    }
    [id*=header] div.right.wrapper.with-menu {
        /* 768px breakpoint */
        width: 100%;
    }
    [id*=header] div.right.with-search {
        /* 768px breakpoint */
        float: none;
        margin: 0 auto;
    }
    [id*=header] div.right.wrapper.login {
        /* 768px breakpoint */
        position: absolute;
        top: 0;
        right: 15px;
    }
    [id*=header] a.with-headline {
        /* 768px breakpoint */
        display: block;
        padding: 10px;
    }
    .homepage input[type="text"],
    .homepage select {
        /* 768px breakpoint */
        width: 71%;
    }
    .badge-homepage .badge-container ul.underlined {
        /* 768px breakpoint */
        min-height: 160px;
        padding-left: 4%;
        padding-right: 4%;
    }
    .badge,
    .badge.tripplecontainer {
        /* 768px breakpoint */
        width: 100%;
    }
    .badge-homepage .badge-container {
        /* 768px breakpoint */
        min-height: 340px !important;
    }
    .sq-badge {
        /* 768px breakpoint */
        width: 25%;
        padding: 15px solid #f1f1f1;
    }
    .third-box,
    .third-box.community {
        /* 768px breakpoint */
        width: 30%;
        padding: 15px 1.2%;
        height: auto;
    }
    .third-box.community {
        /* 768px breakpoint */
        border-bottom: 1px solid #DCDCDC;
    }
    .third-box h2 {
        /* 768px breakpoint */
        padding: 0;
    }
    .large-col,
    [id*=survey] {
        /* 768px breakpoint */
        margin: 0 auto;
        width: 95%;
    }
    [id*=alert].the-form-static-width {
        /* 768px breakpoint */
        padding: 20px 3% 20px 3%;
        width: 89%;
        margin: 0px auto;
    }
    ul.product-list li, ul.product-list li.wider {
        /* 768px breakpoint */
        padding: 20px 3% 0 3%;
        width: 26%;
        margin-right: 1%;
        margin-bottom: 10px;
    }
    .product-list-page h2,
    .product-list-page h1 {
        /* 768px breakpoint */
        text-align: center;
    }
    .devices-wrapper {
        /* 768px breakpoint */
        width: 100%;
    }
    .devices-wrapper .devices-logo,
    .devices-wrapper .devices-text-en_us,
    .devices-wrapper .devices-text-de,
    .devices-wrapper .devices-text-cs,
    .devices-wrapper .devices-text-fr,
    .devices-wrapper .devices-text-it,
    .devices-wrapper .devices-text-nl_nl,
    .devices-wrapper .devices-text-pl,
    .devices-wrapper .devices-text-es,
    .devices-wrapper .devices-text-pt_br {
        /* 768px breakpoint */
        margin: 0 auto;
        float: none;
        width: 80%;
        background-size: contain;
    }
    .devices-wrapper .devices-en_us,
    .devices-wrapper .devices-de,
    .devices-wrapper .devices-cs,
    .devices-wrapper .devices-fr,
    .devices-wrapper .devices-it,
    .devices-wrapper .devices-nl_nl,
    .devices-wrapper .devices-pl,
    .devices-wrapper .devices-es,
    .devices-wrapper .devices-pt_br {
        /* 768px breakpoint */
        background-size: contain;
        width: 80%;
    }

    .devices-wrapper.pts.global {
        /* 768px breakpoint */
        width: 100%;
    }

    .devices-wrapper.pts.global div.content{
        /* 768px breakpoint */
        padding: 107px 20px 30px 20px; /* padding: 134px 20px 20px 20px;*/
        background-size: 105.5px 81px; /* background-size: 134px 108px;*/
        background-position: center top 30px;
        text-align: center;
    }

    .devices-wrapper.pts.global span.line {
        /* 768px breakpoint */
        display: block;
    }

    div[id*=footer] div.box {
        /* 768px breakpoint */
        width: 20%;
        padding-right: 20px;
        min-height: 220px;
    }
    div.support-type-buttons a.button.inverted {
        /* 768px breakpoint */
        width: 30%;
        margin-left: 1%;
        margin-right: 1%;
        font-size: 16px;
    }
    div.support-type-buttons a.button.stype {
        /* 768px breakpoint */
        width: 205px;
    }
    .embed-container {
        /* 768px breakpoint */
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 30px;
        height: 0;
        overflow: hidden;
    }
    .embed-container iframe,
    .embed-container object,
    .embed-container embed {
        /* 768px breakpoint */
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .survey-box .half-width {
        /* 768px breakpoint */
        width: 100%;
    }
    .email-note.cform {
        /* 768px breakpoint */
        display: none;
    }
    .cform-col {
        /* 768px breakpoint */
        width: 100%;
    }
    [id*=header] .flag {
        /*768px breakpoint*/
        display: none;
    }
    [id*=header] .loginLink {
        /* 768px breakpoint */
        border-right: 0;
        margin-right: 0;
        padding-right: 0;
    }
    [id*=mobile-language-selector] {
        /* 768px breakpoint*/
        display: inline;
        padding: 2%;
        width: 70%;
        float: right;
    }
    .banner {
        /* 768px breakpoint*/
        display: none;
    }
    .third-box.community.fr .prominent-link.black {
        /* 768px breakpoint*/
        margin-top: 145px;
    }
    [id*=article-print-email-box] .prominent-link.inversed {
        /* 768px breakpoint*/
        margin-bottom: 26px;
        float: none;
    }
    [id*=article-print-email-box] .right {
        /* 768px breakpoint*/
        float: none;
    }
    [id*=article-print-email-box] {
        /* 768px breakpoint*/
        text-align: center;
    }
    .link-wrap.home {
        /* 768px breakpoint */
        padding: 30px 0 30px 0;
    }
    .link-wrap.home .homepage-link {
        /* 768px breakpoint */
        display: block;
        margin: 0 auto 20px auto;
    }
    .link-wrap.home .separator {
        /* 768px breakpoint */
        display: none;
    }
    textarea.full-width,
    input[type="text"].full-width {
        /* 768px breakpoint */
        width: 94% !important;
        padding: 3%;
    }
    .three-boxes .box .inner {
        /* 768px breakpoint */
        height: 260px;
    }
    .three-boxes .box .inner p {
        /* 768px breakpoint */
        height: 100px;
    }
    .three-boxes.pl.not-found .box .inner h2 {
        /* 768px breakpoint */
        font-size: 18px;
    }
    .three-boxes .box {
        /* 768px breakpoint */
        width: 50%;
        margin: 0 auto;
        float: none;
        margin-bottom: 15px;
    }
    .article-side-box {
        /* 768px breakpoint */
        display: none;
    }
    .pts-home .inner {
        /* 768px breakpoint */
        width: 92%;
        padding: 4%;
    }
    .pts-home {
        /* 768px breakpoint */
        background: #f3f3f3;
    }
    #avg-go-refund-note {
        /* 768px breakpoint */
        width: 100%;
    }
    .sq-badge.pl .outer-consumer-fields .inner-consumer-fields h2,
    .sq-badge.de .outer-consumer-fields .inner-consumer-fields h2,
    .sq-badge.fr .outer-consumer-fields .inner-consumer-fields h2,
    .sq-badge.it .outer-consumer-fields .inner-consumer-fields h2,
    .sq-badge.pt_BR .outer-consumer-fields .inner-consumer-fields h2 {
        /* 768px breakpoint */
        font-size: 18px;
    }
    .sq-badge.home.sales {
        /* 768px breakpoint */
        width: 68%;
        padding: 0px 1% 0px 1%;
        margin-right: 0%;
        margin-left: 30%;
    }
    .sq-badge.home.partner {
        /* 768px breakpoint */
        width: 68%;
        border: none;
        padding: 0px 1% 0px 1%;
        margin-right: 0%;
        margin-left: 30%;
    }
    .sq-badge.home.business {
        /* 768px breakpoint */
        width: 68%;
        padding: 0px 1% 0px 1%;
        margin-right: 0%;
        margin-left: 30%;
    }
    .sq-badge.home.dsr {
        /* 768px breakpoint */
        width: 68%;
        padding: 0px 1% 0px 1%;
        border: none;
        margin-right: 0%;
        margin-left: 30%;
    }
    .content-nav,
    .content-nav-business {
        /* 768px breakpoint */
        margin: 0px auto;
        text-align: center;
    }
    .content-nav div,
    .content-nav-business div {
        /* 768px breakpoint */
        margin: 0px auto;
        text-align: center;
        float: none !important;
    }
    .content-nav .prominent-link,
    .content-nav-business .prominent-link {
        /* 768px breakpoint */
        float: none !important;
    }
    .badge.cloud-care h2,
    .badge.managed-workplace h2,
    .badge.single-sing-on h2 {
        /* 768px breakpoint */
        color: #0072D1;
    }
    .badge.consumer-supp h2,
    .badge.partner-supp h2,
    .badge.business-supp h2 {
        /* 768px breakpoint */
        color: #0072D1;
    }
    .badge-homepage .badge-container.tripplecontainer {
        /* 768px breakpoint */
        width: 100%;
        margin: 0 0 0 0;
    }
    dl.business {
        /* 768px breakpoint */
        display: block;
        width: 100%;
    }
    dl.business dt {
        /* 768px breakpoint */
        display: block;
        width: 100%;
        line-height: 15px;
    }
    dl.business dt .dif-text.outer {
        height: 20px;
    }
    dl.business dd {
        /* 768px breakpoint */
        display: block;
        width: 100%;
    }
    dl.business textarea,
    dl.business input[type="text"] {
        /* 768px breakpoint */
        width: 95%;
    }
    dl.business select {
        /* 768px breakpoint */
        width: 100%;
    }
    dl.business .contact-radio-box .inner {
        /* 768px breakpoint */
        width: 89%;
    }

    div[id*="formContentComponents"] {
        padding: 0;
    }

    .newForm .alert-box {
        /* 768px breakpoint */
        width: 96%;
        padding-left: 2%;
        padding-right: 2%;
    }

    .newForm.consumer .alert-box
    {
        /* 768px breakpoint */
        padding-left: 3%;
        padding-right: 2%;
        width: 94%;
    }

    .newForm dl.business dt {
        /* 768px breakpoint */
        text-align: left;
    }

    .newForm.survey dl dd, .newForm.survey dl dd {
        width: 100%;
        padding: 0;
    }

    .newForm.survey dl.business dt {
        text-align: center;
        margin: 20px 0 10px;
    }

    .newForm.survey .optionsFieldOneFrontend.survey-box.vertical.yes-no {
        text-align: center;
        width: 100%;
    }

    .newForm dl.business input[type="text"], .newForm dl.business textarea {
        /* 768px breakpoint */
        width: 95% !important;
    }

    .newForm input[type="submit"], .newForm input[type="button"] {
        /* 768px breakpoint */
        width: 98% !important;
        margin-left: 1%;
        margin-right: 1%;
    }


    .newForm dl.business select {
        /* 768px breakpoint */
        width: 98% !important;
    }


    .newForm dl.business dd .error-one {
        /* 768px breakpoint */
        display: block;
        padding-bottom: 15px;
    }

    .newForm  dl.business select {
        /* 768px breakpoint */
        width: 100%;
    }

    .newForm dl.business dt .dif-text.outer {
        /* 768px breakpoint */
        margin-top: 0px;
        height: auto;
    }

    .newForm dl.business dt .dif-text.inner {
        /* 768px breakpoint */
        margin-right: 2px;
    }

    .newForm .attachments .add-button {
        /* 768px breakpoint */
        width: 94%;
    }

    .newForm .attachments .attached-item {
        /* 768px breakpoint */
        width: 94%;
    }

    .newForm .attachments .loading-bar {
        /* 768px breakpoint */
        width: 78%;
    }

    .newForm .attachments .error-message {
        /* 768px breakpoint */
        width:  81%;
        padding: 11px 2% 11px 15%;
    }

    .newForm #selectorHoverable,
    .newForm #selectorOslistHoverable,
    .newForm #selectorProdlistHoverable,
    .newForm #selectorUserlistHoverable,
    .newForm #selectorRequestlistHoverable,
    .newForm #selectorLocationlistHoverable,
    .newForm .selectorListHoverable {
        /* 768px breakpoint */
        width: 99%;
        background-position: right 10px center;
        background-position-x: 96%;
        background-position-y: center;
        position: relative;
        margin: 0;
    }

    .newForm .selectorHoverable.wide {
        width: 640px !important;
    }

    .newForm .wide {
        width: 640px !important;
    }

    .newForm #selector.highlight,
    .newForm #selectorOslist.highlight,
    .newForm #selectorProdlist.highlight,
    .newForm #selectorUserlist.highlight,
    .newForm #selectorRequestlist.highlight,
    .newForm #selectorLocationlist.highlight,
    .newForm .selectorList.highlight {
        /* 768px breakpoint */
        display: none;
    }

    .newForm #selector,
    .newForm #selectorOslist,
    .newForm #selectorProdlist,
    .newForm #selectorUserlist,
    .newForm #selectorRequestlist,
    .newForm #selectorLocationlist,
    .newForm .selectorList {
        /* 768px breakpoint */
        width: 99%;
    }

    .newForm #selector.highlight ul li:first-child:hover, .newForm #selector.highlight ul li:first-child,
    .newForm #selectorHoverable.highlight button:first-child:hover, .newForm #selectorHoverable.highlight button:first-child,
    .newForm #selectorOslistHoverable.highlight button:first-child:hover, .newForm #selectorOslistHoverable.highlight button:first-child,
    .newForm #selectorProdlistHoverable.highlight button:first-child:hover, .newForm #selectorProdlist.highlight button:first-child,
    .newForm #selectorUserlistHoverable.highlight button:first-child:hover, .newForm #selectorUserlistHoverable.highlight button:first-child,
    .newForm #selectorRequestlistHoverable.highlight button:first-child:hover, .newForm #selectorRequestlistHoverable.highlight button:first-child,
    .newForm #selectorLocationlistHoverable.highlight button:first-child:hover, .newForm #selectorLocationlistHoverable.highlight button:first-child,
    .newForm .selectorListHoverable.highlight button:first-child:hover, .newForm .selectorListHoverable.highlight button:first-child {
        /* 768px breakpoint */
        background-position: right 10px center;
        background-position-x: right 10px;
        background-position-y: center;
    }

    .newForm .selector-clean-place {
        min-height: 10px;
        /* 768px breakpoint */
    }

    dl.consumer {
        /* 768px breakpoint */
        display: block;
        width: 100%;
    }
    dl.consumer dt {
        /* 768px breakpoint */
        display: block;
        width: 100%;
        line-height: 15px;
    }
    dl.consumer dt .dif-text.outer {
        height: 20px;
    }
    dl.consumer dd {
        /* 768px breakpoint */
        display: block;
        width: 100%;
    }
    dl.consumer textarea,
    dl.consumer input[type="text"] {
        /* 768px breakpoint */
        width: 95%;
    }
    dl.consumer select {
        /* 768px breakpoint */
        width: 100%;
    }
    dl.consumer .contact-radio-box .inner {
        /* 768px breakpoint */
        width: 89%;
    }
    dl.consumer .channel-text {
        /* 768px breakpoint */
        width: 440px;
    }
    dl.email {
        /* 768px breakpoint */
        display: block;
        width: 100%;
    }
    dl.email dt {
        /* 768px breakpoint */
        display: block;
        width: 100%;
        line-height: 15px;
    }
    dl.email dd {
        /* 768px breakpoint */
        display: block;
        width: 100%;
    }
    dl.email input[type="text"] {
        /* 768px breakpoint */
        width: 95%;
    }
    dl.email textarea {
        /* 768px breakpoint */
        width: 95%;
    }
    .third-box.business-community.first,
    .third-box.business-community.last,
    .third-box.business-community.picture {
        /* 768px breakpoint */
        width: 97%;
        padding: 15px 1.2%;
    }
    .third-box.business-community.first h2,
    .third-box.business-community.last h2 {
        /* 768px breakpoint */
        text-align: center;
    }
    .third-box.business-community.picture {
        /* 768px breakpoint */
        border-left: none;
        border-right: none;
        border-top: 1px solid #dcdcdc;
        border-bottom: 1px solid #dcdcdc;
        min-height: 190px;
        background: url('../img/lifestyle/consumer-support-avg-support-community.png') no-repeat top 80px center;
        width: 97%;
        padding: 15px 1.2%;
        float: left;
    }
    .third-box.business-community.picture.pt_BR,
    .third-box.business-community.picture.es,
    .third-box.business-community.picture.fr {
        /* 768px breakpoint */
        background: url('../img/lifestyle/consumer-support-avg-support-community.png') no-repeat top 80px center;
        min-height: 190px;
    }
    .third-box.business-community.with-center-part.first {
        /* 768px breakpoint */
        width: 97%;
        padding: 15px 1.2%;
    }
    .third-box.business-community.with-center-part.last {
        /* 768px breakpoint */
        width: 97%;
        padding: 15px 1.2%;
    }
    .cform-col.contact {
        width: 100%;
        /* 768px breakpoint */
    }
    .cform-col.contact.narrow {
        /* 768px breakpoint */
        width: 60%;
    }
    .support-switcher {
        /* 768px breakpoint */
        min-width: auto;
        padding-left: 10px;
        padding-right: 10px;
    }

    .newForm #select-option #y-n-buttons-wrap {
        /* 768px breakpoint */
        height: 40px;
        text-align: center;
    }

    .newForm #select-option .y-n-radio-like {
        /* 768px breakpoint */
        width: 50px;
        height: 40px;
        background: url('../img/icons/input-radio-x2.png') no-repeat left center;
    }

    .newForm #select-option .y-n-radio-like:hover,
    .newForm #select-option .y-n-radio-like.sel,
    .newForm #select-option button:hover i {
        /* 768px breakpoint */
        background: url('../img/icons/input-radio-checked-x2.png') no-repeat left center;
    }

    .newForm #select-option button {
        /* 768px breakpoint */
        font-size: 25px;
        line-height: 40px;
    }

    .newForm #selectorHoverable.highlight button:nth-child(2):hover, .newForm #selectorHoverable.highlight button:nth-child(2),
    .newForm #selectorOslistHoverable.highlight button:nth-child(2):hover, .newForm #selectorOslistHoverable.highlight button:nth-child(2),
    .newForm #selectorProdlistHoverable.highlight button:nth-child(2):hover, .newForm #selectorProdlistHoverable.highlight button:nth-child(2),
    .newForm #selectorUserlistHoverable.highlight button:nth-child(2):hover, .newForm #selectorUserlistHoverable.highlight button:nth-child(2),
    .newForm #selectorRequestlistHoverable.highlight button:nth-child(2):hover, .newForm #selectorRequestlistHoverable.highlight button:nth-child(2),
    .newForm #selectorLocationlistHoverable.highlight button:nth-child(2):hover, .newForm #selectorLocationlistHoverable.highlight button:nth-child(2),
    .newForm #selectorTermTypeListHoverable.highlight button:first-child:hover, .newForm #selectorTermTypeListHoverable.highlight button:first-child,
    .newForm #selectorTermDateListHoverable.highlight button:first-child:hover, .newForm #selectorTermDateListHoverable.highlight button:first-child,
    .newForm #selectorUnsubReasonListHoverable.highlight button:nth-child(2):hover, .newForm #selectorUnsubReasonListHoverable.highlight button:nth-child(2), {
        /* 768px breakpoint */
        background-position: right 10px center;
    }
    /* product crossroads */
    div.crossroad-two-divs, div.crossroad-three-divs {
        /* 768px breakpoint */
        display: block;
    }
    div.crossroad-two-divs div, div.crossroad-three-divs div {
        /* 768px breakpoint */
        width: 100%;
        padding: 0px 5px 0 0px;
        margin-bottom: 40px;
    }
    div.crossroad-two-divs div:last-child, div.crossroad-three-divs div:last-child {
        /* 768px breakpoint */
        margin-bottom: 0px;
    }
    div.crossroad-two-divs div h2, div.crossroad-three-divs div h2 {
        /* 768px breakpoint */
        min-height: 0px;
    }
    div.crossroad-three-divs .button-business, div.crossroad-three-divs .button-business-invert,
    div.crossroad-two-divs .button-business, div.crossroad-two-divs .button-business-invert {
        /* 768px breakpoint */
        margin-top: 50px;
    }
    div.crossroad-two-divs div a h2, div.crossroad-two-divs div a span, div.crossroad-three-divs div a h2, div.crossroad-three-divs div a span  {
        white-space: pre-line;
        margin-left: 0;
        margin-right: 0;
    }
    .footnote p a.toBlock {
        display: block;
    }

    .footnote p span.toHide {
        display: none;
    }

    .newForm.consumer.sales dl.business {
        /* 768px breakpoint */
        width: 100%;

    }

    #articleview #articlePanel img {
        /* 768px breakpoint */
        max-width: 100% !important;
        height: auto !important;
    }

    .nl_NL h1.homepage-headline {
        /* 768px breakpoint */
        font-size: 22px;
    }

    .nl_NL  h1.homepage-headline span.homepage-subheadline {
        /* 768px breakpoint */
        font-size: 16px;
    }

    .prod-icon.small.before-headline {
        /* 768px breakpoint */
        float: none !important;
        margin: 0px auto !important;
    }

    h1.product {
        /* 768px breakpoint */
        text-align: center;
        padding-left: 0px;
    }

    .product-page-contact-section h2 {
        margin: 0 2.5%;

    }
}

@media screen and (max-width: 700px) {
    #menu .nav-main-link:hover ul {
        /* 700px breakpoint */
        display: none
    }
    #avg-go-refund-note .alert-icon-small {
        /* 700px breakpoint */
        display: none;
    }
    div.survey-box.horizontal p.fieldset-range {
        /* 700px breakpoint */
        margin: 25px 4px 25px 4px;
    }
    .survey-box.vertical tr {
        /* 700px breakpoint */
        line-height: 3em;
    }
    .survey-box.horizontal label {
        /* 700px breakpoint */
        margin: 0 2px;
    }
}

@media screen and (max-width: 680px) {
    [id*=header] div.right.with-search {
        /* 680px breakpoint */
        width: 90%;
    }
    [id*=header] .integrated-search input[type="text"] {
        /* 680px breakpoint */
        width: 75%;
    }
    [id*=header] h2 {
        /* 680px breakpoint */
        border: 0;
        padding: 0;
        float: none;
        text-align: center;
        margin-top: 70px;
    }
    [id*=header] h2.pl,
    [id*=header] h2.nl_NL {
        /* 680px breakpoint */
        font-size: 20px;
    }

    [id*=header].plain a.logo {
        padding: 0 35px 0 0;
        margin: 20px 0 15px 0;
    }

    [id*=header].plain h2 {
        margin-top: 0px;
        padding-top: 25px;
    }

    .homepage-header-wrap .wrapper {
        /* 680px breakpoint */
        padding-top: 80px;
    }
    .homepage-header-wrap.business-background {
        /* 680px breakpoint */
        background-position: -260px center;
    }
    #result-box-overlay-loading,
    #result-box-community-overlay-loading {
        width: 100%;
    }
    .button-business,
    .button-business-invert {
        /* 680px breakpoint */
        width: 280px;
    }
    dl.consumer .channel-text {
        /* 680px breakpoint */
        width: 370px;
    }
    #techButton.wide {
        /* 680px breakpoint */
        width: 205px !important;
    }
}


/* special breakpoint for chat window*/

@media screen and (max-width: 641px) {
    html.contact-form.chat [id*=header] {
        /* 641px breakpoint */
        display: none;
    }
    html.contact-form.chat .page-container + div.wrapper,
    html.contact-form.chat [id*=footer],
    html.contact-form.chat .push-footer-to-bottom {
        /* 641px breakpoint */
        display: none;
    }
    html.contact-form.chat div#chat-frame-wrapper {
        /* 641px breakpoint */
        position: fixed !important;
        width: 100% !important;
        height: 100% !important;
    }
    html.contact-form.chat div#chat-frame-wrapper > iframe {
        /* 641px breakpoint */
        display: block !important;
        width: 100% !important;
        height: 100% !important;
        border: none !important;
    }
    html.contact-form.chat.sales iframe {
        /* 641px breakpoint */
        display: block !important;
        width: 100% !important;
        height: 100% !important;
        border: none !important;
    }
    html.contact-form.chat iframe#chatWindow,
    html.contact-form.chat div#chatWindowPlaceholder {
        /* 641px breakpoint */
        min-height: 10px !important;
        display: block;
    }
    html.contact-form.chat div#content {
        /* 641px breakpoint */
        margin-top: 0 !important;
    }
    html.contact-form.chat.sales div#chatWindowPlaceholder {
        /* 641px breakpoint */
        min-height: 100% !important;
        display: block;
    }
    html.contact-form.chat.sales .make-chat-full-height {
        /* 641px breakpoint */
        min-height: 100% !important;
        display: block;
        position: fixed;
        top: 0;
        bottom: 0;
        overflow: auto;
    }
    html.contact-form.chat.sales .make-chat-full-height-2 {
        /* 641px breakpoint */
        display: block;
        height: 100%;
        position: fixed;
        width: 100%;
    }

    .newForm.consumer.sales .wrapper {
        width: 100%;
    }
}

@media screen and (max-width: 640px) {
    a.sq-badge {
        /* 640px breakpoint */
        display: block;
        float: none;
        margin: 0px auto 20px;
        width: 49%;
    }
    a.sq-badge.home {
        /* 640px breakpoint */
        margin-bottom: 0px;
    }
    h1.large {
        /* 640px breakpoint */
        font-size: 30px;
    }
    .survey-box textarea.half-width {
        /* 640px breakpoint */
        width: 96%;
        margin-left: 0;
        margin-right: 0;
        padding: 2%;
    }
    div.survey-box.horizontal fieldset table tr,
    div.survey-box.horizontal p.fieldset-range {
        /* 640px breakpoint */
        width: 100%;
        padding: 0;
        margin: 0;
    }
    .survey-box.vertical tr {
        /* 640px breakpoint */
        line-height: 3em;
    }
    div.survey-box.horizontal fieldset table td {
        /* 640px breakpoint */
        display: table-row;
    }
    .devices-wrapper .devices-logo,
    .devices-wrapper .devices-en_us,
    .devices-wrapper .devices-de,
    .devices-wrapper .devices-cs,
    .devices-wrapper .devices-fr,
    .devices-wrapper .devices-it,
    .devices-wrapper .devices-nl_nl,
    .devices-wrapper .devices-pl,
    .devices-wrapper .devices-es,
    .devices-wrapper .devices-pt_br {
        /* 640px breakpoint */
        width: 95%;
    }
    .devices-wrapper .devices-logo,
    .devices-wrapper .devices-text-en_us,
    .devices-wrapper .devices-text-de,
    .devices-wrapper .devices-text-cs,
    .devices-wrapper .devices-text-fr,
    .devices-wrapper .devices-text-it,
    .devices-wrapper .devices-text-nl_nl,
    .devices-wrapper .devices-text-pl,
    .devices-wrapper .devices-text-es,
    .devices-wrapper .devices-text-pt_br {
        /* 640px breakpoint */
        width: 95%;
    }
    .contact-us-wrapper .contact-us-logo,
    .contact-us-wrapper .contact-us-text,
    .contact-us-wrapper .contact-us-not {
        /* 640px breakpoint */
        width: 100%;
    }
    #business-article-selector {
        /* 640px breakpoint */
        width: 85%;
        background-position: right 10px center;
        background-position-x: 96%;
        background-position-y: center;
    }

    .button-business,
    .button-business-invert {
        /* 640px breakpoint */
        width: 250px;
    }
    html.chat.sales #chatWindowPlaceholder + div {
        /* 640px breakpoint */
        height: 100% !important;
    }
    .sales-chat-wrapper {
        /* 640px breakpoint */
        border: none !important;
        border-radius: 0px !important;
        width: 100%;
    }
    .support-chat-inner-wrapper {
        width: 90%;
    }
    .sales-chat-windows {
        height: 100%;
    }
    dl.chat-login dt .dif-text.outer {
        /* 640px breakpoint */
        height: auto;
    }
    dl.chat-login dd input {
        /* 640px breakpoint */
        width: 100%;
    }
    dl.chat-login dt {
        /* 640px breakpoint */
        display: block;
        width: 100%;
        /* IE7 fix*/
        zoom: 1;
        *display: inline;
    }
    dl.chat-login dd {
        /* 640px breakpoint */
        display: block;
        width: 100%;
        float: none;
    }

    .nl_NL h1.homepage-headline {
        /* 640px breakpoint */
        font-size: 18px;
    }

    .nl_NL  h1.homepage-headline span.homepage-subheadline {
        /* 640px breakpoint */
        font-size: 14px;
    }

    .switcher-wrapper {
        /* 640px breakpoint */
        margin-bottom: 80px !important;
    }

    .switcher-wrapper.en {
        /* 640px breakpoint */
        margin-bottom: 45px !important;
    }
}

@media screen and (max-width: 560px) {
    .button-business,
    .button-business-invert {
        /* 560px breakpoint */
        width: 210px;
    }
    dl.consumer .channel-text {
        /* 560px breakpoint */
        width: 300px;
    }
    .article-lg-icon .left.wrap {
        /* 560px breakpoint */
        width: 76%;
    }
}

@media screen and (max-width: 480px) {
    div.homepage div.homepage-search-wrapper input.textBox {
        /* 480px breakpoint */
        padding-left: 5%;
        width: 90%;
    }
    .homepage-header-wrap.business-background {
        /* 480px breakpoint */
        background-position: -180px center;
    }
    .homepage-header-wrap.consumer-background {
        /* 480px breakpoint */
        background-position: -260px center;
    }
    .homepage-header-wrap.crossroad-background {
        /* 480px breakpoint */
        background-position: right center;
    }
    .homepage-header-wrap.crossroad-background .display-table.half-width {
        /* 480px breakpoint */
        width: 100%;
    }
    .wrapper {
        /* 480px breakpoint */
        width: 96%;
        margin: 0 auto;
        padding: 0;
    }
    .homepage-header-wrap .wrapper {
        /* 480px breakpoint */
        padding-top: 70px;
    }
    h1.homepage-headline {
        /* 480px breakpoint */
        width: 100%;
    }
    .nl_NL h1.homepage-headline,
    .de h1.homepage-headline {
        /* 480px breakpoint */
        word-break: break-all;
    }
    .homepage-header-wrap.consumer-background.pl .wrapper h1,
    .homepage-header-wrap.partners-background.pl .wrapper h1,
    .homepage-header-wrap.business-background.pl .wrapper h1 {
        /* 480px breakpoint */
        font-size: 36px;
    }

    form.homepage input[type="text"],
    form.homepage select,
    .homepage input[type="text"],
    .homepage select {
        /* 480px breakpoint */
        width: 65%;
    }
    .badge-homepage .badge-container {
        /* 480px breakpoint */
        width: 80% !important;
        margin: 0px auto !important;
        float: none;
        padding-bottom: 0px;
    }
    .badge-homepage .badge-container.business h2 {
        /* 480px breakpoint */
        color: #0072D1;
    }
    .badge-homepage .badge-container ul.underlined {
        /* 480px breakpoint */
        padding-left: 0px;
        padding-right: 0px;
    }
    .no-right-margin {
        /* 480px breakpoint */
        margin-right: 10%;
        margin-left: 10%;
    }
    .badge-homepage .no-right-margin {
        /* 480px breakpoint */
        margin-right: 10% !important;
        margin-left: 10% !important;
    }
    .badge-homepage .badge-container ul.underlined {
        /* 480px breakpoint */
        min-height: 100px;
    }
    .left.sq-badge,
    .right.sq-badge {
        /* 480px breakpoint */
        float: none;
        margin: 0 auto 20px auto;
    }
    .sq-badge {
        /* 480px breakpoint */
        width: 50%;
    }
    .sq-badge.fr .outer-consumer-fields .inner-consumer-fields h2,
    .sq-badge.de .outer-consumer-fields .inner-consumer-fields h2 {
        /* 480px breakpoint */
        font-size: 15px;
    }
    .sq-badge.pl .outer-consumer-fields .inner-consumer-fields h2 {
        /* 480px breakpoint */
        font-size: 13px;
    }
    .sq-badge.home.sales {
        /* 480px breakpoint */
        width: 92%;
        padding: 0px 2% 0px 2%;
        margin-right: 2%;
        margin-left: 2%;
    }
    .badge-homepage .badge-container {
        /* 480px breakpoint */
        min-height: 0 !important;
    }
    .sq-badge.home.partner {
        /* 480px breakpoint */
        width: 92%;
        border: none;
        padding: 0px 2% 0px 2%;
        margin-right: 2%;
        margin-left: 2%;
    }
    .sq-badge.home.business {
        /* 480px breakpoint */
        width: 92%;
        padding: 0px 2% 0px 2%;
        margin-right: 2%;
        margin-left: 2%;
    }
    .sq-badge.home.dsr {
        /* 480px breakpoint */
        width: 92%;
        padding: 0px 2% 0px 2%;
        margin-right: 2%;
        margin-left: 2%;
    }
    .third-box {
        /* 480px breakpoint */
        float: none;
        margin: 0 auto;
        width: 100%;
    }
    .third-box.community {
        /* 480px breakpoint */
        width: 300px;
        padding: 15px 7px;
        border-top: 1px solid #DCDCDC;
    }
    ul.product-list li, ul.product-list li.wider {
        /* 480px breakpoint */
        padding: 20px 3% 0 3%;
        width: 43%;
        margin-right: 1%;
        margin-bottom: 10px;
    }
    .find-related-help-stripe {
        /* 480px breakpoint */
        text-align: center;
    }
    .support-type-buttons {
        /* 480px breakpoint */
        width: 80%;
        margin: 0 auto;
    }
    div.support-type-buttons a.button.inverted,
    div.support-type-buttons a.button.stype,
    #techButton.wide {
        /* 480px breakpoint */
        width: 100% !important;
        margin-bottom: 10px;
        margin-top: 10px;
    }
    div.support-type-buttons.business a.button.stype,
    div.support-type-buttons.partner a.button.stype {
        /* 480px breakpoint */
        width: 80%;
    }
    div.support-type-buttons.business,
    div.support-type-buttons.partner {
        /* 480px breakpoint */
        width: 100%;
    }
    div.support-type-buttons.business a.button.stype,
    div.support-type-buttons.partner a.button.stype {
        /* 480px breakpoint */
        margin: 0;
    }
    div[id*=footer] div.box {
        /* 480px breakpoint */
        min-height: 200px;
        padding: 0 2%;
        width: 46%;
    }
    [id*=feedbackpanel] h3.larger {
        /* 480px breakpoint */
        width: 100%;
    }
    #y-n-buttons-wrap {
        /* 480px breakpoint */
        float: left;
        margin-top: 1em;
    }
    [id*=phonesupportcontent] .every-padding-15px {
        /* 480px breakpoint */
        padding-left: 0;
        padding-right: 0;
    }
    [id*=phonesupportcontent] td.left-cell {
        /* 480px breakpoint */
        width: 39%;
        padding-right: 1%;
        font-size: 0.9em;
        height: 3em;
    }
    [id*=phonesupportcontent] td.right-cell {
        /* 480px breakpoint */
        font-size: 0.9em;
    }
    .link-wrap.single {
        /* 480px breakpoint */
        padding: 35px 0 35px 0 !important;
    }
    .prominent-link.business {
        /* 480px breakpoint */
        margin-right: 0;
        margin-bottom: 20px;
    }
    .lg-icon {
        /* 480px breakpoint */
        width: 14.4%;
    }
    .article-lg-icon .left.wrap {
        /* 480px breakpoint */
        width: 68%;
    }
    div.end-of-internet-cover {
        /* 480px breakpoint */
        padding-top: 25px;
        padding-bottom: 25px;
    }
    .three-boxes .box {
        /* 480px breakpoint */
        width: 90%;
    }
    div.end-of-internet-cover.de h1.large {
        /* 480px breakpoint */
        padding-bottom: 0px;
    }
    div.end-of-internet-cover h1 {
        /* 480px breakpoint */
        margin-top: 15px;
    }
    div.end-of-internet-cover p {
        /* 480px breakpoint */
        font-size: 16px;
    }
    .three-boxes.nl_NL.not-found .box .inner h2 {
        /* 480px breakpoint */
        word-break: break-all;
    }
    .three-boxes.pl.not-found .box.second .inner p {
        /* 480px breakpoint */
        height: 120px;
    }
    [id*=articleBanner] p {
        /* 480px breakpoint */
        width: 100%;
    }
    .pts-home h2 {
        /* 480px breakpoint */
        font-size: 28px;
    }
    .pts-home p {
        /* 480px breakpoint */
        font-size: 16px;
    }
    .contact-radio-box .inner {
        /* 480px breakpoint */
        width: 70%;
    }
    .contact-radio-box {
        /* 480px breakpoint */
        height: 160px;
    }
    .contact-radio-box .inner .channel-icon {
        /* 480px breakpoint */
        margin-bottom: 10px;
    }
    .contact-radio-box .inner.email .channel-icon {
        /* 480px breakpoint */
        margin-bottom: 0;
    }
    .sq-badge.home {
        /* 480px breakpoint */
        width: 70%;
        min-height: 80px;
        padding: 25px 0px 0px 30%;
        border: none;
    }
    .business-underlined,
    .business-underlined-last-item {
        /* 480px breakpoint */
        display: none;
    }
    .badge.home-multi-device h2,
    .badge.home-win h2,
    .badge.home-android h2,
    .badge.home-mac h2,
    .badge.home-pts h2 {
        /* 480px breakpoint */
        padding-top: 0px;
        height: auto;
        margin-bottom: 20px;
        margin-top: 0px;
    }
    .badge.tu-be-products h2 {}
    .badge.cloud-care h2,
    .badge.managed-workplace h2,
    .badge.single-sing-on h2 {
        /* 480px breakpoint */
        padding-top: 0px;
        height: auto;
        margin-bottom: 20px;
    }
    .badge.consumer-supp h2,
    .badge.partner-supp h2,
    .badge.business-supp h2 {
        /* 480px breakpoint */
        padding-top: 0px;
        height: auto;
        margin-bottom: 20px;
    }
    .badge.antivirus-be-products h2,
    .badge.is-products h2,
    .badge.fs-products h2,
    .badge.tu-be-products h2 {
        /* 480px breakpoint */
        padding-top: 60px;
        height: auto;
        margin-bottom: 20px;
    }
    dl.business .channel-text {
        /* 480px breakpoint */
        width: 140px;
    }
    dl.business .contact-radio-box .inner {
        /* 480px breakpoint */
        width: 80%;
    }
    dl.business dd input.cform[type="button"],
    input.cform[type="submit"],
    a.button.cform {
        /* 480px breakpoint */
        width: 100%;
    }

    .newForm dl.business textarea,
    .newForm dl.business input[type="text"] {
        /* 480px breakpoint */
        width: 95% !important;
    }

    .newForm dl.business select {
        /* 480px breakpoint */
        width: 100% !important;
    }

    dl.business .contact-radio-box {
        /* 480px breakpoint */
        height: 100px;
    }

    dl.consumer .channel-text {
        /* 480px breakpoint */
        width: 200px;
    }
    dl.consumer .contact-radio-box .inner {
        /* 480px breakpoint */
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
    }

    .contact-radio-box button > span {
        /* 480px breakpoint */
        margin: 0px auto 5px auto !important;
        display: block !important;
        float: none;
    }

    .contact-radio-box button > span.channel-text {
        /* 480px breakpoint */
        width: 100%;
    }

    .contact-radio-box button > br {
        /* 480px breakpoint */
        display: none;
    }

    dl.consumer dd input.cform[type="button"],
    input.cform[type="submit"],
    a.button.cform {
        /* 480px breakpoint */
        width: 100%;
    }
    dl.consumer .contact-radio-box {
        /* 480px breakpoint */
        height: 100px;
    }
    .button-business {
        /* 480px breakpoint */
        width: 94% !important;
        margin: 20px 3% 0px 3%;
        position: relative;
    }
    .button-business-invert {
        /* 480px breakpoint */
        width: 94% !important;
        margin: 20px 3% 0px 3%;
    }
    dl.business-phone dd,
    dl.business-phone dt {
        /* 480px breakpoint */
        margin: 0px 0px 5px 0px;
        width: 100%;
    }

    dl.business-phone dd {
        /* 480px breakpoint */
        margin-bottom: 15px;
    }
    .switcher-wrapper {
        /* 480px breakpoint */
        text-align: center;
    }
    .support-switcher {
        /* 480px breakpoint */
        display: inline-block;
        float: none;
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;
        width: auto;
        min-width: auto;
    }
    #select-option #y-n-buttons-wrap {
        /* 480px breakpoint */
        float: none;
        height: 24px;
        margin: 0 auto !important;
        text-align: center;
    }
    h1.contact {
        /* 480px breakpoint */
        text-align: center;
    }
    .cform-col.contact.narrow {
        /* 480px breakpoint */
        width: 100%;
    }
    .newForm #select-option button {
        /* 480px breakpoint */
        margin: 0;
        float: left;
        margin-top: 10px;
    }
    .newForm #select-option button.secondOne {
        /* 480px breakpoint */
        float: right;
        margin-left: 10px;

    }
    .switcher-wrapper {
        /* 480px breakpoint */
        border: none !important;
        margin-bottom: 80px !important;
    }
    .switcher-wrapper .support-switcher {
        /* 480px breakpoint */
        border-bottom: 1px solid #dcdcdc !important;
        margin: 4px !important;
    }
    .nl_NL h1.homepage-headline {
        /* 480px breakpoint */
        font-size: 22px;
    }
    .nl_NL  h1.homepage-headline span.homepage-subheadline {
        /* 480px breakpoint */
        font-size: 18px;
    }

    [id*=header].plain h2 {
        /* 480px breakpoint */
        margin-top: 0px;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .question-scale.scale-0-5 > label {
        /* 480px breakpoint */
        margin: 0 0px !important;
    }

    .question-scale.scale-0-5 > .desc {
        /* 480px breakpoint */
        width: 100% !important;
    }

    .question-form .question-open textarea {
        /* 480px breakpoint */
        width: 94% !important;
    }

    .devices-wrapper.pts.number div.content {
        /* 480px breakpoint */
        background-image: url('../img/pts/devices_min.png');
        background-position: center top 30px;
        text-align: center;
        background-size: 75px 56.25px;
        padding: 90px 30px 30px 30px;
    }

    .devices-wrapper.pts.number span.note {
        /* 480px breakpoint */
        margin: 15px auto 0 auto;
        max-width: 100%;
    }

    .switcher-wrapper .support-switcher {
        /* 480px breakpoint */
        padding-left: 3px;
        padding-right: 3px;
        font-size: 16px;
        margin: 2px !important;
    }
}

@media screen and (max-width: 400px) {
    dl.consumer .channel-text {
        /* 400px breakpoint */
        width: 160px;
    }
}

@media screen and (max-width: 380px) {
    ul.product-list li, ul.product-list li.wider {
        /* 380px breakpoint */
        padding: 20px 3% 20px 3%;
    }
}

@media screen and (max-width: 360px) {
    dl.consumer .channel-text {
        /* 360px breakpoint */
        width: 120px;
    }
    .third-box.business-community.picture.pt_BR,
    .third-box.business-community.picture.es,
    .third-box.business-community.picture.fr {
        /* 360px breakpoint */
        background: url('../img/lifestyle/consumer-support-avg-support-community.png') no-repeat top 120px center;
        min-height: 220px;
    }
    .homepage-header-wrap.crossroad-background {
        /* 360px breakpoint */
        background-position: -540px center;
    }
}

@media (max-width: 352px){
    #lang-overlay
    {
        /* 352px breakpoint */
        /**make #lang-overlay responsive**/
        box-sizing: border-box;
        width:100%;
        height: 392px;
        left:0;
        margin-left: 0;
    }
}

@media screen and (max-width: 340px) {
    [id*="mobile-language-selector"] {
        /* 340px breakpoint */
        width: 60%;
    }
    div.support-type-buttons.business a.button.stype,
    div.support-type-buttons.partner a.button.stype {
        /* 340px breakpoint */
        width: 100%;
    }
    [id*=submitSelectedButton],
    [id*=emailButton],
    [id*=chatButton],
    [id*=phoneButton] {
        /* 340px breakpoint */
        width: 100% !important;
    }
}

@media screen and (max-width: 320px) {
    .homepage-header-wrap .wrapper {
        /* 320px breakpoint */
        padding-top: 60px;
    }
    .article-lg-icon .left.wrap {
        /* 560px breakpoint */
        width: 60%;
    }
    .width-300px {
        width: 100%;
    }

    .gap30 {
        /* 320px breakpoint */
        padding-bottom: 10px;
    }
    .business-background.de div h1.homepage-headline {
        font-size: 36px;
        /* 320px breakpoint */
    }
    .partners-background.nl_NL div h1.homepage-headline {
        font-size: 42px;
        /* 320px breakpoint */
    }
    .homepage-header-wrap.partners-background {
        /* 320px breakpoint */
        /*background-position: -640px center;*/
    }
    div[id*=footer] {
        /* 320px breakpoint */
        height: 800px;
    }
    [id*=header] .integrated-search input[type="text"] {
        /* 320px breakpoint */
        width: 74%;
    }
    form.homepage input[type="text"],
    form.homepage select,
    .homepage input[type="text"],
    .homepage select {
        /* 320px breakpoint */
        width: 60%;
    }
    .third-box.community {
        /* 320px breakpoint */
        width: 98%;
        padding: 15px 1%;
    }
    a.button {
        /* 320px breakpoint */
        padding: 14px 14px;
    }
    .survey-box.vertical tr {
        /* 320px breakpoint */
        line-height: 4.2em;
    }
    .three-boxes .box {
        /* 320px breakpoint */
        width: 100%;
    }
    [id*=initial-button] {
        /* 320px breakpoint */
        text-align: center;
    }
    .homepage-header-wrap.crossroad-background {
        /* 320px breakpoint */
        background-position: -595px center;
    }
    .newForm dl.business textarea,
    .newForm dl.business input[type="text"] {
        /* 320px breakpoint */
        width: 92% !important;
    }

    .switcher-wrapper {
        /* 320px breakpoint */
        margin-bottom: 120px !important;
    }

    .switcher-wrapper.en {
        /* 320px breakpoint */
        margin-bottom: 105px !important;
    }
}

/* MOZZILA CSS */

@-moz-document url-prefix() {
    [id*=header] .integrated-search input[type="button"],
    [id*=header] .integrated-search input[type="submit"],
    [id*=header] .integrated-search button {
        height: 32px;
    }
}

/***BOOTSTRAP AND RESPONSIVE GRID***/
/* in ordred to make the responsive grid work, box-sizing must be set up to boder-box
    *{
        box-sizing: border-box;
    }

    */
.container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 10px;
    padding-right: 10px;
}
@media (min-width: 480px) {
    .container {
        width: 480px;
    }
}
@media (min-width: 768px) {
    .container {
        width: 760px;
    }
}
@media (min-width: 992px) {
    .container {
        width: 980px;
    }
}
@media (max-width: 479px) {
    .container.container-fluid-xs {
        width: auto;
    }
}
@media (max-width: 767px) {
    .container.container-fluid-sm {
        width: auto;
    }
}
@media (max-width: 991px) {
    .container.container-fluid-md {
        width: auto;
    }
}
@media (min-width: 992px) {
    .container.container-fluid-lg {
        width: auto;
    }
}
.container-fluid {
    margin-right: auto;
    margin-left: auto;
    padding-left: 10px;
    padding-right: 10px;
}
.row {
    margin-left: -10px;
    margin-right: -10px;
}
.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-lg-2-5, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    position: relative;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;
}
.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
    float: left;
}
.col-xs-12 {
    width: 100%;
}
.col-xs-11 {
    width: 91.66666667%;
}
.col-xs-10 {
    width: 83.33333333%;
}
.col-xs-9 {
    width: 75%;
}
.col-xs-8 {
    width: 66.66666667%;
}
.col-xs-7 {
    width: 58.33333333%;
}
.col-xs-6 {
    width: 50%;
}
.col-xs-5 {
    width: 41.66666667%;
}
.col-xs-4 {
    width: 33.33333333%;
}
.col-xs-3 {
    width: 25%;
}
.col-xs-2 {
    width: 16.66666667%;
}
.col-xs-1 {
    width: 8.33333333%;
}
.col-xs-pull-12 {
    right: 100%;
}
.col-xs-pull-11 {
    right: 91.66666667%;
}
.col-xs-pull-10 {
    right: 83.33333333%;
}
.col-xs-pull-9 {
    right: 75%;
}
.col-xs-pull-8 {
    right: 66.66666667%;
}
.col-xs-pull-7 {
    right: 58.33333333%;
}
.col-xs-pull-6 {
    right: 50%;
}
.col-xs-pull-5 {
    right: 41.66666667%;
}
.col-xs-pull-4 {
    right: 33.33333333%;
}
.col-xs-pull-3 {
    right: 25%;
}
.col-xs-pull-2 {
    right: 16.66666667%;
}
.col-xs-pull-1 {
    right: 8.33333333%;
}
.col-xs-pull-0 {
    right: auto;
}
.col-xs-push-12 {
    left: 100%;
}
.col-xs-push-11 {
    left: 91.66666667%;
}
.col-xs-push-10 {
    left: 83.33333333%;
}
.col-xs-push-9 {
    left: 75%;
}
.col-xs-push-8 {
    left: 66.66666667%;
}
.col-xs-push-7 {
    left: 58.33333333%;
}
.col-xs-push-6 {
    left: 50%;
}
.col-xs-push-5 {
    left: 41.66666667%;
}
.col-xs-push-4 {
    left: 33.33333333%;
}
.col-xs-push-3 {
    left: 25%;
}
.col-xs-push-2 {
    left: 16.66666667%;
}
.col-xs-push-1 {
    left: 8.33333333%;
}
.col-xs-push-0 {
    left: auto;
}
.col-xs-offset-12 {
    margin-left: 100%;
}
.col-xs-offset-11 {
    margin-left: 91.66666667%;
}
.col-xs-offset-10 {
    margin-left: 83.33333333%;
}
.col-xs-offset-9 {
    margin-left: 75%;
}
.col-xs-offset-8 {
    margin-left: 66.66666667%;
}
.col-xs-offset-7 {
    margin-left: 58.33333333%;
}
.col-xs-offset-6 {
    margin-left: 50%;
}
.col-xs-offset-5 {
    margin-left: 41.66666667%;
}
.col-xs-offset-4 {
    margin-left: 33.33333333%;
}
.col-xs-offset-3 {
    margin-left: 25%;
}
.col-xs-offset-2 {
    margin-left: 16.66666667%;
}
.col-xs-offset-1 {
    margin-left: 8.33333333%;
}
.col-xs-offset-0 {
    margin-left: 0%;
}
@media (min-width: 480px) {
    .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
        float: left;
    }
    .col-sm-12 {
        width: 100%;
    }
    .col-sm-11 {
        width: 91.66666667%;
    }
    .col-sm-10 {
        width: 83.33333333%;
    }
    .col-sm-9 {
        width: 75%;
    }
    .col-sm-8 {
        width: 66.66666667%;
    }
    .col-sm-7 {
        width: 58.33333333%;
    }
    .col-sm-6 {
        width: 50%;
    }
    .col-sm-5 {
        width: 41.66666667%;
    }
    .col-sm-4 {
        width: 33.33333333%;
    }
    .col-sm-3 {
        width: 25%;
    }
    .col-sm-2 {
        width: 16.66666667%;
    }
    .col-sm-1 {
        width: 8.33333333%;
    }
    .col-sm-pull-12 {
        right: 100%;
    }
    .col-sm-pull-11 {
        right: 91.66666667%;
    }
    .col-sm-pull-10 {
        right: 83.33333333%;
    }
    .col-sm-pull-9 {
        right: 75%;
    }
    .col-sm-pull-8 {
        right: 66.66666667%;
    }
    .col-sm-pull-7 {
        right: 58.33333333%;
    }
    .col-sm-pull-6 {
        right: 50%;
    }
    .col-sm-pull-5 {
        right: 41.66666667%;
    }
    .col-sm-pull-4 {
        right: 33.33333333%;
    }
    .col-sm-pull-3 {
        right: 25%;
    }
    .col-sm-pull-2 {
        right: 16.66666667%;
    }
    .col-sm-pull-1 {
        right: 8.33333333%;
    }
    .col-sm-pull-0 {
        right: auto;
    }
    .col-sm-push-12 {
        left: 100%;
    }
    .col-sm-push-11 {
        left: 91.66666667%;
    }
    .col-sm-push-10 {
        left: 83.33333333%;
    }
    .col-sm-push-9 {
        left: 75%;
    }
    .col-sm-push-8 {
        left: 66.66666667%;
    }
    .col-sm-push-7 {
        left: 58.33333333%;
    }
    .col-sm-push-6 {
        left: 50%;
    }
    .col-sm-push-5 {
        left: 41.66666667%;
    }
    .col-sm-push-4 {
        left: 33.33333333%;
    }
    .col-sm-push-3 {
        left: 25%;
    }
    .col-sm-push-2 {
        left: 16.66666667%;
    }
    .col-sm-push-1 {
        left: 8.33333333%;
    }
    .col-sm-push-0 {
        left: auto;
    }
    .col-sm-offset-12 {
        margin-left: 100%;
    }
    .col-sm-offset-11 {
        margin-left: 91.66666667%;
    }
    .col-sm-offset-10 {
        margin-left: 83.33333333%;
    }
    .col-sm-offset-9 {
        margin-left: 75%;
    }
    .col-sm-offset-8 {
        margin-left: 66.66666667%;
    }
    .col-sm-offset-7 {
        margin-left: 58.33333333%;
    }
    .col-sm-offset-6 {
        margin-left: 50%;
    }
    .col-sm-offset-5 {
        margin-left: 41.66666667%;
    }
    .col-sm-offset-4 {
        margin-left: 33.33333333%;
    }
    .col-sm-offset-3 {
        margin-left: 25%;
    }
    .col-sm-offset-2 {
        margin-left: 16.66666667%;
    }
    .col-sm-offset-1 {
        margin-left: 8.33333333%;
    }
    .col-sm-offset-0 {
        margin-left: 0%;
    }
}
@media (min-width: 768px) {
    .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
        float: left;
    }
    .col-md-12 {
        width: 100%;
    }
    .col-md-11 {
        width: 91.66666667%;
    }
    .col-md-10 {
        width: 83.33333333%;
    }
    .col-md-9 {
        width: 75%;
    }
    .col-md-8 {
        width: 66.66666667%;
    }
    .col-md-7 {
        width: 58.33333333%;
    }
    .col-md-6 {
        width: 50%;
    }
    .col-md-5 {
        width: 41.66666667%;
    }
    .col-md-4 {
        width: 33.33333333%;
    }
    .col-md-3 {
        width: 25%;
    }
    .col-md-2 {
        width: 16.66666667%;
    }
    .col-md-1 {
        width: 8.33333333%;
    }
    .col-md-pull-12 {
        right: 100%;
    }
    .col-md-pull-11 {
        right: 91.66666667%;
    }
    .col-md-pull-10 {
        right: 83.33333333%;
    }
    .col-md-pull-9 {
        right: 75%;
    }
    .col-md-pull-8 {
        right: 66.66666667%;
    }
    .col-md-pull-7 {
        right: 58.33333333%;
    }
    .col-md-pull-6 {
        right: 50%;
    }
    .col-md-pull-5 {
        right: 41.66666667%;
    }
    .col-md-pull-4 {
        right: 33.33333333%;
    }
    .col-md-pull-3 {
        right: 25%;
    }
    .col-md-pull-2 {
        right: 16.66666667%;
    }
    .col-md-pull-1 {
        right: 8.33333333%;
    }
    .col-md-pull-0 {
        right: auto;
    }
    .col-md-push-12 {
        left: 100%;
    }
    .col-md-push-11 {
        left: 91.66666667%;
    }
    .col-md-push-10 {
        left: 83.33333333%;
    }
    .col-md-push-9 {
        left: 75%;
    }
    .col-md-push-8 {
        left: 66.66666667%;
    }
    .col-md-push-7 {
        left: 58.33333333%;
    }
    .col-md-push-6 {
        left: 50%;
    }
    .col-md-push-5 {
        left: 41.66666667%;
    }
    .col-md-push-4 {
        left: 33.33333333%;
    }
    .col-md-push-3 {
        left: 25%;
    }
    .col-md-push-2 {
        left: 16.66666667%;
    }
    .col-md-push-1 {
        left: 8.33333333%;
    }
    .col-md-push-0 {
        left: auto;
    }
    .col-md-offset-12 {
        margin-left: 100%;
    }
    .col-md-offset-11 {
        margin-left: 91.66666667%;
    }
    .col-md-offset-10 {
        margin-left: 83.33333333%;
    }
    .col-md-offset-9 {
        margin-left: 75%;
    }
    .col-md-offset-8 {
        margin-left: 66.66666667%;
    }
    .col-md-offset-7 {
        margin-left: 58.33333333%;
    }
    .col-md-offset-6 {
        margin-left: 50%;
    }
    .col-md-offset-5 {
        margin-left: 41.66666667%;
    }
    .col-md-offset-4 {
        margin-left: 33.33333333%;
    }
    .col-md-offset-3 {
        margin-left: 25%;
    }
    .col-md-offset-2 {
        margin-left: 16.66666667%;
    }
    .col-md-offset-1 {
        margin-left: 8.33333333%;
    }
    .col-md-offset-0 {
        margin-left: 0%;
    }
}
@media (min-width: 992px) {
    .col-lg-1, .col-lg-2, .col-lg-2-5, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
        float: left;
    }
    .col-lg-12 {
        width: 100%;
    }
    .col-lg-11 {
        width: 91.66666667%;
    }
    .col-lg-10 {
        width: 83.33333333%;
    }
    .col-lg-9 {
        width: 75%;
    }
    .col-lg-8 {
        width: 66.66666667%;
    }
    .col-lg-7 {
        width: 58.33333333%;
    }
    .col-lg-6 {
        width: 50%;
    }
    .col-lg-5 {
        width: 41.66666667%;
    }
    .col-lg-4 {
        width: 33.33333333%;
    }
    .col-lg-3 {
        width: 25%;
    }
    .col-lg-2 {
        width: 16.66666667%;
    }
    .col-lg-2-5 {
        width: 20%;
    }
    .col-lg-1 {
        width: 8.33333333%;
    }
    .col-lg-pull-12 {
        right: 100%;
    }
    .col-lg-pull-11 {
        right: 91.66666667%;
    }
    .col-lg-pull-10 {
        right: 83.33333333%;
    }
    .col-lg-pull-9 {
        right: 75%;
    }
    .col-lg-pull-8 {
        right: 66.66666667%;
    }
    .col-lg-pull-7 {
        right: 58.33333333%;
    }
    .col-lg-pull-6 {
        right: 50%;
    }
    .col-lg-pull-5 {
        right: 41.66666667%;
    }
    .col-lg-pull-4 {
        right: 33.33333333%;
    }
    .col-lg-pull-3 {
        right: 25%;
    }
    .col-lg-pull-2 {
        right: 16.66666667%;
    }
    .col-lg-pull-1 {
        right: 8.33333333%;
    }
    .col-lg-pull-0 {
        right: auto;
    }
    .col-lg-push-12 {
        left: 100%;
    }
    .col-lg-push-11 {
        left: 91.66666667%;
    }
    .col-lg-push-10 {
        left: 83.33333333%;
    }
    .col-lg-push-9 {
        left: 75%;
    }
    .col-lg-push-8 {
        left: 66.66666667%;
    }
    .col-lg-push-7 {
        left: 58.33333333%;
    }
    .col-lg-push-6 {
        left: 50%;
    }
    .col-lg-push-5 {
        left: 41.66666667%;
    }
    .col-lg-push-4 {
        left: 33.33333333%;
    }
    .col-lg-push-3 {
        left: 25%;
    }
    .col-lg-push-2 {
        left: 16.66666667%;
    }
    .col-lg-push-1 {
        left: 8.33333333%;
    }
    .col-lg-push-0 {
        left: auto;
    }
    .col-lg-offset-12 {
        margin-left: 100%;
    }
    .col-lg-offset-11 {
        margin-left: 91.66666667%;
    }
    .col-lg-offset-10 {
        margin-left: 83.33333333%;
    }
    .col-lg-offset-9 {
        margin-left: 75%;
    }
    .col-lg-offset-8 {
        margin-left: 66.66666667%;
    }
    .col-lg-offset-7 {
        margin-left: 58.33333333%;
    }
    .col-lg-offset-6 {
        margin-left: 50%;
    }
    .col-lg-offset-5 {
        margin-left: 41.66666667%;
    }
    .col-lg-offset-4 {
        margin-left: 33.33333333%;
    }
    .col-lg-offset-3 {
        margin-left: 25%;
    }
    .col-lg-offset-2 {
        margin-left: 16.66666667%;
    }
    .col-lg-offset-1 {
        margin-left: 8.33333333%;
    }
    .col-lg-offset-0 {
        margin-left: 0%;
    }
}
@-ms-viewport {
    width: device-width;
}
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
    display: none !important;
}
.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
    display: none !important;
}
@media (max-width: 479px) {
    .visible-xs {
        display: block !important;
    }
    table.visible-xs {
        display: table;
    }
    tr.visible-xs {
        display: table-row !important;
    }
    th.visible-xs,
    td.visible-xs {
        display: table-cell !important;
    }
}
@media (max-width: 479px) {
    .visible-xs-block {
        display: block !important;
    }
}
@media (max-width: 479px) {
    .visible-xs-inline {
        display: inline !important;
    }
}
@media (max-width: 479px) {
    .visible-xs-inline-block {
        display: inline-block !important;
    }
}
@media (min-width: 480px) and (max-width: 767px) {
    .visible-sm {
        display: block !important;
    }
    table.visible-sm {
        display: table;
    }
    tr.visible-sm {
        display: table-row !important;
    }
    th.visible-sm,
    td.visible-sm {
        display: table-cell !important;
    }
}
@media (min-width: 480px) and (max-width: 767px) {
    .visible-sm-block {
        display: block !important;
    }
}
@media (min-width: 480px) and (max-width: 767px) {
    .visible-sm-inline {
        display: inline !important;
    }
}
@media (min-width: 480px) and (max-width: 767px) {
    .visible-sm-inline-block {
        display: inline-block !important;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .visible-md {
        display: block !important;
    }
    table.visible-md {
        display: table;
    }
    tr.visible-md {
        display: table-row !important;
    }
    th.visible-md,
    td.visible-md {
        display: table-cell !important;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .visible-md-block {
        display: block !important;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .visible-md-inline {
        display: inline !important;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .visible-md-inline-block {
        display: inline-block !important;
    }
}
@media (min-width: 992px) {
    .visible-lg {
        display: block !important;
    }
    table.visible-lg {
        display: table;
    }
    tr.visible-lg {
        display: table-row !important;
    }
    th.visible-lg,
    td.visible-lg {
        display: table-cell !important;
    }
}
@media (min-width: 992px) {
    .visible-lg-block {
        display: block !important;
    }
}
@media (min-width: 992px) {
    .visible-lg-inline {
        display: inline !important;
    }
}
@media (min-width: 992px) {
    .visible-lg-inline-block {
        display: inline-block !important;
    }
}
@media (max-width: 479px) {
    .hidden-xs {
        display: none !important;
    }
}
@media (min-width: 480px) and (max-width: 767px) {
    .hidden-sm {
        display: none !important;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .hidden-md {
        display: none !important;
    }
}
@media (min-width: 992px) {
    .hidden-lg {
        display: none !important;
    }
}
.visible-print {
    display: none !important;
}
@media print {
    .visible-print {
        display: block !important;
    }
    table.visible-print {
        display: table;
    }
    tr.visible-print {
        display: table-row !important;
    }
    th.visible-print,
    td.visible-print {
        display: table-cell !important;
    }
}
.visible-print-block {
    display: none !important;
}
@media print {
    .visible-print-block {
        display: block !important;
    }
}
.visible-print-inline {
    display: none !important;
}
@media print {
    .visible-print-inline {
        display: inline !important;
    }
}
.visible-print-inline-block {
    display: none !important;
}
@media print {
    .visible-print-inline-block {
        display: inline-block !important;
    }
}
@media print {
    .hidden-print {
        display: none !important;
    }
}
.clearfix:before,
.clearfix:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after {
    content: " ";
    display: table;
}
.clearfix:after,
.container:after,
.container-fluid:after,
.row:after {
    clear: both;
}

/***********************************/

/*NEW RESPONSIVE FOOTER*/

/*footer push bottom*/
.page-container {
    min-height: 100%;
    height: auto !important;
    height: 100%;
    margin: 0 auto -377px;
}
@media (max-width: 992px) {
    .page-container {
        margin: 0 auto -424px;
    }
}
@media (max-width: 767px) {
    .page-container {
        margin: 0 auto -410px;
    }
}
@media (max-width: 480px) {
    .page-container {
        margin: 0 auto -416px;
    }
}
@media (max-width: 320px) {
    .page-container {
        margin: 0 auto -432px;
    }
}
.push-footer-to-bottom {
    height: 377px;
}
@media (max-width: 992px) {
    .push-footer-to-bottom {
        height: 424px;
    }
}
@media (max-width: 767px) {
    .push-footer-to-bottom {
        height: 410px;
    }
}
@media (max-width: 480px) {
    .push-footer-to-bottom {
        height: 416px;
    }
}
@media (max-width: 320px) {
    .push-footer-to-bottom {
        height: 432px;
    }
}
footer {
    height: 377px;
}
@media (max-width: 992px) {
    footer {
        height: 424px;
    }
}
@media (max-width: 767px) {
    footer {
        height: 410px;
    }
}
@media (max-width: 480px) {
    footer {
        height: 416px;
    }
}
@media (max-width: 320px) {
    footer {
        height: 432px;
    }
}
/* Listed Links component - footer links design */
footer h4 {
    padding: 0;
}
.footer-links {
    padding: 10px;
}
@media (max-width: 767px) {
    .footer-links {
        border-bottom: 1px solid #373839;
        padding: 0;
    }
}
.footer-links ul li {
    line-height: 14px;
    padding: 2px 0;
}
@media (max-width: 767px) {
    .footer-links ul li {
        padding: 0 0 0 40px;
    }
}
.footer-links ul li a {
    color: #bababa;
    font-size: 11px;
    font-family: Verdana, sans-serif;
}
@media (max-width: 767px) {
    .footer-links ul li a {
        display: block;
        padding: 5px 0;
    }
}
.footer-links ul li a:hover {
    text-decoration: underline;
}
@media (max-width: 767px) {
    .footer-links .footer-links-content {
        display: none;
    }
}
@media (max-width: 767px) {
    .footer-links.active-column {
        background: #373839;
    }
    .footer-links.active-column h3 span {
        background-position: -18px 0;
    }
}
.footer-links h4 button {
    font-size: 18px;
    color: #fff;
    display: inline-block;
    margin: 0px;
    line-height: 24px;
    padding: 4px 0;
    width: 100%;
    border: 0;
    text-align: left;
    font-weight: 300;
    font-family: AVGSans, Verdana, Arial, Helvetica, sans-serif;
}

@media (max-width: 767px) {
    .footer-links h4 button {
        cursor: pointer;
        padding: 5px 30px;
        line-height: 30px;
    }
    .footer-links h4 button span {
        float: right;
        width: 15px;
        height: 15px;
        margin-top: 10px;
        background: url("../img/icons/footer-arrow.png") no-repeat 0 0;
    }
}

/* Social Connect component */
.social-connect {
    padding: 10px;
}

.social-connect li {
    overflow: hidden;
    margin-bottom: 5px;
    padding-bottom: 0px;
}

.social-connect li a {
    display: inline-block;
    line-height: 25px;
    color: #bababa;
    font-size: 11px;
    font-family: Verdana, sans-serif;
}

.social-connect li a .icon {
    display: block;
    width: 25px;
    height: 25px;
    float: left;
    margin-right: 8px;
}

.social-connect li a:hover {
    text-decoration: underline;
}

.social-connect h4 {
    font-size: 18px;
    color: #fff;
    display: inline-block;
    margin: 0;
    line-height: 24px;
    padding: 4px 0;
    width: 100%;
}

@media (max-width: 992px) {
    .social-connect {
        border-bottom: 1px solid #373839;
        display: block !important;
        text-align: center;
        margin-bottom: 0;
        padding: 0;
        width: 100%;
    }

    .social-connect ul {
        margin: 15px 0;
    }

    .social-connect li {
        display: inline-block;
        margin: 0;
        margin-right: 2px;
    }

    .social-connect li a {
        text-indent: -9999px;
        width: 25px;
    }

    .social-connect li a .icon {
        display: inline-block !important;
        margin-right: 0px;
    }

    .social-connect h4 {
        display: none;
    }
}

/* footer specific */
footer .footer-button {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin: 0 8px 15px 8px;
}
footer .footer-button.disabled {
    cursor: auto;
}

footer .footer-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;

    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -moz-box-orient: horizontal;
    -moz-box-direction: normal;
    -ms-flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
}

@media (max-width:767px) {
    footer .footer-buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
    }
}

footer .button-silver,
footer .button-silver:hover,
footer .button-silver:focus,
footer .button-silver.focus,
footer .button-silver:active,
footer .button-silver.active {
    background-image: none !important;
    background-color: transparent !important;
    border: 1px solid #bababa !important;
    filter: none !important;
    color: #bababa;
    border-radius: 4px;
    padding: 6px 15px;
    font-size: 14px;
    width: auto;
    position: relative;
}
footer .flag + span {
    padding-left: 32px;
}
footer .button-silver .flag {
    position: absolute;
}
footer * {
    box-sizing: border-box;
}
footer .list-unstyled {
    padding: 0!important;
    list-style: none!important;
}
/*other styles necessary for footer*/
.group-43 {
    background-color: #48494B;
}
.group-50 {
    background-color: #373839;
    padding: 25px 0;
    font-family: Verdana, sans-serif;
}
@media (min-width: 768px) {
    .pull-md-right {
        float: right!important;
    }
}
@media (min-width: 768px) {
    .pull-md-left {
        float: left!important;
    }
}
/*text classes*/
.text-center {
    text-align: center;
}
.text-xx-small {
    font-size: 11px !important;
    margin-top: 10px;
    margin-bottom: 6px;
    line-height: 16px !important;
}
.text-silver,
.text-silver-all * {
    color: #bababa !important;
}
.footnote a:hover {
    text-decoration: underline;
}
/*icons*/
.icon-0 {
    background: url("../img/icons/sprite-icon-social.png") 0 0 no-repeat;
}
.icon-1 {
    background: url("../img/icons/sprite-icon-social.png") 0 -30px no-repeat;
}
.icon-2 {
    background: url("../img/icons/sprite-icon-social.png") 0 -60px no-repeat;
}
.icon-3 {
    background: url("../img/icons/sprite-icon-social.png") 0 -90px no-repeat;
}
.icon-4 {
    background: url("../img/icons/sprite-icon-social.png") 0 -120px no-repeat;
}
.icon-5 {
    background: url("../img/icons/sprite-icon-social.png") 0 -150px no-repeat;
}
/***********************/

/*****Survey page *******/
.question-form {
    margin-top: 3rem;
}

.question-box {
    display: block;
    margin-bottom: 3rem;
}

.question {
    font-size: 18px;
    font-weight: 300;
    color: #5c707b;
    margin-bottom: 12px;
    overflow: auto;
}

.question-form .button {
    display: inline-block;
    background-color: #0072D1;
    color: #ffffff;
    border: 0;
    padding: 14px 42px;
    font-weight: 300;
    cursor: pointer;
    border-radius: 4px;
    font-size: 18px;
    user-select: none;
}

.question-form .button:hover {
    background-color: #3aa9fe;
}

.question-form .question-open textarea {
    padding: 9px;
    margin: 0;
    outline: none;
    border-radius: 4px;
    border-color: #e5e5e5;
    border: 1px solid #959595;
    width: 100%;
    max-width: 600px;
}

.question-scale button, .question-yes-no button, .question-yes-no-notyet button {
    font-size: 18px;
    font-family: AVGSans;
    margin-right: 15px;
    cursor: pointer;
}

.question-scale > button::before, .question-yes-no > button::before, .question-yes-no-notyet > button::before  {
    content: '';
    display: inline-block;
    vertical-align: middle;
    margin-left: 0px;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background: url(../img/icons/input-radio.png) no-repeat left center;
}

.question-scale > button.selected, .question-yes-no > button.selected, .question-yes-no-notyet > button.selected {
    color: #0072D1;
}

.question-scale > button.selected::before, .question-yes-no > button.selected::before, .question-yes-no-notyet > button.selected::before {
    background: url(../img/icons/input-radio-checked.png) no-repeat left center;
}

.question-select select {
    -webkit-appearance: menulist !important;
}


.question-scale.scale-0-5 > button {
    background-size: 324px 128px;
    background-repeat: no-repeat;
    padding: 0;
    width: 64px;
    height: 64px;
    background-image: url('../img/feedback-sprite.png');
    background-repeat: no-repeat;
    display: inline-block;
    margin: 0 13px;
    text-align: center;
}
.question-scale.scale-0-5 > .desc {
    display: inline-block;
    width: 450px;
    position: relative;
    margin: 0px auto;
}
.question-scale.scale-0-5 > button::before {
    display: none;
}
.question-scale.scale-0-5 > button:hover, .question-scale.scale-0-5 > button.selected {
    background-color: transparent !important;
}
.question-scale.scale-0-5 > button.l0 {
    background-position: 0 0;
    margin-left: 0;
}
.question-scale.scale-0-5 > button.l0:hover, .question-scale.scale-0-5 > button.l0.selected {
    background-position: 0px -64px;
}
.question-scale.scale-0-5 > button.l25 {
    background-position: -65px 0;
}
.question-scale.scale-0-5 > button.l25:hover, .question-scale.scale-0-5 > button.l25.selected {
    background-position: -65px -64px;
}
.question-scale.scale-0-5 > button.l5 {
    background-position: -130px 0;
}
.question-scale.scale-0-5 > button.l5:hover, .question-scale.scale-0-5 > button.l5.selected {
    background-position: -130px -64px;
}
.question-scale.scale-0-5 > button.l75 {
    background-position: -195px 0;
}
.question-scale.scale-0-5 > button.l75:hover, .question-scale.scale-0-5 > button.l75.selected {
    background-position: -195px -64px;
}
.question-scale.scale-0-5 > button.l10 {
    background-position: -260px 0;
}
.question-scale.scale-0-5 > button.l10:hover, .question-scale.scale-0-5 > button.l10.selected {
    background-position: -260px -64px;
}


.question.h1 {
    font-size: 36px;
    margin-top: 3rem;
    margin-bottom: 32px;
    color: #000;
}

.question.h2 {
    font-size: 28px;
    margin-top: 3rem;
    margin-bottom: 32px;
    color: #000;
}

.question.h3 {
    font-size: 24px;
    margin-top: 3rem;
    margin-bottom: 32px;
    color: #000;
}

.question.p {
    font-size: 18px;
    margin-bottom: 12px;
    margin-top: 3rem;
}

.question.spacer {
    margin-top: 3rem;
}

@media (max-width:767px) {
    .scale-0-5.wrapper {
        text-align: center;
    }

}

/************************/

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}